import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService , AlertService, FormatDateService} from '../../../../services/index';
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';
import * as NProgress from 'nprogress';


@Component({
  selector: 'app-notification-event',
  templateUrl: './notification-event.component.html',
  styleUrls: ['./notification-event.component.css']
})
export class NotificationEventComponent implements OnInit {

clientId: any = localStorage.getItem("clientId");
customerId: any = localStorage.getItem("customerId");
userId: any = localStorage.getItem("userId");
notificationEventList : any = [];
dataTable: any;
dropdownSettingsSite:any={};
dropdownSettingsAsset:any={};
site:any = '';
asset:any = '';
checkSiteArray:any = [];
checkAssetArray:any = [];
selectedSite:any = [];
selectedAsset:any = [];
assetDatalist:any=[];
siteList:any=[];

showAckBtn : number = 0;
showAckErcBtn : number = 0;
showERC : number = 0; 
performAction : any = 0;

constructor(private router:Router, private apiService: ApiService , private alertService: AlertService, private formatDateService: FormatDateService, private chRef: ChangeDetectorRef) { }

  // Function to format Date
  DateFormat(startDate, gmtOffset){
    if (startDate) {
      var formattedDate = moment(startDate,"YYYY-MM-DD HH:mm:ss:SSS").format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    } else {
      return '-'
    }
  }

  ngOnInit() {
  this.getNotificationEventList();
  this.getSiteManagementList();
  this.getAllAssetList();

  this.dropdownSettingsSite = {
    singleSelection: false,
    idField: 'siteId',
    textField: 'siteName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 0,
    allowSearchFilter: true
};

this.dropdownSettingsAsset = {
    singleSelection: false,
    idField: 'assetId',
    textField: 'assetName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 0,
    allowSearchFilter: true
};
  }

  /* On Item Select Site */
  onItemSelectSite(item: any) {
    var exist = this.checkSiteArray.indexOf(item.siteId.toString());
    if (exist == '-1') {
        this.checkSiteArray.push(item.siteId.toString());
        this.getAllAssetList();
    }
  }

  /* On Item DeSelect Site */
  onItemDeselectSite(item: any) {
    var exist = this.checkSiteArray.indexOf(item.siteId.toString());
    this.checkAssetArray = [];
    if (exist != '-1') {
        this.checkSiteArray.splice(exist, 1);
        this.getAllAssetList();
    }
  }

  /* On All Select Site */
  onSelectAllSite(items: any) {
    this.checkSiteArray = [];
    Object.keys(items).forEach(key => {
        this.checkSiteArray.push(items[key]['siteId'].toString());
    });
    this.getAllAssetList();
  }

  /* On All DeSelect Site */
  onDeSelectAllSite() {
    this.checkSiteArray = [];
    this.checkAssetArray = [];
    this.getAllAssetList();
  }

  /* On Item Select Asset */
  onItemSelectAsset(item: any) {
      var exist = this.checkAssetArray.indexOf(item.assetId.toString());
      if (exist == '-1') {
          this.checkAssetArray.push(item.assetId.toString());
      }
  }

  /* On Item DeSelect Asset */
  onItemDeselectAsset(item: any) {
      var exist = this.checkAssetArray.indexOf(item.assetId.toString());
      if (exist != '-1') {
          this.checkAssetArray.splice(exist, 1);
      }
  }

  /* On All Select Asset */
  onSelectAllAsset(items: any) {
      this.checkAssetArray = [];
      Object.keys(items).forEach(key => {
          this.checkAssetArray.push(items[key]['assetId'].toString());
      });
  }

  /* On All DeSelect Site */
  onDeSelectAllAsset() {
      this.checkAssetArray = [];
  }

  /* On Search */
  seachRecord() {
    this.site = this.checkSiteArray;
    this.asset = this.checkAssetArray;
    this.getNotificationEventList();
  }

  /* Reset Search Record */
  resetSearch() {
    this.site = '';
    this.asset = '';
    this.checkSiteArray = [];
    this.checkAssetArray = [];
    var self = this;
    setTimeout(()=>{
      self.getAllAssetList();
      this.getNotificationEventList();
    }, 2000);

    this.selectedSite = [];
    this.selectedAsset = [];
  }

/* Get Site Management list */
getSiteManagementList() {  
  var URL = 'customer/site/getSiteListByCustomer?customerId=' + this.customerId;
  this.apiService.getAll(URL).subscribe(
    data => {
        if (data.statusCode == 100) {
            this.siteList = data.responseList;
        } else {
            this.siteList = [];
        }
    },
    error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
     }
  );
  }

  // Getting Asset List
  getAllAssetList() {
      var URL = 'customer/asset/getAssetByCustomer?customerId=' + this.customerId + '&clientId='+this.clientId;
      if (this.checkSiteArray.length != 0) {
          this.site = this.checkSiteArray.toString();
          var siteString = '&siteId=' + this.site;
          var URL = URL.concat(siteString);
      }
      this.apiService.getAll(URL).subscribe(
          data => {
              if (data.statusCode == 100) {
                  this.assetDatalist = data.responseList;
              } else {
                  this.assetDatalist    = [];
              }
              this.selectedAsset = [];
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
           }
      );
  }


  /* Get Notification Event list */
getNotificationEventList(){
  
  var URL = "customer/wffNotification/getNotificationLatestEvents?clientId="+this.clientId+"&customerId="+this.customerId//'customer/wffNotification/getLatestEvents?clientId='+this.clientId+'&customerId='+this.customerId;
  if (this.site != '') {
    var siteString = '&siteId=' + this.site;
    var URL = URL.concat(siteString);
  }
  if (this.asset != '') {
      var assetString = '&assetId=' + this.asset;
      var URL = URL.concat(assetString);
  }
  if ($.fn.DataTable.isDataTable('#notificationEventListTable')) {
      $('#notificationEventListTable').DataTable().destroy();
      this.notificationEventList = [];
  };
  
   this.apiService.getAll(URL).subscribe(
   data => {
       if(data.statusCode == 100){
          this.notificationEventList = data.responseList;
          this.chRef.detectChanges();
          if ($.fn.DataTable.isDataTable('#notificationEventListTable')) {
              $('#notificationEventListTable').DataTable().destroy();
          };
          const table: any = $('#notificationEventListTable');
          this.dataTable = table.DataTable({"columnDefs": [{ "orderable": false, "targets": 7 }]});          
        }else{
          this.notificationEventList = [];
          this.chRef.detectChanges();
          const table: any = $('#notificationEventListTable');
          this.dataTable = table.DataTable({"columnDefs": [{ "orderable": false, "targets": 7 }]});
          this.alertService.sweetMessage('error',data.responseMessage);          
         }
      },
     error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );
      }

      notificationDetail(eventData, eventListType) {
        let runTimeStatus = eventData.runTimeStatus;
        let licenseIsAck = eventData.licenseIsAck;
        let acknowledgeByMe = eventData.acknowledgeByMe;
        let assignToMe = eventData.assignToMe;
        let technicianId = eventData.technicianId;      
        let licenseIsTechEntry = eventData.licenseIsTechEntry;
  
        if(this.userId == eventData.technicianId && runTimeStatus == 2) {
           this.performAction = 1;
        } 
  
        console.log('notification detail', eventData.acknowledgeByMe, eventData.licenseIsAck, eventData.assignToMe);
        
        console.log('notification detail 2', eventData.technicianId, eventData.runTimeStatus, eventData.licenseIsTechEntry);
        
  
        if(licenseIsAck == true && acknowledgeByMe == false && assignToMe == true) {
          console.log('in if detail');
          this.showAckBtn = 1;
        } else {
          this.showAckBtn = 0;
          console.log('in else detail');
        }
  
        if(
          licenseIsAck == true && 
          acknowledgeByMe == false && 
          assignToMe == true && 
          (technicianId == null || technicianId == 0) && 
          licenseIsTechEntry == true && 
          runTimeStatus == 1
          ) {
          console.log('in if detail showAckErcBtn');
          this.showAckErcBtn = 1;
        } else {
          this.showAckErcBtn = 0;
          console.log('in else detail showAckErcBtn');
        }
  
        if(
          licenseIsAck == true && 
          assignToMe == true && 
          (technicianId == null || technicianId == 0) &&         
          runTimeStatus == 1
          ) {
          this.showERC = 1;
        } else {
          this.showERC = 0;
        }
  
        let obj = {
          id:null,
          type:null
        }
        let pVal = 0;
        if(eventData.isParent == false) {
          pVal = 0;
        } else if(eventData.isParent == true) {
          pVal = 1;
        }
        obj.type = eventData.notificationType;
        if (eventData.notificationType == "Notification") {
            obj.id = eventData.notificationId;
        } else if (eventData.notificationType == "RunTime") {
            obj.id = eventData.runTimeEventId;
        }
        if(eventListType == 'approval' || eventListType == 'approved') {
          obj.type = 'RunTime';
          obj.id = eventData.runTimeEventId;
          this.performAction = 1;
          eventData.licenseIsTechEntry = true;
        }
  
        this.router.navigate(["eventDetails", eventData.assetId,], 
          {
            queryParams: 
            { 
              type: obj.type, 
              id :obj.id, 
              rcpId:eventData.runTimeCollectionPointId, 
              notificationId : eventData.notificationId, 
              isParent : pVal, 
              performAction : this.performAction, 
              showAckBtn : this.showAckBtn,
              showAckErcBtn : this.showAckErcBtn,
              showERC : this.showERC,
              licenseIsTechEntry : eventData.licenseIsTechEntry, 
              eventListType : eventListType  
            }
        });
      }


}
