import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, FormatDateService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';
import { jqxTreeComponent } from '../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../jqwidgets-ts/angular_jqxdropdownbutton'


@Component({
  selector: 'app-copy-schedule',
  templateUrl: './copy-schedule.component.html',
  styleUrls: ['./copy-schedule.component.css']
})
export class CopyScheduleComponent implements OnInit {

    @ViewChild('myTree') myTree: jqxTreeComponent;
    @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;


    clientId: any = localStorage.getItem("clientId");
    userId: any = localStorage.getItem("userId");
    customerId: any = []
    tierId: any = []
    assetId: number = 0;
    dataTable: any;
    site: any = '';
    assetDatalist: any = [];
    shiftGroupList: any = [];
    assetsumaryData: any = [];
    assetList: any = [];
    siteList: any = [];
    siteLat: number = 39.381266;
    siteLong: number = -97.922211;
    dataAdapter: any = [];
    tierListData: any = [];
    tierArray: any = [];
    data: any = [];
    userassetList: any = [];
    checkTreeArray: any = [];
    disableTreeArray: any = [];
    records: any = [];
    source: any = {};
    assetArray:any=[];
    finalAssetArray:any=[];
    copyData:any={"assetId": "","assets" : []};
    client: any = {
        "assetId": "",
        "clientRequest": {
            "clientId": ""
        },
        "shiftGroup": {
            "shiftGroupId": ""
        },
        "site": {
            "siteId": ""
        },
        "scheduleAssetId": ""
    }
    clearsite : any = "";
    clientCode : any = '';
    shiftGroupId:any='';
    // permissionData:any=[];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private apiService: ApiService ,
        private alertService: AlertService,
        private formatDateService: FormatDateService ,
        private chRef: ChangeDetectorRef,

        ) {
        // this.permissionData = ApiService.permission;
    }

    // Function to format Date
    DateFormat(startDate, gmtOffset){
        return this.formatDateService.formatDate(startDate, gmtOffset);
    }

    ngOnInit() {
      this.clientCode = this.alertService.getClientCode();
        this.route.params.subscribe(params => {
            if (params['id']) {
                this.assetId = params['id'];
            }
        });

        if (this.assetId) {
            this.getSIteListDataForDropdown();
            this.getAssetSummaryData();
            this.getTierList();
            this.getUserByAsset();
            setTimeout(()=>{
                this.getAllAssetList();
            }, 3000);

        }
    }

    // Function to set latitude & longitude
    setLatLong(lat, long) {
        this.siteLat = lat;
        this.siteLong = long;
    }

    // Function to serch a record
    seachRecord(){
        this.getAllAssetList();
    }

    // Function to Reset Data
    resetSearch(){
        this.checkTreeArray = [];
        this.myDropDownButton.setContent('')
        this.clearsite = "";
        this.getAllAssetList();
        this.getTierList();
        let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"> Select Tier </div>';
        this.myDropDownButton.setContent(dropDownContent);
    }

    // Function to disable a item
    treeItemDisable() {
        Object.keys(this.disableTreeArray).forEach(key => {
            let checkedItem = document.getElementById(this.disableTreeArray[key]);
            this.myTree.expandItem(checkedItem);
            this.myTree.disableItem(checkedItem);
        });
    }

    // Function for onchange of checkbox
    CheckChange(event){
        if(event.args.checked == true){
            var exist = this.checkTreeArray.indexOf(event.args.element.id);
            if(exist == '-1'){
                this.checkTreeArray.push(event.args.element.id);
            }
        }else if(event.args.checked == false){
            var exist = this.checkTreeArray.indexOf(event.args.element.id);
            if(exist != '-1'){
                this.checkTreeArray.splice(exist,1);
            }

        }
        if(this.checkTreeArray.length > 0){
            let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"><span class="textRight">+' + this.checkTreeArray.length+' </span></div>';
            this.myDropDownButton.setContent(dropDownContent);

        }else{
            let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"> Select Tier </div>';
            this.myDropDownButton.setContent(dropDownContent);

        }
        this.myDropDownButton.close();
    }

    /* Get tier list */
    getTierList() {
        this.apiService.getAll('tier/getAllTierByUser?clientId=' + this.clientId + '&userId=' + this.userId).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.tierListData = data.responseList;
                    this.data = this.tierListData;
                    Object.keys(this.data).forEach(key => {
                        if (this.data[key]['flag'] == false) {
                            this.disableTreeArray.push(this.data[key]['tierId']);
                        }
                    });
                    this.source = {
                        datatype: 'json',
                        datafields: [{
                            name: 'tierId'
                        },
                        {
                            name: 'parentTierId'
                        },
                        {
                            name: 'tierName'
                        },
                        ],
                        id: 'tierId',
                        localdata: this.data
                    };
                    this.dataAdapter = new jqx.dataAdapter(this.source, {
                        autoBind: true
                    });
                    this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [{
                        name: 'tierName',
                        map: 'label'
                    }, {
                        name: 'tierId',
                        map: 'id'
                    }]);
                    var self = this;
                    setTimeout(function() {
                        self.treeItemDisable();
                    }, 1000);
                    // let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"> Select Tier </div>';
                    // this.myDropDownButton.setContent(dropDownContent);
                } else {
                    this.alertService.sweetMessage('error',data.responseMessage);
                }
            },
            error =>{
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    // Listen For event Recive from child
      reciveAsset(){
        this.getAssetSummaryData();
      }

    /* Function for get Asset Summary Data */
    getAssetSummaryData() {
        this.apiService.getAll('asset/getAssetSummaryById?clientId=' + this.clientId + '&userId=' + this.userId + '&assetId=' + this.assetId).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.assetsumaryData = data.response;
                    if(data.response.shiftGroup){
                        this.shiftGroupId = this.assetsumaryData.shiftGroup.shiftGroupId;
                    }
                } else {
                    this.alertService.sweetMessage('error',data.responseMessage);
                }
            },
           error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    // Check box functionality for Asset
    checkAsset(e,assId){
        if(e.target.checked == true){
            this.assetArray.push(assId);
        }else{
            var exist = this.assetArray.indexOf(assId);
            if(exist != '-1'){
                this.assetArray.splice(exist,1);
            }
        }
    }

    // Creating an array for Assets
    createFinalAssetArray(){
        this.copyData.assets = [];
        Object.keys(this.assetArray).forEach(key => {
           this.finalAssetArray.push({"assetId":this.assetArray[key]});
       });
        this.copyData.assets = this.finalAssetArray;
        this.finalAssetArray = [];
    }

    // Function to make a copy of Asset
    scheduleCopyToAsset() {
        Swal({
          title: 'Are you sure?',
          text: "Existing users will be removed and new users will be assigned to this asset.",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Copy it!'
        }).then((result) => {
            if (result.value) {
              this.copyData.assetId = this.assetId;
              this.createFinalAssetArray();
              // this.apiService.create('asset/copyAssetSchedule',this.copyData).subscribe(
              this.apiService.create('asset/copyAssetUsers',this.copyData).subscribe(
                data => {
                    if (data.statusCode == 100) {
                        this.copyData={"assetId": "","assets" : []};
                        this.getAllAssetList();
                        this.alertService.sweetMessage('success','Successfully Copy Schedule');
                    }
                    else
                      { this.alertService.sweetMessage('error',data.responseMessage); }
                },
              error =>{
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
              );
            }
        })
    }

    // Function to fetch data for Site Array
    getSIteListDataForDropdown() {
        this.apiService.getAll('site/getSiteDropDownListByClient?clientId=' + this.clientId + '&userId=' + this.userId + '&assetId=' + this.assetId).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.siteList = data.responseList;
                  // Sort the Tier Drop Down By Name
                    var _this = this;
                    this.siteList.sort(function(a, b){
                      var nameA = a.siteName.toLowerCase(),
                          nameB = b.siteName.toLowerCase();
                      if (nameA < nameB) //sort string ascending
                        return -1;
                      if (nameA > nameB)
                        return 1;
                      return 0; //default return value (no sorting)
                    });
                    //console.log(typeof this.siteList)
                } else {
                    this.siteList = [];
                    //this.alertService.sweetMessage('error',data.responseMessage);
                }
            },
            error =>{
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    /* Get User by Asset list */
    getUserByAsset(){
        // this.apiService.getAll('public/user/getUserByAsset?clientId='+this.clientId+'&assetId='+this.assetId).subscribe(
        this.apiService.getAll('user/getUserByAsset?clientId='+this.clientId+'&assetId='+this.assetId).subscribe(
        data => {
            if(data.statusCode == 100){
                  this.userassetList = data.userList;
              }else{
                  this.userassetList = [];
              }
           },
            error =>{
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    /* Function for getting Root level tier data */
    getAllAssetList() {
        // var URL = 'asset/getAssetByClient?clientId=' + this.clientId + '&userId=' + this.userId;
        var URL = 'asset/getAssetByClientForCopyAssetUser?clientId=' + this.clientId + '&userId=' + this.userId + '&assetId='+this.assetId;
        if (this.customerId != null && this.customerId != '') {
            var customerString = '&customerId=' + this.customerId.toString();
            var URL = URL.concat(customerString);
        }
        if (this.checkTreeArray != null && this.checkTreeArray != '') {
            var tierString = '&tierId=' + this.checkTreeArray.toString();
            var URL = URL.concat(tierString);
        }
        if (this.clearsite != '') {
            var siteString = '&siteId=' + this.clearsite;
            var URL = URL.concat(siteString);
        }
        if(this.shiftGroupId !=''){
            var shiftGroupId = '&shiftGroupId=' + this.shiftGroupId;
            var URL = URL.concat(shiftGroupId);
        }

        this.apiService.getAll(URL).subscribe(
            data => {
               if (data.statusCode == 100) {
                if ($.fn.DataTable.isDataTable('#asset_data_table')) {
                    $('#asset_data_table').DataTable().destroy();
                };
                this.assetDatalist = data.responseList;
                this.chRef.detectChanges();
                const table: any = $('#asset_data_table');
                this.dataTable = table.DataTable();

            }

            else {
                if ($.fn.DataTable.isDataTable('#asset_data_table')) {
                    $('#asset_data_table').DataTable().destroy();
                };
                this.assetDatalist = [];
                this.chRef.detectChanges();
                const table: any = $('#asset_data_table');
                this.dataTable = table.DataTable();

            }
        },
        error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }


}
