import { Component, OnInit, ChangeDetectorRef, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService, AlertService, FormatDateService } from '../../../../../services/index';
import Swal from 'sweetalert2'
declare var $: any;
declare var jQuery: any,Dygraph:any,Highcharts:any;
declare var d3: any, moment: any;
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'app-asset-graph',
  templateUrl: './asset-graph.component.html',
  styleUrls: ['./asset-graph.component.css']
})
export class AssetGraphComponent implements OnInit {
  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  assetId: any = 0;
  assetChannelData: any = [];
  graphRenderData: any = [];
  checkAssetArray: any = [];
  unitArray: any = ['date'];
  tempArray: any = [];
  assetsumaryData: any = {};
  data: any = [];
  options1: any = {};
  dataTable: any;
  dropdownSettingsAsset: any = {};
  assetData: any = [];
  assetDataPoint: any = { "graphName": "", "asset": { "assetId": "" }, "datapoint": [], "assetGraphId" : 0 };
  siteLat: number = 39.381266;
  siteLong: number = -97.922211;
  clientCode: any = '';
  // permissionData: any = [];
  checkAsset: any = [];
  assetGraphId : any = 0;
  assetDataObj :any = {}
  DateError:any = null;
  TimeZone = ""
  breadcrumbObj: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private alertService: AlertService,
    private formatDateService: FormatDateService,
    private chRef: ChangeDetectorRef) {
    // this.permissionData = ApiService.permission;
  }

  // Function to format Date
  DateFormat(startDate, gmtOffset) {
    if (startDate) {
      var formattedDate = moment(startDate,"YYYY-MM-DD HH:mm:ss:SSS").format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    } else {
      return '-'
    }
  }

  ngOnInit() {
    this.clientCode = this.alertService.getClientCode();
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.assetId = params['id'];
      }
    });
    this.route
    .queryParams
    .subscribe(params => {
        this.breadcrumbObj = params['source'];
    })
    if (this.assetId) {
      this.getAssetSummaryData();
      this.getAssetChannel();
      this.getAllAssetGraphData();


      this.dropdownSettingsAsset = {
        singleSelection: false,
        idField: 'assetChannelId',
        textField: 'assetChannelDescription',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 0,
        allowSearchFilter: true
      };
    }

    jQuery('.rst-date').datetimepicker({
      language: 'en',
      pickTime: true,
      autoclose: true,
      pickDate: true,
      minDate: 0,
      inline: true,
      sideBySide: true
    });
    jQuery('.rst-time').datetimepicker({
        pickDate: false,
        autoclose: true,
        pickTime: true,
        pickSeconds: false,
        pickMinutes: true,
        timeFormat: "HH:mm"
    });


    let today = moment().format('YYYY-MM-DD HH:MM:ss')
    
    $('#endDate').val(today);
    $('#startDate').val(moment().subtract(45,'days').format('YYYY-MM-DD HH:MM:ss'));
  }

  /* On Item Select State */
  onItemSelectAsset(item: any) {
    var exist = this.checkAssetArray.indexOf(item.assetChannelId.toString());
    if (exist == '-1') {
      this.checkAssetArray.push(item.assetChannelId.toString());
    }
  }

  /* On Item DeSelect State */
  onItemDeselectAsset(item: any) {
    var exist = this.checkAssetArray.indexOf(item.assetChannelId.toString());
    if (exist != '-1') {
      this.checkAssetArray.splice(exist, 1);
    }
  }
  /* On All Select State */
  onSelectAllAsset(items: any) {
    this.checkAssetArray = [];
    Object.keys(items).forEach(key => {
      this.checkAssetArray.push(items[key]['assetChannelId'].toString());
    });
  }
  /* On All DeSelect State */
  onDeSelectAllAsset() {
    this.checkAssetArray = [];
  }

  // Function to set latitude and longitude
  setLatLong(lat, long) {
    this.siteLat = lat;
    this.siteLong = long;
  }


  reciveAsset() {
    this.getAssetSummaryData();
  }

  /* Function for get Asset Summary Data */
  getAssetSummaryData() {
    this.apiService.getAll('customer/asset/getCustomerAssetSummaryById?assetId=' + this.assetId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.assetsumaryData = data.response;
        } else {
          this.alertService.sweetMessage('error', data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  
  ResetDate(){
    let today = moment().format('YYYY-MM-DD HH:MM:ss')
    
    $('#endDate').val(today);
    $('#startDate').val(moment().subtract(45,'days').format('YYYY-MM-DD HH:MM:ss'));
    this.getAssetDataD(this.assetDataObj);
  }


  /* Function for get Asset Channel Data */
  getAssetChannel() {
    this.apiService.getAll('customer/asset/getCustomerAssetChannelsByAssetId?assetId=' + this.assetId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.assetChannelData = data.responseList;
        } else {
          //this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  /* Function for Create Asset Data Point */
  creatAssetsDataPoint() {

    if(this.assetGraphId == 0){

      this.assetDataPoint.datapoint = this.checkAssetArray;
      this.assetDataPoint.asset.assetId = this.assetId;

      this.apiService.create('asset/createAssetGraph', this.assetDataPoint).subscribe(
        data => {
          if (data.statusCode == 100) {
            $("#addAssetDataPoint").click();
            this.alertService.sweetMessage('success', 'Date Point Added Successfully.');
            this.checkAssetArray = [];
            this.checkAsset = [];
            this.assetDataPoint = { "graphName": "", "asset": { "assetId": this.assetId }, "datapoint": [] };
            this.getAllAssetGraphData();
            var close = document.getElementById("closePop");
            close.click();
          } else {
            this.alertService.sweetMessage('error', data.responseMessage);

          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }else {

      this.assetDataPoint.datapoint = this.checkAssetArray;
      this.assetDataPoint.asset.assetId = this.assetId;
      this.assetDataPoint.assetGraphId = this.assetGraphId;


      this.apiService.update('asset/updateAssetDataPointGraph',this.assetDataPoint).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.assetGraphId = 0;
            this.checkAssetArray = [];
            this.checkAsset = []
            this.getAllAssetGraphData();
            this.alertService.sweetMessage('success', "Graph updated Successfully");
          } else {
            this.alertService.sweetMessage('error', data.responseMessage);
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }

  }

  /* Function for get All Asset Graph Data */
  getAllAssetGraphData() {

    // if ($.fn.DataTable.isDataTable('#asset_data_table')){
    //   $('#asset_data_table').DataTable().destroy();
    //   this.assetData=[];
    // }

    this.apiService.getAll('customer/asset/getAssetDatapointGraph?assetId=' + this.assetId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.assetData = data.response;
          if ($.fn.DataTable.isDataTable('#asset_data_table')){
            $('#asset_data_table').DataTable().destroy();
          };
          const table: any = $('#asset_data_table');
          this.dataTable   = table.DataTable({
            "order": [],"columnDefs": [{ "orderable": false, "targets": 2 }]
          });

          //
          // if ($.fn.DataTable.isDataTable('#asset_data_table')) {
          //   $('#asset_data').DataTable().destroy();
          // };
          // this.chRef.detectChanges();
          // const table: any = $('#asset_data');
          // this.dataTable = table.DataTable();
        } else {
          this.assetData = [];
          this.chRef.detectChanges();
          const table: any = $('#asset_data');
          if ($.fn.DataTable.isDataTable('#asset_data_table')){
            $('#asset_data_table').DataTable().destroy();
          };
          this.dataTable   = table.DataTable({
            "order": [],"columnDefs": [{ "orderable": false, "targets": 2 }]
          });

          //this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

 /* Get Asset Data By Specfic Channel Numbers*/
 getAssetDataD(assetChannelData) {

  this.assetDataObj = JSON.parse(JSON.stringify(assetChannelData))
  var dataPointArray = [];
  this.tempArray = [];
  this.graphRenderData = [];
  this.data = [];
  this.unitArray = ['date'];
  if (assetChannelData.assetGraphDataPointDTO) {
    Object.keys(assetChannelData.assetGraphDataPointDTO).forEach(key => {
      dataPointArray.push(assetChannelData.assetGraphDataPointDTO[key]['datapointId']);
    })
  }
  var channelNumber = dataPointArray.toString();
  // this.apiService.getAll('asset/drawAssetEventGraph?clientId=' + this.clientId + '&assetId=' + this.assetId +/*  '&assetGraphId=' + assetChannelData.assetGraphId */ '&channelNumber=' + channelNumber).subscribe(
  let start = $('#startDate').val();
  let end = $('#endDate').val()

  let diff = moment(end).diff(moment(start),'days')

  if(diff <0){
    this.DateError = "Please Enter valid Start Date and End Date"
} 
else {
  this.DateError = null
  // console.log('asset/drawAssetEventGraph?clientId='+this.clientId+'&assetId='+this.assetId+'&assetGraphId=' + assetChannelData.assetGraphId+ (start ? '&fromDate='+moment(start).format('YYYY-MM-DD HH:mm:ss'):'')+ (end ? "&toDate="+moment(end).format('YYYY-MM-DD HH:mm:ss'):''))
  this.apiService.getAll('customer/asset/drawAssetEventGraph?clientId='+this.clientId+'&assetId='+this.assetId+'&assetGraphId=' + assetChannelData.assetGraphId+ (start ? '&fromDate='+moment(start).format('YYYY-MM-DD HH:mm:ss'):'')+ (end ? "&toDate="+moment(end).format('YYYY-MM-DD HH:mm:ss'):'')).subscribe(
    data => {
      if (data.statusCode == 100 || data.statusCode == 103 ) {
        this.graphRenderData = data.responseList ? data.responseList : [];

        var graphData = [];

        let tempGraphObj = {}

        var self = this;

        // /* ****************************Dygraph code*********************latest graph code start************************************************************ */
        // /* *************************************************latest graph code start************************************************************ */

        // let GraphDataArray = [];  //==================================new data array
        // let unitArray = ["date"]
        // let responseAssetChennalID = []
        // //console.log(this.graphRenderData);

        // // this.graphRenderData
        // for (let index = 0; index < this.graphRenderData.length; index++) {
        //   const element = this.graphRenderData[index];
        //   if (responseAssetChennalID.length < dataPointArray.length) {
        //     for (const objData of element.dataPoints) {
        //       if (responseAssetChennalID.indexOf(objData.assetChannelId) == -1) {
        //         unitArray.push(objData.dataChannelDescription ? objData.dataChannelDescription : "ID-" + objData.assetChannelId)
        //         responseAssetChennalID.push(objData.assetChannelId)
        //       }
        //     }
        //     // console.log(unitArray,responseAssetChennalID)
        //   } else if (responseAssetChennalID.length == dataPointArray.length) {
        //     // console.log(unitArray,responseAssetChennalID)

        //     break;
        //   }
        // }



        // this.graphRenderData.map(function (rowData, index) {
        //   self.unitArray.push(rowData.description ? rowData.description : '')



        //   let arr = Array(dataPointArray.length + 1)
        //   arr[0] = moment(rowData.eventTimenew).toDate();

        //   // responseAssetChennalID.map((valOBjArr,indexIdArr)=>{
        //   //   arr[indexIdArr+1] = 0
        //   // })

        //   rowData.dataPoints.map((pointObj, pointIndex) => {
        //     let indexValue = responseAssetChennalID.indexOf(pointObj.assetChannelId)
        //     arr[indexValue + 1] = (pointObj.originalValue)
        //   })
        //   // console.log(arr)
        //   GraphDataArray.push(arr);


        //   /* *************************************************latest graph code end************************************************************ */




        //   // console.log(graphData)
        //   if (rowData.dataPoints.length == 0) {
        //     for (const key in graphData) {
        //       if (graphData.hasOwnProperty(key)) {
        //         graphData[key].push(null)
        //       }
        //     }

        //   }
        // });

        // // console.log(graphData)
        // var tempArr = [];
        // Object.keys(graphData).forEach(key => {
        //   tempArr.push(graphData[key]);
        // })
        // // console.log(JSON.stringify(tempArr), this.unitArray);
        // // this.tempArray = tempArr;

        // this.tempArray = GraphDataArray
        // // [[new Date('2019-01-12T00:28:00.000Z'),27270,null,null,44.36],[new Date("2019-01-13T09:44:01.000Z"),27189,null,null,41.99],[new Date("2019-01-14T09:44:01.000Z"),27439,null,null,41.93],[new Date("2019-01-12T05:43:01.000Z"),85,1798,37360,56],[new Date("2019-01-13T05:43:01.000Z"),7,799,3384,5],[new Date("2019-01-14T05:43:01.000Z"),52,198,12408,85],[new Date("2019-01-14T06:43:01.000Z"),85,2298,3208,96],[new Date("2019-01-14T07:43:01.000Z"),96,198,308,35],[new Date("2019-01-13T04:43:01.000Z"),17,178,3740,45],[new Date("2019-01-14T06:43:01.000Z"),0,1238,3408,75],[new Date("2019-01-15T08:43:01.000Z"),52,1798,378,75],[new Date("2019-01-16T04:43:01.000Z"),0,179,3778,45],[new Date("2019-01-14T14:43:01.000Z"),62,178,3708,78],[new Date("2019-01-12T18:43:01.000Z"),24,1798,37408,795]];


        // this.tempArray.sort(function compare(a, b) {
        //   var dateA: any = new Date(a[0]);
        //   var dateB: any = new Date(b[0]);
        //   return (dateA - dateB);
        // });

        // // console.log(dataPointArray.length ,"++++++++++++++++++++++++++++++", unitArray.length-1)
        // if (dataPointArray.length > unitArray.length - 1) {
        //   for (let indexValue = unitArray.length; indexValue < dataPointArray.length + 1; indexValue++) {
        //     unitArray.push('-' + indexValue)
        //     console.log(indexValue)
        //   }
        // }
        // /* -----------------------------remove line after test dummy data------------------------------------- */
        // this.unitArray = unitArray//['date','volts','','RPM','hours']

        // let obj = {
        //   labels: this.unitArray,//['date','volts','','RPM','hours'],
        //   series: {
        //     /* 'Y3': {
        //       axis: 'y2'
        //     },
        //     'Y4': {
        //       axis: 'y2'
        //     }, */
        //   },
        //   drawPoints: true,
        //   drawGapEdgePoints: true,
        //   connectSeparatedPoints: true,
        //   // fillGraph:true,
        //   // rollPeriod: 2,
        //   axes: {
        //     y: {
        //       axisLabelWidth: 60,
        //       independentTicks: true
        //     },
        //     y2: {
        //       // set axis-related properties here
        //       independentTicks: true
        //       // labelsKMB: true
        //     }

        //   }
        // }

        // let series = {

        // }
        // this.unitArray.map((val, index) => {
        //   if (index > 0) {
        //     if (index == 1) {
        //       obj['ylabel'] = val;
        //       // series[val] = {axis:'y'}

        //     } else if (index > 1) {
        //       obj['y' + index + 'label'] = val

        //       if (index % 2==0) {
        //         series[val] = { axis: 'y2' }
        //       }else{
        //         series[val] = { axis: 'y' }
        //       }
        //     }
        //   }
        // })
        // obj.series = series;

        // setTimeout(() => {
        //   let g = new Dygraph(
        //     document.getElementById("chart-container"),
        //     this.tempArray,
        //     obj
        //   );
        // }, 500);

        /* ***********************Dygraph code end**************************latest graph code end************************************************************ */


        /* ************************************Highchart start********************************************** */
        let channelidArray = []

        let yAxisArray = []
        let SeriesName = []
        let SerieslabelName = []


        let isDataObj = false;
        this.graphRenderData.map((obj,index)=>{
          // if (index == 0) {
            tempGraphObj[index] = []
          // }
          // SeriesName.push(obj.description)
          yAxisArray.push({ // third yAxis
            gridLineWidth: 0,
            lineWidth: 2,
            title: {
                text: obj.description,
                // style: {
                //     color: Highcharts.getOptions().colors[index]
                // }
            },
            labels: {
                format: '{value} ',
                // style: {
                //     color: Highcharts.getOptions().colors[index]
                // }
            },
            opposite: index%2==0 ? true :false,
        })
        let TZ = ""

        isDataObj = isDataObj ? true : (obj.dataPoints.length>0 ? true : false )
        if (obj.dataPoints.length) {
          SeriesName.push(obj.description /* dataPoints[0].dataChannelDescription*/ ? obj.description : null)//dataPoints[0].dataChannelDescription
        }else{
          SeriesName.push(obj.description)
        }
        SerieslabelName.push(obj.textstate+'('+obj.description+')')
        // isDataObj = isDataObj ? true : (obj.dataPoints.length ? true : false )

          obj.dataPoints.map((pointObj,pointIndex)=>{
            TZ = pointObj['tzt']
            // console.log( new Date(moment(pointObj.eventTime, "YYYY-MM-DD HH:mm:ss:sssZ").toDate()).getTime(),  new Date(pointObj.eventTime+' '+pointObj.tzt).getTime(), pointObj.eventTime);
            tempGraphObj[index].push([new Date(moment(pointObj.eventTime, "YYYY-MM-DD HH:mm:ss:sss").toDate()).getTime(),pointObj.originalValue])            
            // tempGraphObj[index].push([new Date(moment(pointObj.eventTime, "YYYY-MM-DD HH:mm:ss:sssZ").toDate()).getTime(),pointObj.originalValue])
          })
          this.TimeZone = TZ
        })

        let seriesData = []
        // console.log(tempGraphObj)
        // console.log(isDataObj);
        
        
        for (const key in tempGraphObj) {
          if (tempGraphObj.hasOwnProperty(key)) {
            const element = tempGraphObj[key];
            // console.log(element , "element");
            // isDataObj = isDataObj ? true : (element.length ? true : false )
            
            seriesData.push({
              data: element,
              lineWidth: 1,
              marker: {
                symbol: 'circle'
              },
              yAxis: Number(key),
              name: SerieslabelName[key]
          })
          }
        }

        let title =  data.statusCode == 103 ?  data.responseMessage : (!isDataObj ? "No Data Available" :assetChannelData.graphName)
        setTimeout(() => {


          (function(H) {
            H.wrap(H.Legend.prototype, 'positionCheckboxes', function(p, scrollOffset) {
              var alignAttr = this.group.alignAttr,
                translateY,
                clipHeight = this.clipHeight || this.legendHeight;
          
              if (alignAttr) {
                translateY = alignAttr.translateY;
                H.each(this.allItems, function(item) {
                  var checkbox = item.checkbox,
                    bBox = item.legendItem.getBBox(true),
                    top;
          
                  if (checkbox) {
                    top = (translateY + checkbox.y + (scrollOffset || 0) + 2);
                    H.css(checkbox, {
                      left: (alignAttr.translateX + item.checkboxOffset + checkbox.x - 80 - bBox.width) + 'px',
                      top: (top+4) + 'px',
                      display: top > translateY - 6 && top < translateY + clipHeight - 6 ? '' : 'none'
                    });
                  }
                });
              }
            });
          })(Highcharts);



            let chartObj = Highcharts.chart('chart-container-highchart', {

              chart: {
                  zoomType: 'x'
              },
              // exporting: { enabled: false },
              title: {
                  text: title
              },
              time:{
                useUTC:false,
              },
              // subtitle: {
              //     text: ''
              // },
              // noData: "no data available",
              tooltip: {
                  // valueDecimals: 2,
                  // crosshairs: true,
                  shared: true
              },
              legend: {
                enabled: true,
                symbolPadding: 20,
                itemMarginTop: 4,
                itemMarginBottom: 4,
              //   useHTML: true,
              //   symbolWidth: 0,
              //   labelFormatter: function() {
              //     return '<input type="checkbox">' + series.name
              //   }
              },
              plotOptions:{
                series:{
                  showCheckbox:true,
                  selected:true,
                  showInLegend: true,
                  events: {
                    legendItemClick: function() {
                      return false;
                    },
                    checkboxClick: function (event) {
                        if (event.checked) {
                            this.show();
                        } else {
                            this.hide();
                        }
                    }
                }
                }
              },


              exporting: {
                enabled:data.statusCode == 103 ? false : true,
                buttons: {
                    contextButton: {
                        // text: 'DOWNLOAD',
                        theme: {
                            fill: 'transparent',
                            //'stroke-width': 1,
                            //stroke: 'white',
                            //r: 1,
                            style: {
                                color: 'white',
                                fontSize: '10px'
                            },   

                            // states: {
                            //     hover: {
                            //         fill: 'transparent'
                            //     },
                            //     select: {
                            //         fill: 'transparent'
                            //     }
                            // }
                        },
                        menuItems: [{
                            text: 'Download PNG image',
                            onclick: function () {
                                this.exportChart({
                                    type: 'image/png'
                                });
                            }
                        }, {
                            text: 'Download JPEG image',
                            onclick: function () {
                                this.exportChart({
                                    type: 'image/jpeg'
                                });
                            }
                        }, {
                            text: 'Download PDF document',
                            onclick: function () {
                                this.exportChart({
                                    type: 'application/pdf'
                                });
                            }

                        }]
                    }
                },
                // filename: 'Map',
                allowHTML: true,
                chartOptions: {
                    chart: {
                        style: {
                            fontFamily: '"Gotham SSm A" "Gotham SSm B"'
                        },
                        height: 880,
                        spacingBottom: 300,
                        marginTop: 50,
                        width: 1010,
                        // backgroundColor: 'rgba(0, 0, 0, 0)',
//                         events: {
//                             load: function () {
//                                 this.series[0].update({
//                                     nullColor: 'rgba(245, 245, 245, 1)',
//                                     borderColor: 'rgba(192, 192, 192, 1)'
//                                 });
//                                 //DisableGeographiesOnExport(this);
//                                 //this.renderer.image('http://www.eiu.com/images/logos/EIU_logo2x.png', 0, 740, 100, 25).add();
//                                 //this.renderer.image('http://data.eiu.com/assets/images/charts/bubbles-legend.png', 600, 565, 20, 16).add();
//                                 this.renderer.label('<b>Size of opportunity</b>', 620, 565, null, null, null, true).add();
//                                 //var label = this.renderer.label(getExportPropertiesTable(), null, null, null, null, null, true).add();

// //                                label.align(Highcharts.extend(label.getBBox(), {
// //                                    align: 'center',
// //                                    x: 0, // offset
// //                                    verticalAlign: 'bottom',
// //                                    y: 160 // offset
// //                                }), null, 'spacingBox');  
//                             }
//                         }
                    },
                    legend: {
                        enabled: true,
                        verticalAlign: 'bottom',
                        align: 'center',
                        itemMarginTop: -10,
                        // itemStyle: {
                        //     color: 'rgb(0,0,0)'
                        // },
                        symbolRadius: 6,
                        symbolHeight: 13,
                        symbolWidth: 13                                                
                    },
                    // credits: {
                    //     enabled: true,
                    //     text: 'Copyright ©2015 The Economist Intellligence Unit.  Output generated April 30th 2014 11:22 GMT+00:00',
                    //     position: {
                    //       align: 'left',
                    //       x: 110,
                    //       verticalAlign: 'bottom',
                    //       y: -115
                    //     }
                    // },
                    title: {
                        text: assetChannelData.graphName,
                        style: {
                            fontWeight: 'bold'
                        }
                    }
                }
            },    

              
              xAxis: {
                  type: 'datetime'
              },
              yAxis: yAxisArray,

              series: seriesData
          });


          // chartObj.exportChart()
        }, 200);


        /* ************************************Highchart end********************************************** */

        this.graphCreate();
      } else {
        this.graphRenderData = [];
        this.alertService.sweetMessage('error', data.responseMessage);
      }



    },
    error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    }
  );
}
}


exportChart(assetChannelData){
  
  this.assetDataObj = JSON.parse(JSON.stringify(assetChannelData))
  var dataPointArray = [];
  this.tempArray = [];
  this.graphRenderData = [];
  this.data = [];
  this.unitArray = ['date'];
  if (assetChannelData.assetGraphDataPointDTO) {
    Object.keys(assetChannelData.assetGraphDataPointDTO).forEach(key => {
      dataPointArray.push(assetChannelData.assetGraphDataPointDTO[key]['datapointId']);
    })
  }
  var channelNumber = dataPointArray.toString();

  let start = $('#startDate').val();
  let end = $('#endDate').val()

  let diff = moment(end).diff(moment(start),'days')

  if(diff <0){
    this.DateError = "Please Enter valid Start Date and End Date"
} 
else {
  this.DateError = null
  this.apiService.getAll('asset/drawAssetEventGraph?clientId='+this.clientId+'&assetId='+this.assetId+'&assetGraphId=' + assetChannelData.assetGraphId+ (start ? '&fromDate='+moment(start).format('YYYY-MM-DD HH:mm:ss'):'')+ (end ? "&toDate="+moment(end).format('YYYY-MM-DD HH:mm:ss'):'')).subscribe(
    data => {
      if (data.statusCode == 100 || data.statusCode == 103 ) {
        this.graphRenderData = data.responseList ? data.responseList : [];

        var graphData = [];

        let tempGraphObj = {}

        var self = this;


        /* ************************************Highchart start********************************************** */
        let channelidArray = []

        let yAxisArray = []
        let SeriesName = []
        let SerieslabelName = []



        let isDataObj = false;
        this.graphRenderData.map((obj,index)=>{
          // if (index == 0) {
            tempGraphObj[index] = []
          yAxisArray.push({ // third yAxis
            gridLineWidth: 0,
            lineWidth: 2,
            title: {
                text: obj.description,
            },
            labels: {
                format: '{value} ',
            },
            opposite: index%2==0 ? true :false,
        })
        let TZ = ""

        isDataObj = isDataObj ? true : (obj.dataPoints.length>0 ? true : false )
        if (obj.dataPoints.length) {
          SeriesName.push(obj.description /* dataPoints[0].dataChannelDescription*/ ? obj.description : null)//dataPoints[0].dataChannelDescription
        }else{
          SeriesName.push(null)
        }
        SerieslabelName.push(obj.textstate)

          obj.dataPoints.map((pointObj,pointIndex)=>{
            TZ = pointObj['tzt']              
            // tempGraphObj[index].push([new Date(moment(pointObj.eventTime, "YYYY-MM-DD HH:mm:ss:sssZ").toDate()).getTime(),pointObj.originalValue])
            tempGraphObj[index].push([new Date( moment( pointObj.eventTime , "YYYY-MM-DD HH:mm:ss:sss").toDate()).getTime(),pointObj.originalValue])

          })
          this.TimeZone = TZ
        })

        let seriesData = []
        for (const key in tempGraphObj) {
          if (tempGraphObj.hasOwnProperty(key)) {
            const element = tempGraphObj[key];
            seriesData.push({
              data: element,
              lineWidth: 1,
              marker: {
                symbol: 'circle'
              },
              yAxis: Number(key),
              name: SerieslabelName[key]
          })
          }
        }

        let title =  data.statusCode == 103 ?  data.responseMessage : (!isDataObj ? "No Data Available" :assetChannelData.graphName)
        setTimeout(() => {


          (function(H) {
            H.wrap(H.Legend.prototype, 'positionCheckboxes', function(p, scrollOffset) {
              var alignAttr = this.group.alignAttr,
                translateY,
                clipHeight = this.clipHeight || this.legendHeight;
          
              if (alignAttr) {
                translateY = alignAttr.translateY;
                H.each(this.allItems, function(item) {
                  var checkbox = item.checkbox,
                    bBox = item.legendItem.getBBox(true),
                    top;
          
                  if (checkbox) {
                    top = (translateY + checkbox.y + (scrollOffset || 0) + 2);
                    H.css(checkbox, {
                      left: (alignAttr.translateX + item.checkboxOffset + checkbox.x - 100 - bBox.width) + 'px',
                      top: top + 'px',
                      display: top > translateY - 6 && top < translateY + clipHeight - 6 ? '' : 'none'
                    });
                  }
                });
              }
            });
          })(Highcharts);



            let chartObj = Highcharts.chart('chart-container-highchart', {

              chart: {
                  zoomType: 'x'
              },
              // exporting: { enabled: false },
              title: {
                  text: title
              },
              time:{
                useUTC:false,
              },
              tooltip: {
                  shared: true
              },
              legend: {
                enabled: true,
                symbolPadding: 20
              },
              plotOptions:{
                series:{
                  showCheckbox:true,
                  selected:true,
                  showInLegend: true,
                  events: {
                    legendItemClick: function() {
                      return false;
                    },
                    checkboxClick: function (event) {
                        if (event.checked) {
                            this.show();
                        } else {
                            this.hide();
                        }
                    }
                }
                }
              },


              exporting: {
                buttons: {
                    contextButton: {
                        // text: 'DOWNLOAD',
                        theme: {
                            fill: 'transparent',
                            style: {
                                color: 'white',
                                fontSize: '10px'
                            },   

                        },
                        menuItems: [{
                            text: 'Download PNG image',
                            onclick: function () {
                                this.exportChart({
                                    type: 'image/png'
                                });
                            }
                        }, {
                            text: 'Download JPEG image',
                            onclick: function () {
                                this.exportChart({
                                    type: 'image/jpeg'
                                });
                            }
                        }, {
                            text: 'Download PDF document',
                            onclick: function () {
                                this.exportChart({
                                    type: 'application/pdf'
                                });
                            }

                        }]
                    }
                },
                // filename: 'Map',
                allowHTML: true,
                chartOptions: {
                    chart: {
                        style: {
                            fontFamily: '"Gotham SSm A" "Gotham SSm B"'
                        },
                        height: 880,
                        spacingBottom: 300,
                        marginTop: 50,
                        width: 1010,
                    },
                    legend: {
                        enabled: true,
                        verticalAlign: 'bottom',
                        align: 'center',
                        itemMarginTop: -10,
                        symbolRadius: 6,
                        symbolHeight: 13,
                        symbolWidth: 13                                                
                    },
                    title: {
                        text: data.statusCode == 103 ? 'No Record Found' : assetChannelData.graphName,
                        style: {
                            fontWeight: 'bold'
                        }
                    }
                }
            },    
              xAxis: {
                  type: 'datetime'
              },
              yAxis: yAxisArray,

              series: seriesData
          });


          // chartObj.exportChart()
          if (data.statusCode == 103) {
            self.alertService.sweetMessage('error', 'No data to export.');
           }else{
             chartObj.exportChart()
           }
        }, 200);


        /* ************************************Highchart end********************************************** */

        this.graphCreate();
      } else {
        this.graphRenderData = [];
        this.alertService.sweetMessage('error', data.responseMessage);
      }



    },
    error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    }
  );
}
}

  /* Create Graph */
  graphCreate() {
    // this.data = this.tempArray;
    // /*,labels: this.unitArray*/
    // let obj = { width: 'auto', labels: this.unitArray, xlabel: 'Date', ylabel: 'Unit',y2label: 'Unit2', animatedZooms: true, pointSize: 4 };
    // this.unitArray.map((val,index)=>{
    //   if (index==0) {
    //     obj['ylabel'] = val;
    //   }else{
    //     obj['y'+index+'label'] = val
    //   }
    // })

    // console.log(obj)
    // this.options1 = obj;
    // options2:{width: 'auto', labels: this.unitArray, xlabel: 'Date', ylabel: 'Unit', animatedZooms: true, pointSize: 4}


  }

  getGraphById(assetGraphId) {
    this.assetGraphId = assetGraphId;
    this.apiService.getAll('asset/getAssetDataPointGraphById?assetGraphId='+this.assetGraphId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.assetDataPoint.graphName = data.response.graphName;

          if (data.response.assetGraphDataPoints) {
            var tempData=[];
            setTimeout(()=>{
              // this.checkAsset = [{assetChannelId: 452347}];
              Object.keys(data.response.assetGraphDataPoints).forEach(key => {
                tempData.push({assetChannelId:parseInt(data.response.assetGraphDataPoints[key]['dataPoint'])})
                this.checkAssetArray.push(parseInt(data.response.assetGraphDataPoints[key]['dataPoint']));
              })
              this.checkAsset = tempData;
            },500)
          }
        } else {
          this.alertService.sweetMessage('error', data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );


  }


deleteGraphData(id) {

  this.apiService.getAll('asset/deleteAssetGraph?assetGraphId='+id).subscribe(
    data => {
      if (data.statusCode == 100) {
        this.assetGraphId = 0;
        this.getAllAssetGraphData();
        this.alertService.sweetMessage('success', "Graph deleted Successfully");
      } else {
        this.alertService.sweetMessage('error', data.responseMessage);
      }
    },
    error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    }
  );

}

getDataPointName(datapoint){
  if (datapoint) {
    let name = ''
    if (datapoint.length) {
      datapoint.map((val,index)=>{
        if (name) {
          if (val.datapointName) {
            name = name.trim() + ', ' +val.datapointName
          }
        } else {
          name += val.datapointName            
        }          
      })
      return name
    }
  } else {
    return '-'
  }
}


}
