import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, FormatDateService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {
clientId: any = localStorage.getItem("clientId");
userId: any = localStorage.getItem("userId");
assetId :any = 0;
assetsumaryData : any ={};
assetdocumentData : any =[];
dataTable: any;
imageName:any={};
title:any="";
ApiUrl = null;
siteLat:number = 39.381266;
siteLong:number = -97.922211;
clientCode : any = '' ;
// permissionData:any=[];
imageurl : any = 'assets/img/upload-img.png';
  breadcrumbObj: any;

constructor(
  private router:Router,
  private route: ActivatedRoute, 
  private apiService: ApiService , 
  private formatDateService: FormatDateService,
  private alertService: AlertService, 
  private chRef: ChangeDetectorRef
  ) {
  // this.permissionData = ApiService.permission;
 }

  // Function to format Date
  DateFormat(startDate, gmtOffset){
    if (startDate) {
      var formattedDate = moment(startDate,"YYYY-MM-DD HH:mm:ss:SSS").format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    } else {
      return '-'
    }
  }

  ngOnInit() {
    this.clientCode = this.alertService.getClientCode();
    this.ApiUrl = this.apiService.imageBasePath;
    this.route.params.subscribe(params => {
      if(params['id']){
        this.assetId = params['id'];
      }
    });
    this.route
      .queryParams
      .subscribe(params => {
          this.breadcrumbObj = params['source'];
      })
    if(this.assetId){
      this.getAssetSummaryData();
      this. getAssetDocumentData();
    }
  }

  // Function to set latitude and longitude
  setLatLong(lat,long){
    this.siteLat = lat;
    this.siteLong = long;
  }

  // Listen For event Recive from child
    reciveAsset(){
      this.getAssetSummaryData();
    }

  /* Function for getting Asset Summary Data */
  getAssetSummaryData(){
    this.apiService.getAll('customer/asset/getCustomerAssetSummaryById?assetId='+this.assetId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.assetsumaryData= data.response;

        }else{
          this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }


  // Function called on file change
    onFileChanged(event) {
        const file = event.target.files;
        this.imageName = file;
        var reader = new FileReader();
        var ext = this.imageName[0].name.split('.').pop().toLowerCase();
        if(ext == "docx" || ext == "xls"|| ext == "doc"|| ext == "xlsx"|| ext == "pdf"){
          this.imageurl = 'assets/img/'+ ext +'.png';
        }else if(ext == "jpg" || ext == "png" || ext == "jpeg"){
          this.imageurl = 'assets/img/upload-img.png';
        }else{
          this.CancelUploadDoc();
          this.alertService.sweetMessage('error','Invalid file type.');
          return false;
        }
        reader.readAsDataURL(file[0]);
        reader.onload = (_event: any) => {
            var img = new Image();
            if(ext == "jpg" || ext == "png" || ext == "jpeg"){
              img.src = _event.target.result;
            }
            img.onload = () => {
                this.imageurl = reader.result as string;
            };
        }

    }


    CancelUploadDoc(){
        this.imageurl = 'assets/img/upload-img.png';
        $("#FileUploadDoc").val('');
    }
  // Function called on file changed
  documentSave() {
    if(this.title){
      if(this.imageName.length){
      const uploadData = new FormData();
       uploadData.append('file', this.imageName[0], this.imageName[0].name);
       this.apiService.create('assetDocument/uploadDocument?assetId='+this.assetId+'&userId='+this.userId+'&title='+this.title, uploadData).subscribe(
        data => {
            if(data.statusCode == 100){
              $('#uploadModal').modal('hide');
              this.imageurl = 'assets/img/upload-img.png';
              this. getAssetDocumentData();
              this.title = "";
              this.alertService.sweetMessage('success','Document uploaded successfully');
            }else{
                this.alertService.sweetMessage('error',data.responseMessage);
                  }
                },
            error =>{
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
      }else{
        this.alertService.sweetMessage('error','Please upload document');
      }
    }else{
        this.alertService.sweetMessage('error','Please enter image title');
    }
  }

  /* Function for getting Document */
  getAssetDocumentData(){
    if ($.fn.DataTable.isDataTable('#assetDoc')){
      $('#assetDoc').DataTable().destroy();
    };
    this.apiService.getAll('customer/assetDocument/getAssetDocumentByAsset?assetId='+this.assetId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.assetdocumentData= data.responseList;          
          this.chRef.detectChanges();
            const table: any = $('#assetDoc');
            this.dataTable = table.DataTable({aaSorting: [[4, 'desc']]});
        }else{
          this.assetdocumentData= [];
          if ($.fn.DataTable.isDataTable('#assetDoc')){
            $('#assetDoc').DataTable().destroy();
          };
          this.chRef.detectChanges();
          const table: any = $('#assetDoc');
          this.dataTable = table.DataTable({aaSorting: [[4, 'desc']]});
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  // Function to download Document
  downloadDoc(url){
    return window.location.href= url;
  }

  DeleteDocument (documentId) {
    Swal({
      title: 'Are you sure?',
      text: "You want to delete this Document.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        this.apiService.delete('assetDocument/deleteAssetDocument?assetDocumentId='+documentId+'&userId='+this.userId,'').subscribe(
        data => {
          if(data.statusCode == 100){
            this.alertService.sweetMessage('success','Document deleted successfully');
            this.getAssetDocumentData();
          }
          else
            { this.alertService.sweetMessage('error',data.responseMessage); }
        },
       error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
      })
  }

}
