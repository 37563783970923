import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'CUSTOMER-ADMIN';
  ngOnInit(): void {
    $('.parentelement ngx-loading-bar').addClass('loading-bar-fixed');
    setTimeout( () => {
      setInterval( () => { 
        if($("#loading-bar-spinner").length == 0) {
          console.log("id not exist");
          $('.parentelement ngx-loading-bar').removeClass('loading-bar-fixed');
        } else {
          console.log("id exist");
          $('.parentelement ngx-loading-bar').addClass('loading-bar-fixed');
        }
      },500);
    }, 500);
  }
  
}


