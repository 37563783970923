import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
//import { LoginModule } from './login/login.module';
import { ViewModule } from './admin/view.module';

@NgModule({
  imports: [
    CommonModule,
  //  LoginModule,
    ViewModule,
    FormsModule    
  ],
  declarations: []
})
export class StructureModule { }
