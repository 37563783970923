import { Component, OnInit,ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Router , ActivatedRoute} from '@angular/router';
import { ApiService , AlertService} from '../../../../services/index';
import Swal from 'sweetalert2'
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';
import * as NProgress from 'nprogress';

@Component({
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {

  // @ViewChild('addCustomer') addCustomer:any
  @ViewChild('customerContact') customerContact:any
  @ViewChild('imageUrlInnerName') imageUrlInnerName: ElementRef;
  @ViewChild('imageUrlName') imageUrlName: ElementRef;

customerId: any = localStorage.getItem("customerId");
userId: any = localStorage.getItem("userId");
clientId: any = localStorage.getItem("clientId");

contactUserId:number=0;
countryList:any=[];
stateList:any=[];
contactData:any=[];
dataTable: any;
customer: any = {"name":"","isActive":1,"emailId":"","billingAddress":"","city":"","country":{"countryId":""},"state":{"stateId":""}, "sites": [],"contactNumber":"","alternateNumber":"","client":{"clientId":this.customerId}};
contact: any =
{
  "firstName":"",
  "lastName":"",
  "emailId":"",
  "phoneNumber":"",
  "secondaryPhoneNumber":"",
  "client":{"clientId":this.clientId},
   "customer":{"customerId":this.customerId}
}
  section : any;
  imageUrl = "assets/img/upload-img.png";
  imageUrl_inner = "assets/img/upload-img.png";
  imageName: any = {};
  imageName_inner: any = {};
  imgBasePath:any="";
  imgBasePath_inner:any="";
  UserImage = null;
  oldImage:any = ''
  imageTitle = "";
  innerImageTitle = "";
  imageNameInner: any;

constructor(private router:Router,private route: ActivatedRoute, private alertService: AlertService,private apiService: ApiService, private chRef: ChangeDetectorRef) { }

 ngOnInit() {

    this.getContactData();
    setTimeout(()=>{
      this.getCountryList();
      this.getCustomerById();
    },1000)
}


/* Create Customer */
SubmitCustomer(formValue){
  if (!formValue.invalid && (this.customer.alternateNumber != this.customer.contactNumber)) {
    // if (this.customer.country) {
    //   if (!this.customer.country.countryId) {
    //     this.customer.country = {}
    //   }
    // }
    // if (this.customer.state) {
    //   if (!this.customer.state.stateId) {
    //     this.customer.state = {}
    //   }
    // }
    //   console.log(this.addCustomer.valid,this.addCustomer)
    // return
    // if (this.addCustomer.valid) {
      this.apiService.update('customer/updateCustomer', this.customer).subscribe(
      data => {
          if(data.statusCode == 100){
            this.alertService.sweetMessage('success',"Customer Profile Updated Successfully ");
            // this.imageSave();
            // if (this.imageName != '') {
            //   this.imageSave(2, this.imageName);
            // }
            // if (this.imageNameInner) {
            //   this.imageSave(17, this.imageNameInner);
            // }
            this.getUserInfo();
          }else{
            this.alertService.sweetMessage('error',data.responseMessage);
          }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      });
    // }
  }
}

  /* Create Contact */

SubmitContact(){
  this.contact.phoneNumber = this.contact.phoneNumber ? this.contact.phoneNumber : '';
  this.contact.secondaryPhoneNumber = this.contact.secondaryPhoneNumber ? this.contact.secondaryPhoneNumber : '';
  if (this.customerContact.valid && (this.contact.phoneNumber != this.contact.secondaryPhoneNumber)) {

    if(this.contactUserId == 0){
      this.apiService.create('customer/createContactType', this.contact).subscribe(
       data => {
           if(data.statusCode == 100){
             this.alertService.sweetMessage('success','Contact details added successfully');
             this.getContactData();
             this.contact = {"firstName":"","lastName":"","emailId":"","phoneNumber":"","mobileNumber":"","client":{"clientId":this.clientId},"customer":{"customerId":this.customerId}};
             this.customerContact.submitted = false;
            }else{
               this.alertService.sweetMessage('error',data.responseMessage);
             }
           },
           error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
           }
       );
     }
     else{
       this.apiService.update('customer/updateCustomerContact', this.contact).subscribe(
       data => {
          if(data.statusCode == 100){
           this.alertService.sweetMessage('success','Contact details updated successfully');
           this.getContactData()
           this.contact = {"firstName":"","lastName":"","emailId":"","phoneNumber":"","mobileNumber":"","client":{"clientId":this.clientId},"customer":{"customerId":this.customerId}};
           this.customerContact.submitted = false;
           this.contactUserId = 0;
          }else{
              this.alertService.sweetMessage('error',data.responseMessage);
                 }
               },
           error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
           }
       );
     }
  }

}


/* Get Contact data list */
getContactData(){
  if ($.fn.DataTable.isDataTable('#customer_contact_table')) {
    $('#customer_contact_table').DataTable().destroy();
  };
  this.apiService.getAll('customer/getCustomerContactListByClientId?clientId='+this.clientId+'&customerId='+this.customerId).subscribe(
    data => {
        if(data.statusCode == 100){
            this.contactData = data.responseList;
            this.chRef.detectChanges();
          const table: any = $('#customer_contact_table');
          this.dataTable = table.DataTable({"order": [], "columnDefs": [{"orderable": false, "targets": [4]}]});
        }else{
          this.contactData = [];
          this.chRef.detectChanges();
          const table: any = $('#customer_contact_table');
          this.dataTable = table.DataTable({"order": [], "columnDefs": [{"orderable": false, "targets": [4]}]});
              }
            },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );
}


/* Get Contact by Id*/
getContactById(contact){
  this.contactUserId = contact.contactTypeId;
    this.apiService.getAll('customer/getContactTypeById?contactTypeId='+this.contactUserId).subscribe(
      data => {
       if(data.statusCode == 100){
          this.contact = data.response;
        }else{
          this.contact = {};
         this.alertService.sweetMessage('error',data.responseMessage);
         }
      },
     error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );
}

 // Function to Save Image
 imageSave(/* section, fileData */) {
  let uploadData = new FormData();
  // console.log(this.imageName[0].size/1024>500,)

  // if (this.imageName[0].size/1024<500) {
    
  // }
   if(this.imageName.length){
      uploadData.append('file', this.imageName[0], this.imageName[0].name);
      this.apiService.create('customer/uploadImage/uploadImage?section='+this.section+'&sectionId=' + this.customerId, uploadData).subscribe(
          data => {
            if (data.statusCode == 100) {
                this.getCustomerById();
            } else {
                this.alertService.sweetMessage('error',data.responseMessage);
            }
            // this.getUserInfo();
          },
         error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          // this.getUserInfo();
       }
      );
  }else{
    this.getUserInfo();
  }
}

// Function to update Profile Image
onFileChanged(event) {
  this.imageTitle = ''
  const file = event.target.files;
  const fileType = file[0]['type'];
  const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  if ((file[0].size/1024) > 300)
  { 
    this.imageUrl = "assets/img/upload-img.png";
    this.alertService.sweetMessage('error',"Size should be below 300 KB");return;
  }
  else if (!validImageTypes.includes(fileType)) {
    this.imageUrl = "assets/img/upload-img.png";
    this.alertService.sweetMessage('error',"Only PNG/JPG/JPEG images are allowed");return;
  }
  else 
  {
    // if(file[0].type != 'image/png' && file[0].type != 'image/jpg' &&  file[0].type != 'image/jpeg'){
    // if (!validImageTypes.includes(fileType)) {
    //   this.alertService.sweetMessage('error',"Only PNG/JPG/JPEG image are allowed");
    //   return;
    // }
    // this.imageName = file;
    
    this.section = 2
    var reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = (_event: any) => {
    var img = new Image();
    img.src = _event.target.result;
    img.onload = () => {
      this.imageUrl = reader.result as string;
          if (img.width <= 300 && img.height <= 300) {
              this.imageUrl = reader.result as string;
              this.imageName = file;
              this.imageTitle = file[0]['name'];
                if (this.customerId != 0) {
                  this.imageSave();
                }
          } else {
              this.imageName = null
              // this.imageUrl = this.UserImage ? this.UserImage : "assets/img/upload-img.png";
              this.imageUrl = "assets/img/upload-img.png";
              Swal({
                position: 'top-end',
                type: 'error',
                title: 'Image should not be greater than 300 X 300',
                showConfirmButton: false,
                timer: 2500
              })
          }
    };
  }
  // if (this.customerId != 0) {
  //     this.imageSave();
  // }
  }
}


// onFileChanged(event) {
//   const file = event.target.files;
//   this.imageName = file;

//   var reader = new FileReader();
//   reader.readAsDataURL(file[0]);
//   reader.onload = (_event: any) => {
//       var img = new Image();
//       img.src = _event.target.result;
//       img.onload = () => {
//           this.imageUrl = reader.result as string;

//           if (img.width <= 300 && img.height <= 300) {
//               this.imageUrl = reader.result as string;
//               if (this.customerId != 0) {
//                   this.imageSave();
//               }
//           } else {
//             this.alertService.sweetMessage('error','Image should not be greater than 300 X 300');
//             this.imageUrl = "assets/img/upload-img.png";
//           }
//       };
//   }

// }


// Function to update file inner page logo
onFileChangedInnerPageLogo(event) {
  this.innerImageTitle = '';
  const file = event.target.files;
  const fileType = file[0]['type'];
  const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  if ((file[0].size/1024) > 300)
  { 
    // this.imageNameInner = null
    this.imageName = null
    this.imageUrl_inner = "assets/img/upload-img.png";
    this.alertService.sweetMessage('error',"Size should be below 300 KB");return;
  }
  else if (!validImageTypes.includes(fileType)) {
    // this.imageNameInner = null
    this.imageName = null
    this.imageUrl_inner = "assets/img/upload-img.png";
    this.alertService.sweetMessage('error',"Only PNG/JPG/JPEG images are allowed");return;
  }else{
  // if ((file[0].size/1024)>500)
  //     { this.alertService.sweetMessage('error',"Size should be below 500 KB");  }
  // else {
  //   if(file[0].type != 'image/png' && file[0].type != 'image/jpg' &&  file[0].type != 'image/jpeg'){
  //       this.alertService.sweetMessage('error',"Only PNG/JPG/JPEG image are allowed");
  //       return;
  //     }

    // this.imageName = file;
    this.section   = 17
    var reader     = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = (_event: any) => {
      var img = new Image();
      img.src = _event.target.result;
      img.onload = () => {
        // this.imageUrl_inner = reader.result as string;
        if (img.width <= 300 && img.height <= 300) {
          this.oldImage = JSON.parse(JSON.stringify(this.imageUrl_inner))
          // this.imageNameInner = file
          this.imageName = file;
          this.imageUrl_inner = reader.result as string;
          this.innerImageTitle = file[0]['name'];
          this.imageSave();
        } else {
          this.imageName = null;
          this.imageNameInner = null
          // this.imageUrl_inner = this.oldImage;
          this.imageUrl_inner = "assets/img/upload-img.png";
          Swal({
              position: 'top-end',
              type: 'error',
              title: 'Image should not be greater than 300 X 300',
              showConfirmButton: false,
              timer: 2500
          })
        }
      };
    }
  }
}



/* Get Customer by Id*/
getCustomerById(){
  this.apiService.getAll('customer/getCustomerById?customerId='+this.customerId).subscribe(
  data => {
    if(data.statusCode == 100){
        this.customer = data.response;
        if(this.customer.imageLocation != null){
            // profile imageUrl
            // this.imageUrl = data.response.imageLocation ? this.apiService.imageBasePath+data.response.imageLocation : this.imageUrl;
          let imgArr = this.customer.imageLocation.split('/');
          this.imageTitle = imgArr[1];
          this.imageUrl = this.apiService.imageBasePath + this.customer.imageLocation;
        }else{
          this.imageUrl = this.imageUrl;
          this.imageTitle = "";
        }

        // // Inner page logo
            // this.imageUrl_inner = data.response.customerInnerlogoPath ? this.apiService.imageBasePath+data.response.customerInnerlogoPath : this.imageUrl_inner;
            // this.oldImage = JSON.parse(JSON.stringify(this.imageUrl_inner))

        // Inner page logo
        if(this.customer.customerInnerlogoPath == null){
          this.imageUrl_inner = this.imageUrl_inner;
          this.innerImageTitle = "";
        }else{
          let innerImgArr = this.customer.customerInnerlogoPath.split('/');
          this.innerImageTitle = innerImgArr[2];
          this.imageUrl_inner = this.apiService.imageBasePath + this.customer.customerInnerlogoPath;
        }

        if (data.response.country) {
          this.getStateListByCountryId(data.response.country.countryId);
        } else {
            this.customer.country = {countryId:""};
        }
        if (this.customer.state === null) {
            this.customer.state = {stateId:""};
        }

    }else{
      this.alertService.sweetMessage('error',data.responseMessage);
    }
  },
  error => {
    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
  });
}

/*
this.UserImage = data.response.imageLocation ? this.apiService.imageBasePath+data.response.imageLocation : this.imageUrl;
console.log(this.imageUrl)
}
if(data.response.country){
this.getStateListByCountryId(data.response.country.countryId);
}else{
*/

changeCountry(){
  this.customer.state = {stateId:""}; 
}

  /* Get state list by country id */
  getStateListByCountryId(countryId){
    this.stateList = [];
    if(countryId != ""){
      this.apiService.getAll('state/getStateList/'+countryId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.stateList = [];
          this.stateList = data.responseList;
        }else{
          this.stateList = [];
          //this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
      error => {
        this.stateList = [];
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      });
    }
  }

  /* Get Country list */
  getCountryList(){
   this.apiService.getAll('country/getCountry').subscribe(
   data => {
       if(data.statusCode == 100){
          this.countryList = data.responseList;
        }else{
          this.alertService.sweetMessage('error',data.responseMessage);
         }
      },
     error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );
      }

  /* Delete Contact User*/
  DeleteUser(userId){
  Swal({
   title: 'Are you sure?',
   text: "You want to delete this Contact.",
   type: 'warning',
   showCancelButton: true,
   confirmButtonColor: '#3085d6',
   cancelButtonColor: '#d33',
   confirmButtonText: 'Yes, delete it!'
 }).then((result) => {
   if (result.value) {
    this.apiService.getAll('customer/deleteCustomerContact?contactTypeId='+userId).subscribe(
    data => {
        if(data.statusCode == 100){
             this.alertService.sweetMessage('success',"Contact deleted successfully");
             this.getContactData();
         }else{
           this.alertService.sweetMessage('error',data.responseMessage);
          }
       },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
         );
     }
 })
 }

 
 getUserInfo(){
  this.apiService.getUserInfoData('customer/user/getUserInfo').subscribe(
    data => {
      if(data.statusCode == 100){
        ApiService.permission = data.response.permissions;
        if(ApiService.permission){
          ApiService.isPermission = 1;
        }
        localStorage.setItem("permission",JSON.stringify(data.response.permissions));
        localStorage.setItem("customerData", JSON.stringify(data.response));
        localStorage.setItem("userId", data.response.userId);
        localStorage.setItem("clientId", data.response.clientId);
      }else if(data.statusCode == 105){
      var msg = data.responseMessage;
     }else{
     }
    this.router.navigate(['/dashboard']);
  },
  error => {
  });
}

  removeInputImage(type: any) {
    //  clear file data after operation 
    if (type == 2) {
      this.imageUrlName.nativeElement.value = "";
      this.imageTitle = '';
    } else if (type == 17) {
      this.imageUrlInnerName.nativeElement.value = "";
      this.innerImageTitle = '';
    }
  }
  // function for deleting image of branding 
  removeBrandingImage(section, sectionName) {
    Swal({
      title: 'Are you sure?',
      text: `You want to remove ${sectionName}.`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        this.apiService.delete("customer/uploadImage/removeImage?section=" + section + "&sectionId=" + this.customerId, '').subscribe(
          data => {
            if (data.statusCode == 100) {
              this.alertService.sweetMessage('success', `${sectionName} removed successfully`);
              if (section == 2) {
                this.imageUrl = 'assets/img/upload-img.png'
              } else if (section == 17) {
                this.imageUrl_inner = 'assets/img/upload-img.png'
              }
            } else {
              this.alertService.sweetMessage('error', data.responseMessage);
            }
          },
          error => {
            this.alertService.sweetMessage('error', "Something went wrong, please try again.");
          }
        );
      }
    })
  }
}
