import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';
import { jqxTreeComponent } from '../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../jqwidgets-ts/angular_jqxdropdownbutton'

@Component({
    selector: 'app-add-report',
    templateUrl: './add-report.component.html',
    styleUrls: ['./add-report.component.css']
})

export class AddReportComponent implements OnInit {
    @ViewChild('myTree') myTree: jqxTreeComponent;
    @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;
    clientId: any = localStorage.getItem("clientId");
    userId: any = localStorage.getItem("userId");
    customerId: any = (localStorage.getItem("customerId") ? JSON.parse(localStorage.getItem("customerId")) : []);
    tierId: any = (localStorage.getItem("tierId") ? JSON.parse(localStorage.getItem("tierId")) : []);
    dropdownSettingsSite = {};
    dropdownSettings = {};
    dropdownSettingsUser ={};
    dropdownSettingsAsset ={};
    siteList: any = [];
    customerList: any = [];
    tierListData: any = [];
    data: any = [];
    dataAdapter: any = [];
    source: any = {};
    records: any = [];
    disableTreeArray: any = [];
    checkTreeArray: any = [];
    tiersArray: any = [];
    reportObjects:any=[];
    reportUsers:any=[];
    reportId: any = 0;
    reportperiod: any = "";
    weekdayMapData:any = 0;
    userListDataForTable1:any=[];
    userListDataForTable:any=[];
    userListTableData: any = [];

    userListData:any = {
      "firstName":"",
      "email":"",
      "phoneNumber":"",
      "mobileNumber":"",
      "title":""
    };
    

    recurringPattern:any = {
      "isMonday":"",
      "isTuesday":"",
      "isWednesday":"",
      "isThursday":"",
      "isFriday":"",
      "isSaturday":"",
      "isSunday":""
    }

    frequencyDataForCheck:any = {
      "frequencyStep":"",
      "weekdayMap":"",
      "dateOfMonth":"",
      "weekOfMonth":"",
      "weekdayOfMonth":"",
      "monthOfYear":""
    }

    addFormData:any = {
      "reportTime":"00:00:00",
      "isActive":true,
      "name":"",
      "description":"",
      "frequencyStep":"",
      "weekdayMap":"",
      "dateOfMonth":"1",
      "weekOfMonth":"",
      "weekdayOfMonth":"",
      "monthOfYear":"",
      "emailAddress" : ""
    }

    reportData:any = {
      "isActive":true,
      "frequencyType":"",
      "name":"",
      "description":"",
      "frequencyStep":"",
      "reportTime":"",
      "weekdayMap":"",
      "dateOfMonth":"",
      "weekOfMonth":"",
      "weekdayOfMonth":"",
      "monthOfYear":"",
      "users":[],
      "customers":[],
      "tiers":[],
      "sites":[],
      "reportType" : '',
      "client" : {
            "clientId" : (localStorage.getItem("clientId") ? JSON.parse(localStorage.getItem("clientId")) : [])
        }
      }

    frequencyData: any = {};
    checkSiteArray: any = [];
    checkCustomerArray: any = [];
    checkAssetArray: any = [];
    checkUserArray: any = [];
    radioSelected: string = 'Daily';
    checkTreeArrayData: any = [];
    siteIdForApi:any;
    tierIdForApi:any;
    customerIdForApi:any;
    reportForUser:any=[];
    dataForShowTable:any = [];
    userIdForshow :any = 0;

    selectedItems: any = [];
    selectedSite: any = [];
    selectedcustomer: any = [];
    selectedsite: any = [];
    selecteuser: any = [];
    selectedUser: any = [];
    user : any = [];
    afterEditData:any=[];
    showSelectedUserTable:any=[];
    checkDataForchecked:any=0;

    monthlyRadioSelected : string;
    YearlyRadioSelected : string;

    resultForSite:any      =  [];
    resultForUser:any      = [];
    resultForAsset:any      = [];
    resultForCustomer:any  = [];
    clientCode: any = '';
    preivewData: any = [];
    permissionData:any=[];
    assetDatalist: any = [];
    selectedAsset: any = [];
    selectedasset: any = [];

    constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService, private chRef: ChangeDetectorRef) {
        
    }

    // Function to format Date
    DateFormat(startDate){
        if(startDate){
            return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
        }else{
            return "";
        }
    }

    ngOnInit() {                
        setTimeout(function(){
            $('.frequencyStep').val(1);
        }, 200);

        jQuery('.rst-time').datetimepicker({
            pickDate: false,
            pickTime: true,
            pickSeconds: false,
            pickMinutes: true,
            timeFormat: "HH:mm"
        });

        this.clientCode = this.alertService.getClientCode();
        this.route.params.subscribe(params => {
            if (params['reportId']) {
                this.reportId = params['reportId'];
            }
        });
        if (this.reportId) {
            this.getReportById();
        }


        this.getSiteManagementList();
        this.getCustomerList();
        this.getTierList();
        this.getAllAssetList();

        this.dropdownSettingsSite = {
            singleSelection: false,
            idField: 'siteId',
            textField: 'siteName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 0,
            allowSearchFilter: true
        };
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'customerId',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 0,
            allowSearchFilter: true
        };

        this.dropdownSettingsAsset = {
            singleSelection: false,
            idField: 'assetId',
            textField: 'assetName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 0,
            allowSearchFilter: true
        };

        this.dropdownSettingsUser = {
            singleSelection: false,
            idField: 'userId',
            textField: 'firstName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 0,
            allowSearchFilter: true
        };
    }

    initilaiseCal(){
        if (this.reportId == 0) {
            setTimeout(function(){
                $('.daily-check-month').prop('checked', true);
                $('.year-ckeck-box').prop('checked', true);
                $('input[name=dateOfMonth]').val('1');
                $('#weekOfMonth').val(1);
                $('#weekOfMonth1').val(1);
                $('#weekDayOfMonth').val(2);
                $('#weekdayOfMonth-year').val(2);
                $('#monthOfYear').val(1);
                $('#monthOfYear-first').val(1);
                 $('.frequencyStep').val(1);
            }, 200);
        }
        
    }
    /* Get Site Management list */
    getSiteManagementList() {
        var URL = 'site/getSiteByClient?clientId=' + this.clientId + '&userId=' + this.userId;
        if (this.customerId != null && this.customerId != '') {
            var customerString = '&customerId=' + this.customerId.toString();
            var URL = URL.concat(customerString);
        }
        if (this.tierId != null && this.tierId != '') {
            var tierString = '&tierId=' + this.tierId.toString();
            var URL = URL.concat(tierString);
        }
        this.apiService.getAll(URL).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.siteList = data.responseList;
                } else {
                    this.siteList = [];
                }
            },
            error =>{
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    /* Get Customer list */
    getCustomerList() {
        var URL = 'customer/getCustomerByClient?clientId=' + this.clientId;
        if (this.customerId != null && this.customerId != '') {
            var customerString = '&customerId=' + this.customerId.toString();
            var URL = URL.concat(customerString);
        }
        if (this.tierId != null && this.tierId != '') {
            var tierString = '&tierId=' + this.tierId.toString();
            var URL = URL.concat(tierString);
        }
        this.apiService.getAll(URL).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.customerList = data.responseList;
                } else {
                    this.customerList = [];
                    /*this.alertService.sweetMessage('error',data.responseMessage);*/
                }
            },
            error =>{
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }


    getReportperiod(value) {
        this.reportperiod = value;
    }

    onItemSelectSite(item: any) {
        var exist = this.checkSiteArray.indexOf(item.siteId.toString());
        if (exist == '-1') {
            this.checkSiteArray.push(item.siteId.toString());
            this.siteIdForApi = this.checkSiteArray.join();
            //this.getUserListData();
        }
    }

    onItemDeselectSite(item: any) {

        var exist = this.checkSiteArray.indexOf(item.siteId.toString());
        if (exist != '-1') {
            this.checkSiteArray.splice(exist, 1);
            this.siteIdForApi = this.checkSiteArray.join();
            //this.getUserListData();
        }
    }

    onSelectAllSite(items: any) {
        this.checkSiteArray = [];
        this.siteIdForApi = this.checkSiteArray.join();

        Object.keys(items).forEach(key => {
            this.checkSiteArray.push(items[key]['siteId'].toString());
            this.siteIdForApi = this.checkSiteArray.join();
            //this.getUserListData();
        });
    }

    onDeSelectAllSite() {
        this.checkSiteArray = [];
        this.siteIdForApi = this.checkSiteArray.join();
        //this.getUserListData();
    }

    onItemSelectCustomer(item: any) {
        var exist = this.checkCustomerArray.indexOf(item.customerId.toString());
        if (exist == '-1') {
            this.checkCustomerArray.push(item.customerId.toString());
            this.customerIdForApi = this.checkCustomerArray.join();
            this.getUserListData();
        }

    }

    onItemDeselectCustomer(item: any) {
        var exist = this.checkCustomerArray.indexOf(item.customerId.toString());
        if (exist != '-1') {
            this.checkCustomerArray.splice(exist, 1);
            this.customerIdForApi = this.checkCustomerArray.join();
            //this.getUserListData();
        }

    }

    onSelectAllCustomer(items: any) {
        this.checkCustomerArray = [];
        this.customerIdForApi = this.checkCustomerArray.join();
        //this.getUserListData();

        Object.keys(items).forEach(key => {
            this.checkCustomerArray.push(items[key]['customerId'].toString());
            this.customerIdForApi = this.checkCustomerArray.join();
            //this.getUserListData();
        });
    }

    onDeSelectAllCustomer() {
        this.checkCustomerArray = [];
        this.customerIdForApi = this.checkCustomerArray.join();
        //this.getUserListData();
    }


    //******
    onItemSelectUser(item: any) {
        var exist = this.checkUserArray.indexOf(item.userId.toString());
        if (exist == '-1') {
            this.checkUserArray.push(item.userId.toString());
        }
        this.showTableInfo(item.userId);
    }

    onItemDeselectUser(item: any) {
        var exist = this.checkUserArray.indexOf(item.userId.toString());
        if (exist != '-1') {
            this.checkUserArray.splice(exist, 1);
            this.removeTableInfo(item.userId);
        }
    }

    onSelectAllUser(items: any) {
        this.checkUserArray = [];
        this.userListTableData = [];
        this.checkUserArray = _.map(items, 'userId');
        this.showTableInfo(this.checkUserArray);
    }

    onDeSelectAllUser(item: any) {
        this.checkUserArray = [];
        this.userListTableData = [];
    }

    // function to create tier
    createTierArray() {
        for (var tierId in this.checkTreeArray) {
            this.tiersArray.push({
                tierId: this.checkTreeArray[tierId],
            })
        }
    };

    // Function to add report
    addReportData(data) {
        if(data.invalid){
          this.alertService.sweetMessage('error','Please fill in all the required fields');
          return;
        }
        for (var siteId in this.checkSiteArray) {
           this.resultForSite.push({
              siteId: this.checkSiteArray[siteId],
            })
        }
        for (var userId in this.checkUserArray) {
            this.resultForUser.push({
               userId: this.checkUserArray[userId],
            })
        }
        for (var customerId in this.checkCustomerArray) {
            this.resultForCustomer.push({
              customerId: this.checkCustomerArray[customerId],
            })
        }

        for (var assetId in this.checkAssetArray) {
            this.resultForAsset.push({
              assetId: this.checkAssetArray[assetId],
            })
        }

        this.createTierArray();
        console.log('new',this.addFormData);

        this.reportData.users       = this.resultForUser;
        this.reportData.assets       = this.resultForAsset;
        this.reportData.customers   = this.resultForCustomer;
        this.reportData.sites       = this.resultForSite;
        this.reportData.tiers       = this.tiersArray;
        this.reportData.name        = this.addFormData.name;
        this.reportData.description = this.addFormData.description;
        this.reportData.isActive    = this.addFormData.isActive;
        this.reportData.reportTime    = $('#reportTime').val();

        if (this.radioSelected == 'OnDemand') {
            this.reportData.frequencyType = "OnDemand";

        } else if (this.radioSelected == 'Daily') {
            this.reportData.frequencyType = "Daily";
            this.reportData.frequencyStep = this.addFormData.frequencyStep;

        } else if (this.radioSelected == 'Weekly') {

            this.reportData.frequencyType = "Weekly";
            this.reportData.frequencyStep = this.addFormData.frequencyStep;

            if (this.recurringPattern.isSunday == true) {
                // this.weekdayMapData = this.weekdayMapData + 64;
                this.weekdayMapData = this.weekdayMapData + 1;
            }
            if (this.recurringPattern.isMonday == true) {
                // this.weekdayMapData = this.weekdayMapData + 1;
                this.weekdayMapData = this.weekdayMapData + 2;
            }
            if (this.recurringPattern.isTuesday == true) {
                // this.weekdayMapData = this.weekdayMapData + 2;
                this.weekdayMapData = this.weekdayMapData + 4;
            }
            if (this.recurringPattern.isWednesday == true) {
                // this.weekdayMapData = this.weekdayMapData + 4;
                this.weekdayMapData = this.weekdayMapData + 8;
            }
            if (this.recurringPattern.isThursday == true) {
                // this.weekdayMapData = this.weekdayMapData + 8;
                this.weekdayMapData = this.weekdayMapData + 16;
            }
            if (this.recurringPattern.isFriday == true) {
                // this.weekdayMapData = this.weekdayMapData + 16;
                this.weekdayMapData = this.weekdayMapData + 32;
            }
            if (this.recurringPattern.isSaturday == true) {
                // this.weekdayMapData = this.weekdayMapData + 32;
                this.weekdayMapData = this.weekdayMapData + 64;
            }

            this.reportData.weekdayMap = this.weekdayMapData;

        } else if (this.radioSelected == 'Monthly') {
            this.reportData.frequencyType = "Monthly";
            console.log('u', this.addFormData.weekOfMonth);           
            if (!this.addFormData.weekOfMonth) {
                console.log('in if');
                this.reportData.dateOfMonth = this.addFormData.dateOfMonth;
                this.reportData.frequencyStep = this.addFormData.frequencyStep;
            } else {
                console.log('in else');
                this.reportData.weekOfMonth = this.addFormData.weekOfMonth;
                this.reportData.weekdayOfMonth = this.addFormData.weekdayOfMonth;
                this.reportData.dateOfMonth = this.addFormData.dateOfMonth;
            }
        } else if (this.radioSelected == 'Yearly') {
            this.YearlyRadioSelected = $('.year-ckeck-box').val();    
            console.log(this.YearlyRadioSelected);
            this.reportData.frequencyType = "Yearly";
            if (!this.addFormData.weekOfMonth) {                
                this.reportData.monthOfYear = this.addFormData.monthOfYear;
                this.reportData.frequencyStep = this.addFormData.frequencyStep;
            } else {
                console.log('yearsec', this.addFormData.frequencyStep);
                this.reportData.weekdayOfMonth = this.addFormData.weekDayOfMonth;
                this.reportData.weekOfMonth = this.addFormData.weekOfMonth;
                this.reportData.monthOfYear = this.addFormData.monthOfYear;
            }

        }
        console.log('yearly', this.reportData);
        this.addReportDataList();
    }


  // Preview Report
    previewReport() {
        var URL = 'report/getPreviewReport?reportId=' + this.reportId;
        this.apiService.getAll(URL).subscribe(
          data => {
              if (data.statusCode == 100) {
                  this.preivewData = data.responseList;
              }
              else
                { this.alertService.sweetMessage('error',"No Data Found"); }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }


  // get tier List
    getTierList() {
        var URL = 'tier/getAllTierByUser?clientId=' + this.clientId + '&userId=' + this.userId;
        if (this.customerId != null && this.customerId != '') {
            var customerString = '&customerId=' + this.customerId.toString();
            var URL = URL.concat(customerString);
        }
        this.apiService.getAll(URL).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.data = data.responseList;
                    Object.keys(this.data).forEach(key => {
                        if (this.data[key]['flag'] == false) {
                            this.disableTreeArray.push(this.data[key]['tierId']);
                        }
                    });
                    this.source = {
                        datatype: 'json',
                        datafields: [{
                            name: 'tierId'
                        }, {
                            name: 'parentTierId'
                        }, {
                            name: 'tierName'
                        }],
                        id: 'tierId',
                        localdata: this.data
                    };
                    this.dataAdapter = new jqx.dataAdapter(this.source, {
                        autoBind: true
                    });
                    this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [{
                        name: 'tierName',
                        map: 'label'
                    }, {
                        name: 'tierId',
                        map: 'id'
                    }]);
                    var self = this;
                    setTimeout(function() {
                        self.treeItemDisable();
                    }, 3000);
                }
            },
            error =>{
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    // Function to disable items
    treeItemDisable() {
        Object.keys(this.disableTreeArray).forEach(key => {
            let checkedItem = document.getElementById(this.disableTreeArray[key]);
            this.myTree.expandItem(checkedItem);
            this.myTree.disableItem(checkedItem);
        });
    }

    /* Handle tier tree chacked unchecked Event */
    CheckChange(event) {
        if (event.args.checked == true) {
            
            var exist = this.checkTreeArray.indexOf(event.args.element.id);
            if (exist == '-1') {
                this.checkTreeArray.push(event.args.element.id);
                this.getUserListData();
                // localStorage.setItem("tierId", JSON.stringify(this.checkTreeArray));
            }
        } else if (event.args.checked == false) {
            var exist = this.checkTreeArray.indexOf(event.args.element.id);
            if (exist != '-1') {
                this.checkTreeArray.splice(exist, 1); 
                this.checkUserArray = [];
                this.selectedUser = [];
                this.userListTableData = [];
                this.userListDataForTable1 = [];               
                this.userListDataForTable = [];  
            } 
            this.getUserListData();
        }
       
    }

    // Getting User List Data
    getUserListData() {
        this.tierIdForApi = this.checkTreeArray.join();
        // var URL = 'public/user/getUserClientByClient?clientId=' + this.clientId + '&userId=' + this.userId
        var URL = 'public/user/getUserClientByClient?clientId=' + this.clientId 

        if (this.tierIdForApi != null && this.tierIdForApi != '') {
            var tierString = '&tierId=' + this.tierIdForApi.toString();
            var URL = URL.concat(tierString);
        }
        // if (this.customerIdForApi != null && this.customerIdForApi != '') {
        //     var customerString = '&customerId=' + this.customerIdForApi.toString();
        //     var URL = URL.concat(customerString);
        // }
        // if (this.siteIdForApi != null && this.siteIdForApi != '') {
        //     var siteString = '&siteId=' + this.siteIdForApi.toString();
        //     var URL = URL.concat(siteString);
        // }

        if(this.checkTreeArray != "")
        {
            this.apiService.getAll(URL).subscribe(
                data => {
                    if (data.statusCode == 100) {
                        this.afterEditData = JSON.parse(JSON.stringify(data.userList));
                        this.userListDataForTable1 = [];               
                        this.userListDataForTable = [];                    
                        this.userListDataForTable1 = data.userList;
                        this.userListDataForTable = this.userListDataForTable.concat(data.userList);                       
                    } else {
                        this.userListDataForTable1 = [];
                        this.userListDataForTable = [];
                    }
                },
               error =>{
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                 }
            );
        }
    }

    // Function to show table Info
    showTableInfo(data) {
        var opbj = JSON.parse(JSON.stringify(data));
        if(Array.isArray(opbj) && opbj.length == 1){
            var opbj = opbj[0];
        }
        if (opbj && Array.isArray(opbj) && opbj.length > 0) {
            for (var i = 0; i < opbj.length; i++) {
                this.userListTableData.push(this.userListDataForTable[i]);
            }
        }else{
            for(var i = 0; i < this.userListDataForTable.length; i++) {
                if(this.userListDataForTable[i]['userId'] === opbj) {
                    this.userListTableData.push(this.userListDataForTable[i]);
                }
            }
        }
    }

    // Function to remove table Info
    removeTableInfo(data) {
        this.userListTableData.splice(_.indexOf(this.userListTableData, _.find(this.userListTableData, function(item) {
            return item.userId === data;
        })), 1);
    }

    // Function to add Report Data
    addReportDataList() {
         if (this.reportId == 0) {
            this.apiService.create('report/createReport', this.reportData).subscribe(
                data => {
                    if (data.statusCode == 100) {
                        Swal({
                            position: 'top-end',
                            type: 'success',
                            title: 'Report Details Added Successfully',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.router.navigate(['/report']);
                    } else {
                        this.alertService.sweetMessage('error',data.responseMessage);
                    }
                },
                error =>{
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
            );
        } else {
            this.reportData.reportId = this.reportId;
            this.apiService.update('report/updateReport', this.reportData).subscribe(
                data => {
                    if (data.statusCode == 100) {
                        Swal({
                            position: 'top-end',
                            type: 'success',
                            title: 'Report Details Updated successfully',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.router.navigate(['/report']);
                    } else {
                        this.alertService.sweetMessage('error',data.responseMessage);
                    }
                },
                error =>{
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
            );
        }
    }

    // getting report by Id
    getReportById() {
        this.apiService.getAll('report/getReportById?reportId=' + this.reportId).subscribe(
            data => {
                    if (data.statusCode == 100) {
                        console.log( data.response);
                    this.addFormData = data.response;
                    this.user = data.response;
                    this.reportData.reportType = data.response.reportType;
                    this.radioSelected = data.response.frequencyType;
                    //this.checkItemCommunication();
                    if (data.response.frequencyType == 'Monthly') {
                        if (!data.response.weekdayOfMonth) {
                            this.addFormData.dateOfMonth = data.response.dateOfMonth;
                            this.addFormData.frequencyStep = data.response.frequencyStep;
                            setTimeout(function(){
                                $('#dateOfMonth-second').val('');
                                $('#monthly-check-first').prop('checked', true);                                
                            },500);
                        } else {
                            this.addFormData.weekOfMonth = data.response.weekOfMonth;
                            this.addFormData.weekdayOfMonth = data.response.weekdayOfMonth;
                            this.addFormData.dateOfMonth = data.response.dateOfMonth;
                            setTimeout(function(){
                                $('#dateOfMonth-first').val('');
                                $('#monthly-check-second').prop('checked', true);
                            },500);
                        }
                    }
                    if (data.response.frequencyType == 'Yearly') {
                        if (!data.response.weekOfMonth) {
                            this.addFormData.monthOfYear = data.response.monthOfYear;
                            this.addFormData.frequencyStep = data.response.frequencyStep;
                            setTimeout(function(){
                                $('#monthOfYear').val(1);
                                $('#year-check-first').prop('checked', true);                                
                            },500);
                        } else {
                            this.addFormData.weekdayOfMonth = data.response.weekdayOfMonth;
                            this.addFormData.weekOfMonth = data.response.weekOfMonth;
                            this.addFormData.monthOfYear = data.response.monthOfYear;
                            setTimeout(function(){
                                $('#monthOfYear-first').val(1);
                                $('#year-check-second').prop('checked', true);                                
                            },500);
                        }
                    }

                    this.checkDataForchecked = data.response.weekdayMap;
                   
                    if (this.checkDataForchecked >= 64) {
                        // this.checkDataForchecked = this.checkDataForchecked - 32;
                        this.checkDataForchecked = this.checkDataForchecked - 64;
                        this.recurringPattern.isSaturday = true;
                    }
                    if (this.checkDataForchecked >= 32) {
                        // this.checkDataForchecked = this.checkDataForchecked - 16;
                        this.checkDataForchecked = this.checkDataForchecked - 32;
                        this.recurringPattern.isFriday = true;
                    }
                    if (this.checkDataForchecked >= 16) {
                        // this.checkDataForchecked - 8;
                        this.recurringPattern.isThursday = true;
                        this.checkDataForchecked = this.checkDataForchecked - 16;
                    }
                    if (this.checkDataForchecked >= 8) {
                        // this.checkDataForchecked = this.checkDataForchecked - 4;
                        this.checkDataForchecked = this.checkDataForchecked - 8;
                        this.recurringPattern.isWednesday = true;
                    }
                    if (this.checkDataForchecked >= 4) {
                        // this.checkDataForchecked = this.checkDataForchecked - 2;
                        this.checkDataForchecked = this.checkDataForchecked - 4;
                        this.recurringPattern.isTuesday = true;
                    }
                    if (this.checkDataForchecked >= 2) {
                        // this.checkDataForchecked = this.checkDataForchecked - 1;
                        this.checkDataForchecked = this.checkDataForchecked - 2;
                        this.recurringPattern.isMonday = true;
                    }
                     if (this.checkDataForchecked >= 1) {
                        // this.checkDataForchecked = this.checkDataForchecked - 64;
                        this.checkDataForchecked = this.checkDataForchecked - 1;
                        this.recurringPattern.isSunday = true;
                    }

                    for (let object of this.addFormData.tiers) {
                        this.checkTreeArray.push(object.tierId.toString());
                    }

                    var self = this;
                      setTimeout(function(){
                         self.checkedCheckboxOnLoad();
                      },4000);

                    for (let object of this.addFormData.sites) {
                        this.selectedsite.push({
                            'siteId': object.siteId
                        });
                        this.checkSiteArray.push(object.siteId.toString());
                    }

                    for (let object of this.addFormData.assets) {
                        this.selectedasset.push({
                            'assetId': object.assetId
                        });
                        this.checkAssetArray.push(object.assetId.toString());
                    }

                    for (let object of this.addFormData.customers) {
                        this.selectedcustomer.push({
                            'customerId': object.customerId
                        });
                        this.checkCustomerArray.push(object.customerId.toString());
                    }
                    if(this.addFormData.users){
                    if(this.addFormData.users.length == 1){
                        this.userListDataForTable.push(this.addFormData.users);
                        this.userListDataForTable1.push({'userId': this.addFormData.users[0]['userId']});
                        this.checkUserArray.push(this.addFormData.users[0]['userId']);
                    }else{
                        for (let object of this.addFormData.users) {
                            this.userListDataForTable1.push({
                                'userId': object['userId']
                            });
                            this.checkUserArray.push(object['userId'].toString());
                            this.userListDataForTable.push(object);
                        }
                        }
                    }

                    this.showTableInfo(this.checkUserArray);
                    this.customerIdForApi = this.checkCustomerArray.join();
                    this.siteIdForApi = this.checkSiteArray.join();
                    this.selectedItems = this.selectedcustomer;
                    this.selectedSite = this.selectedsite;
                    this.selectedAsset = this.selectedasset;
                    this.selectedUser = this.userListDataForTable1;
                    this.getUserListData();

                    this.getSiteManagementList();
                    this.getCustomerList();
                    this.getTierList();
                    this.getAllAssetList();
                } else {
                    this.alertService.sweetMessage('error', data.responseMessage);
                }
            },
           error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );

    }

    // checked checkbox on load of tree
    checkedCheckboxOnLoad() {
        Object.keys(this.checkTreeArray).forEach(key => {
            let checkedItem = document.getElementById(this.checkTreeArray[key]);
            this.myTree.expandItem(checkedItem);
            this.myTree.checkItem(checkedItem, true);
        });
    }

    // Getting Asset List
    getAllAssetList() {
        var URL = 'customer/asset/getAssetDropDownByClient';
        let apiData: any = {
            clientId: this.clientId,
            userId: this.userId
        }
        if (this.customerId != null && this.customerId != '') {
            apiData.customerIds = [this.customerId];
            // var customerString = '&customerId=' + this.customerId.toString();
            // var URL = URL.concat(customerString);
        }
        if (this.tierId != null && this.tierId != '') {
            apiData.tierIds = this.tierId.toString();
            // var tierString = '&tierId=' + this.tierId.toString();
            // var URL = URL.concat(tierString);
        }
        // if (this.checkSiteArray.length != 0) {
        //     this.site = this.checkSiteArray.toString();
        //     var siteString = '&siteId=' + this.site;
        //     var URL = URL.concat(siteString);
        // }
        this.apiService.create(URL, apiData).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.assetDatalist = data.responseList;
                } else {
                    this.assetDatalist = [];
                }
                //this.selectedAsset = [];
            },
            error =>{
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    /* On Item Select Asset */
    onItemSelectAsset(item: any) {
        var exist = this.checkAssetArray.indexOf(item.assetId.toString());
        if (exist == '-1') {
            this.checkAssetArray.push(item.assetId.toString());
        }
    }

    /* On Item DeSelect Asset */
    onItemDeselectAsset(item: any) {
        var exist = this.checkAssetArray.indexOf(item.assetId.toString());
        if (exist != '-1') {
            this.checkAssetArray.splice(exist, 1);
        }
    }

    /* On All Select Asset */
    onSelectAllAsset(items: any) {
        this.checkAssetArray = [];
        Object.keys(items).forEach(key => {
            this.checkAssetArray.push(items[key]['assetId'].toString());
        });
    }

    /* On All DeSelect Site */
    onDeSelectAllAsset() {
        this.checkAssetArray = [];
    }

}
