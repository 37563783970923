import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService , AlertService, FormatDateService} from '../../../../services/index';
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';
import * as NProgress from 'nprogress';



@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

clientId: any = localStorage.getItem("clientId");
customerId: any = localStorage.getItem("customerId");
userId: any = localStorage.getItem("userId");
notificationList : any = []
dataTable: any;

constructor(private router:Router, private apiService: ApiService , private alertService: AlertService, private formatDateService: FormatDateService, private chRef: ChangeDetectorRef) { }


  ngOnInit() {
  //this.getNotificationList();
  }

  /* Get Notification list */
getNotificationList(){ 
   this.apiService.getAll('notification/getLatestLiveFeed?clientId='+this.clientId+'&userId='+this.userId).subscribe(
   data => {
       if(data.statusCode == 100){
          this.notificationList = data.responseList;          
          this.chRef.detectChanges();
          if ($.fn.DataTable.isDataTable('#notificationListTable')) {
              $('#notificationListTable').DataTable().destroy();
          };
          const table: any = $('#notificationListTable');
          this.dataTable = table.DataTable();          
        }else{
          this.notificationList = [];
          this.chRef.detectChanges();
          const table: any = $('#notificnotificationListTable');
          this.dataTable = table.DataTable();
          this.alertService.sweetMessage('error',data.responseMessage);          
         }
      },
     error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );
      }


}
