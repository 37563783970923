import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService ,AlertService} from '../../services/index';
import * as $ from 'jquery';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-user-activation',
  templateUrl: './user-activation.component.html',
  styleUrls: ['./user-activation.component.css']
})
export class UserActiveComponent implements OnInit {

token:any="";
accessToken:any={"accessToken":""}
msg:any="";
loginPageContentData:any;
imageFile:any;

constructor(private router:Router, private route: ActivatedRoute, private apiService: ApiService, private alertService: AlertService) { }

  	ngOnInit() {
  		// Getting Login Data
  		// this.getLoginData();
		$(document).ready(function () { 
			// CSS
		    $("body").css({"padding-top": "0px"});
	    });
	    this.route.params.subscribe(params => {
		    if(params['id']){
		      this.token = params['id'];
		      this.resetPassword();
		    }      
	  	});
	}

	// Reset Password
	resetPassword(){
		if(!this.token){
			this.alertService.sweetMessage('error',"Invalid token.");			
		}else{
			this.accessToken = {"accessToken":this.token};
			this.apiService.userActivate('customer/user/userActivation', this.accessToken).subscribe(
				data => {
					if(data.statusCode == 100 || data.statusCode == 103){
						this.msg = data.responseMessage
						// this.alertService.sweetMessage('error',data.responseMessage);
					}else{
						this.alertService.sweetMessage('error',data.responseMessage);					
					}
				},
				error => {
					this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
				}
			);
		}
   	}

   	// Getting Login Data
 	getLoginData(){
	 	// var url = this.apiService.appUrl;
    	// this.apiService.getLoginDatawithoutToken('client/getClientByDomain?domain='+url).subscribe(
		//     data => {
		// 		if(data.statusCode == 100){
		// 		      	 this.loginPageContentData=data.response.loginPageContent;
	    //   				 this.imageFile = data.response.logoFile;
		// 						 document.getElementById('imageData').setAttribute( 'src', this.apiService.imageBasePath+this.imageFile );	
		// 		}
	    //   	},
	    //   	error => {
	    //   		this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
	    //   	}
    	// );
  	}
}
