import { Component, OnInit,ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
import * as $ from 'jquery'
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'app-asset-edit',
  templateUrl: './asset-edit.component.html',
  styleUrls: ['./asset-edit.component.css']
})
export class AssetEditComponent implements OnInit {
  // Initializing Data
  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  assetId :any = 0;
  // permissionData:any=[];

  assetData: any = {
    assetId:"",
    name:"",
    criticalThresHold:"",
    shiftGroup:{},
    notificationPlan:{},
    site:{},
    customer:{}
  };

  customerListData: any = [];
  notificationListData: any = [];
  shiftGroupListData: any = [];
  siteListData: any = [];
  assetDataById:any={};
  siteLat:number = 39.381266;
  siteLong:number = -97.922211;

  isShowCalendar : any = false;
  isShowCritical : any = false;


  @Output() getAssetEvent = new EventEmitter<String>();

  constructor(private router:Router,private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService) {
    // this.permissionData = ApiService.permission;
  }

  // Format Date
  DateFormat(startDate){
    if(startDate){
        return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
    }else{
        return "";
    }
  }

  ngOnInit() {
   this.route.params.subscribe(params => {
      if(params['id']){
        this.assetId = params['id'];
      }
      if(this.assetId != 0){


      }
    });
  }

EditAsset(){
  this.getAssetDataById();
  this.getAllNotificationList();
  this.getAllCustomerList();
  this.getAllShiftList();
  this.getAllSiteList();
}

/* Get Asset Data by id for edit Asset Screen */
  getAssetDataById() {
      var URL = 'asset/getAssetById?assetId='+this.assetId;
      this.apiService.getAll(URL).subscribe(
          data => {
              if (data.statusCode == 100) {

                if(data.response.site.isRuntime == true) {
                  this.isShowCritical = true
                }

                  this.assetDataById = data.response;
                  this.assetData.assetId = this.assetId;
                  this.assetData.name = this.assetDataById.assetName;
                  this.assetData.criticalThresHold = this.assetDataById.criticalThresHold;
                  this.assetData.shiftGroup.shiftGroupId = (this.assetDataById.shiftGroup?this.assetDataById.shiftGroup.shiftGroupId:'')
                  this.assetData.notificationPlan.notificationPlanId = (this.assetDataById.notificationPlan?this.assetDataById.notificationPlan.notificationPlanId:'')
                  this.assetData.site.siteId = (this.assetDataById.site?this.assetDataById.site.siteId:'')
                  this.assetData.customer.customerId = (this.assetDataById.customer?this.assetDataById.customer.customerId:'')
              } else {
                  this.assetDataById = {};
                  /* this.alertService.sweetMessage('error',data.responseMessage); */
              }
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
      );
  }
 // Getting all Customers
    getAllCustomerList() {
        var URL = 'customer/getCustomerDropDownByClient?clientId=' + this.clientId + '&userId=' + this.userId+ '&alphabetSortingOrder=1'
        this.apiService.getAll(URL).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.customerListData = data.responseList;
                } else {
                    this.customerListData = [];
                    /* this.alertService.sweetMessage('error',data.responseMessage); */
                }
            },
            error =>{
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    // Getting All Notifications
    getAllNotificationList() {
        var URL = 'notification_plan/getNoficationPlanDropDownListByClient?clientId=' + this.clientId + '&userId=' + this.userId+ '&alphabetSortingOrder=1'
        this.apiService.getAll(URL).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.notificationListData = data.responseList;
                } else {
                    this.notificationListData = [];
                    /* this.alertService.sweetMessage('error',data.responseMessage); */
                }
            },
           error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    // Getting shift List
    getAllShiftList() {
        var URL = 'shiftGroup/getShiftGroupDropDownByClient?clientId=' + this.clientId + '&userId=' + this.userId
        this.apiService.getAll(URL).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.shiftGroupListData = data.responseList;
                } else {
                    this.shiftGroupListData = [];
                }
            },
            error =>{
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    // Getting Site List
    getAllSiteList() {
        var URL = 'site/getSiteDropDownListByClient?clientId=' + this.clientId + '&userId=' + this.userId+ '&alphabetSortingOrder=1'
        this.apiService.getAll(URL).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.siteListData = data.responseList;
                } else {
                    this.siteListData = [];
                }
            },
            error =>{
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

  // Update Assets
    updateAssets(){
        this.apiService.update('asset/updateAssetForDropDown', this.assetData).subscribe(
            data => {
                if(data.statusCode == 100){
                  this.getAssetEvent.emit();

                    this.alertService.sweetMessage('success','Asset Updated successfully');
                }else{
                    this.alertService.sweetMessage('error',data.responseMessage);
                }
            },
            error =>{
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }


  // check if call schedule value is 2 then show calender DropDown
  isRuntime (event) {

    let isRuntimeValue = event.target.selectedOptions[0].id;

    if(isRuntimeValue == "true") {
      this.isShowCritical = true;
    }
    else {
      this.isShowCritical = false;
    }
  }

  // check if call schedule value is 2 then show calender DropDown
  isCallSchedule (event) {

    let callScheduleValue = event.target.selectedOptions[0].id;

    if(callScheduleValue == 2) {
      this.isShowCalendar = true;
    }
    else {
      this.isShowCalendar = false;
    }
  }


}
