import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService, AlertService } from '../../services/index';
import * as $ from 'jquery';
import Swal from 'sweetalert2'
import { UUID } from 'angular2-uuid';
import { CookieService } from 'ngx-cookie-service';
declare var moment:any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',  
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public href: string = "";
  constructor(private router:Router,private route: ActivatedRoute, private apiService: ApiService,private alertService:AlertService, private cookieService:CookieService) { }
  uuid = UUID.UUID();
  errorMsg:any;
  clientCode: any = '';
  customerCode: any = '';	
  resMessage:any = '';
  rememberMe:any;
  user: any = {"login": "","password": "","agent":"!@#QWERTY"};
  userForm: any = {"agent":this.uuid,"agentType":"WEB","grant_type" : "password","client_id":"my-trusted-client"};
	ngOnInit() {
		// localStorage.clear();
		$(document).ready(function () { 
		   $("body").addClass('login')//.css({"padding-top": "0px"});
		});
		
		let username = window.atob(this.cookieService.get('#1bcd3cu21w'))
		let pass = window.atob(this.cookieService.get('zxcvcu'))
		let islogin = this.cookieService.get('isLogin')
		
		this.userForm.username = username
		this.userForm.password = pass
		if (this.userForm.password && this.userForm.username) {
			this.rememberMe = true
		}
		else {
			
		}
		if (this.userForm.password && this.userForm.username && islogin) {
			// this.login();
		}
		this.cookieService.set('#1bcd3cu21w',window.btoa(this.userForm.username) );
		this.cookieService.set('zxcvcu',window.btoa(this.userForm.password));

	    this.href = this.router.url;
	    if(this.href != '/login'){
	    	this.clientCode = this.route.snapshot.queryParamMap.get("clientCode");
	    	this.customerCode = this.route.snapshot.queryParamMap.get("customerCode");
	    	localStorage.setItem("clientCode", this.clientCode);
			localStorage.setItem("customerCode", this.customerCode);
			localStorage.setItem("viaLink", 'yes');
			this.getCustomerDomain();
		}else{

			let Token = localStorage.getItem("Token");

			let customerData = localStorage.getItem("customerData");
			let userId = localStorage.getItem("userId");
			let customerId = localStorage.getItem("customerId");

			if (Token && customerData && userId && customerId) {
				this.router.navigate(['/dashboard']);
				return;
			}
		}
		
		let errorMessage = localStorage.getItem('logoutMessage')
		if (errorMessage) {
			Swal({
				position: 'top-end',
				type: 'error',
				title: errorMessage,
				showConfirmButton: false,
				timer: 5000
			  })
		}

		setTimeout(() => {
			localStorage.removeItem('logoutMessage')
		}, 5000);


	}

// 	login(){
// 		//var agent = window.navigator.userAgent;
// 		//this.user.agent = agent;
// 		//console.log(this.user);
//        this.apiService.login('customer/user/userLogin', this.user).subscribe(
// 			data => {
// 				if(data.statusCode == 100){
// 					localStorage.setItem("customerData", JSON.stringify(data.response));
// 					localStorage.setItem("customerId", data.response.customerId);
// 					localStorage.setItem("userId", data.response.userId);
// 					localStorage.setItem("clientId", data.response.clientId);

// 					Swal({
// 					  position: 'top-end',
// 					  type: 'success',
// 					  title: 'Login Successful',
// 					  showConfirmButton: false,
// 					  timer: 1500
// 					})
// 					this.router.navigate(['/dashboard']);
// 				}else{
// 					Swal({
// 		              position: 'top-end',
// 		              type: 'error',
// 		              title: data.responseMessage,
// 		              showConfirmButton: false,
// 		              timer: 1500
// 		            })					
// 				}
// 			},
// 			error => {
// 				            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
// 			}
// 		);
//    }

login(){
	this.resMessage = null;
	localStorage.removeItem('logoutMessage')
	// this.userForm.password = window.btoa(this.userForm.password);	
	let Obj = {
		"agentType":this.userForm.agentType,
		"agent":this.userForm.agent,
		"grant_type":this.userForm.grant_type,
		"client_id":this.userForm.client_id,
		"username":this.userForm.username.trim(),
		"password":window.btoa(this.userForm.password),
	}

	this.apiService.login('userauth/oauth/login', Obj).subscribe(
		 (data:any) => {
			 if(data.status == 200){
				 localStorage.setItem("Token", data["_body"]);
				//  this.cookieService.set("refresh_token",data.refresh_token)
				 let dataObj = JSON.parse(data._body) //data["_body"]
				 // this.cookieService.set("refresh_token",dataObj.refresh_token)
				 document.cookie = "refresh_token="+dataObj.refresh_token+"; expires=" + new Date(moment().add(30, 'days')).toUTCString();
				 let resData = JSON.parse(data["_body"])
				//  console.log(resData);
				 
				 if (resData.statusCode == 109) {
					 
					 this.resMessage = resData.responseMessage
				}else{
					this.getUserInfo();
				}
			 }else{
				
			 }
		 },
		 error => {
	 if(error.error_description){
	   this.errorMsg = error.error_description
	 }else{
	   this.errorMsg = "Invalid Credentials"
	 }
	 this.alertService.errorMessage(error, error.status, this.errorMsg ? this.errorMsg : "Something went wrong, please try again ");
		 }
	 );
 }

 getCustomerDomain(){
	  this.apiService.getAll('customer/getCustomerDomain?clientCode='+this.clientCode+'&customerCode='+this.customerCode).subscribe(
			 data => {
			 if(data.statusCode == 100){
			 	setTimeout(function(){			 		
			 		$('.loginContent').html(data.response.loginPageContent);
			 	}, 500);	
			 }
		   },
		   error => {
			this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
		   }
	 );
   }

getUserInfo(){
		

	  this.apiService.getAll('customer/user/getUserInfo').subscribe(
			 data => {
			 if(data.statusCode == 100){
				 
			 	localStorage.setItem("clientId",JSON.stringify(data.response.clientId));
			 	localStorage.setItem("permission",JSON.stringify(data.response.permissions));
				localStorage.setItem("customerData", JSON.stringify(data.response));
				localStorage.setItem("customerId", data.response.customerId);
				localStorage.setItem("userId", data.response.userId);
				localStorage.setItem("clientId", data.response.clientId);
				this.router.navigate(['/dashboard']);
				//  document.cookie = "islogin=;"//+data.refresh_token
				//  document.cookie = "islogin="+true
				console.log(this.rememberMe);
				
				if (this.rememberMe) {
					this.cookieService.set('#1bcd3cu21w',window.btoa(this.userForm.username.trim()) );
					this.cookieService.set('zxcvcu',window.btoa(this.userForm.password));
					document.cookie = "isLogin=;"//+data.refresh_token
					// document.cookie = "isLogin="+true
					document.cookie = "isLogin=true; expires=" + new Date(moment().add(600, 'days')).toUTCString();
				}else{
					this.cookieService.delete('#1bcd3cu21w');
					this.cookieService.delete('zxcvcu');
					this.cookieService.delete('isLogin');
					
				}

				//  Swal({
				//    position: 'top-end',
				//    type: 'success',
				//    title: 'Login Successfully',
				//    showConfirmButton: false,
				//    timer: 1500
				//  })
			 }else if(data.statusCode == 105){
				var msg = data.responseMessage;
				this.logout(msg); 
				this.resMessage = data.responseMessage
			}else{
				this.resMessage = data.responseMessage
			 }
		   },
		   error => {
			this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
		   }
	 );
   }
	
   logout(msg) {   	
	this.apiService.logout('userauth/oauth/revokeToken').subscribe(
		data => {
		   if(data.status == 200) {
			Swal({
				position: 'top-end',
				type: 'error',
				title: msg,
				showConfirmButton: false,
				timer: 1500
			  })
			localStorage.clear();


			} else {
			}
		},
		error => {
			//            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
		}
		);
}

}
