import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, FormatDateService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
/*import * as $ from 'jquery'
declare var d3:any,moment:any;;*/
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'app-asset-user',
  templateUrl: './asset-user.component.html',
  styleUrls: ['./asset-user.component.css']
})
export class AssetUserComponent implements OnInit {
  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  assetId : any = 0;
  userassetList:any=[];
  roleList:any=[];
  UserList:any=[];

  assetsumaryData:any =[];
  UserToAsset:any = {
    "createdTime":"",
    "modifiedTime":"",
    "creator":"",
    "lastModifier":"",
    "user":{
      "userId":""
    },
    "asset":{
      "assetId":''
    },
    "assetRole":{
      "assetRoleId":""

    }
  }
  siteLat:number = 39.381266;
  siteLong:number = -97.922211;
  clientCode : any = '';
  breadcrumbObj: any;
  // permissionData:any=[];

  constructor(
    private router:Router,
    private route: ActivatedRoute,
    private apiService: ApiService ,
    private alertService: AlertService,
    private formatDateService: FormatDateService,
    private chRef: ChangeDetectorRef
    ) {
    // this.permissionData = ApiService.permission;
  }

  // Function to format Date
  DateFormat(startDate, gmtOffset){
    if (startDate) {
      var formattedDate = moment(startDate,"YYYY-MM-DD HH:mm:ss:SSS").format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    } else {
      return '-'
    }
  }

  ngOnInit(){
    this.clientCode = this.alertService.getClientCode();
    this.route.params.subscribe(params => {
      if(params['id']){
        this.assetId = params['id'];
      }
    });
    this.route
      .queryParams
      .subscribe(params => {
          this.breadcrumbObj = params['source'];
      })
    if(this.assetId){
      this.getUserByAsset();
      this.getAssetSummaryData();
    }
    //this.getRoleList();
    //this.getUserList();
  }

  // Function to set latitude and longitude
  setLatLong(lat,long){
    this.siteLat = lat;
    this.siteLong = long;
  }

  // Listen For event Recive from child
    reciveAsset(){
      this.getAssetSummaryData();
    }


  /* Function for getting Asset Summary Data */
  getAssetSummaryData(){
    this.apiService.getAll('customer/asset/getCustomerAssetSummaryById?assetId='+this.assetId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.assetsumaryData= data.response;
        }else{
          this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  /* Get User by Asset list */
  getUserByAsset(){
    // this.apiService.getAll('customer/user/getUsersByAsset?assetId='+this.assetId).subscribe(
      this.apiService.getAll('customer/user/getUserByAsset?assetId='+this.assetId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.userassetList = data.userList;
        }else{
          this.userassetList = [];
          /*this.alertService.sweetMessage('error',data.responseMessage); */
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  /* Get role list */
  getRoleList(){
    this.apiService.getAll('customer/assetRole/getAllAssetRole').subscribe(
      data => {
        if(data.statusCode == 100){
          this.roleList = data.responseList;
        }else{
          this.roleList =[];

          this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  /* Get User list */
  getUserList(){
    // this.apiService.getAll('public/user/getUserByTier?clientId='+this.clientId+'&assetId='+this.assetId+'&isActive=true').subscribe(
    this.apiService.getAll('user/getUserByTier?clientId='+this.clientId+'&assetId='+this.assetId+'&isAssetTeam=true').subscribe(
      data => {
        if(data.statusCode == 100){
          this.UserList = data.userList;
        }else{
          this.UserList =[];
          this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  /* Add User by Asset  */
  AddUserByAsset(){
    this.UserToAsset.asset.assetId = this.assetId;
    this.apiService.create('assetUser/addUserToAsset', this.UserToAsset).subscribe(
      data => {
        if(data.statusCode == 100){

           this.alertService.sweetMessage('success','Asset User Added Successfully');
          this.getUserByAsset();
          this.UserToAsset = {"createdTime":"","modifiedTime":"","creator":"","lastModifier":"","user":{"userId":""},"asset":{"assetId":''},"assetRole":{"assetRoleId":""}};
        }else{
          this.alertService.sweetMessage('error',data.responseMessage);
          this.UserToAsset = {"createdTime":"","modifiedTime":"","creator":"","lastModifier":"","user":{"userId":""},"asset":{"assetId":''},"assetRole":{"assetRoleId":""}};
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  /* Delete User */
  DeleteUser(assetdata){
    Swal({
      title: 'Are you sure?',
      text: "You want to delete this Asset User.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.apiService.delete('assetUser/deleteAssetUser?assetId='+this.assetId+'&userId='+assetdata.userId,'').subscribe(
          data => {
            if(data.statusCode == 100){

              this.alertService.sweetMessage('success','Asset User Deleted Successfully');
              this.getUserByAsset();
            }else{
              this.alertService.sweetMessage('error',data.responseMessage);
            }
          },
         error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
          );
      }
    })
  }

}
