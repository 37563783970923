import { Component, OnInit } from '@angular/core';
import { ApiService , AlertService}  from '../../../../../services/index';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css']
})




export class TemplateComponent implements OnInit {

  clientCode : any = '';
  templateList:any = [];
  permissionData:any = {}
  constructor(private apiService: ApiService , private alertService: AlertService) {
    this.permissionData = localStorage.getItem('permission') ? JSON.parse(localStorage.getItem('permission')) : {}
  }


ngOnInit() {
  this.clientCode = this.alertService.getClientCode();
  this.getTemplateList();
  }


  getTemplateList(){
    
    this.apiService.getAll('template/getAllTemplateList').subscribe(
      data => {
        if(data.statusCode == 100){
          this.templateList = data.responseList ? data.responseList : [];
    
        }else{
          this.templateList = [];
    
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }
}
