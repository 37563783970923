import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, AlertService } from '../../../../../services/index';
import { jqxTreeComponent } from '../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../jqwidgets-ts/angular_jqxdropdownbutton'
import { FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Location } from '@angular/common';
import Swal from 'sweetalert2'
import * as $ from 'jquery'
declare var d3: any, moment: any;
import * as _ from "lodash";
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  @ViewChild('myTree') myTree: jqxTreeComponent;
  @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;
  @ViewChild('f') form: any; 
  @ViewChild('imageUrlName') imageUrlName: ElementRef;
  clientId: any = localStorage.getItem("clientId");
  customerId: any = (localStorage.getItem("customerId") ? JSON.parse(localStorage.getItem("customerId")) : []);
  userId: any = localStorage.getItem("userId");
  customerData: any = JSON.parse(localStorage.getItem("customerData"));
  // tiersId: any = 1

  isCusDisable: any = false;
  isTreeDisable: any = false;

  commMode: any; //commMode:number=0;
  newUserId: any;

  imageUrl = "assets/img/upload-img.png";
  imageName: any = {};
  imgBasePath: any = "";
  imageError = "";
  imageTitle = "";


  data: any = [];
  disableTreeArray: any = [];
  dataAdapter: any = [];
  checkTreeArray: any = [];
  checkCustomerArray: any = [];
  phoneCarrierList: any = [];
  roleList: any = [];
  roles: any = [];
  roleIds: any = [];
  records: any = [];
  selectedItems: any = [];
  tierList: any = [];
  tierListData: any = [];

  source: any = {};
  user: any = {}


  comm: any = { "Email": false, "SMS": false, "IVRS": false, "Call": false };
  clientCode: any = '';
  permissionData: any = [];
  FormObject: any = new FormData();
  showError = false;
  passwordError : any = 'Password is required';

  PassInfo   : any = `    
    1) Password must be at least 8 characters.<br>
    2) Password must contain at least 1 numeric character.<br>
    3) Password must contain at least 1 uppercase character.<br>
    4) Password must contain at least 1 special character !@#/\$%^&*().<br>
    5) Password cannot contain spaces.<br>`;

  PassInfoToolTip  : any = ` 1. Password must be at least 8 characters.
    2. Password must contain at least 1 numeric character.
    3. Password must contain at least 1 uppercase character.
    4. Password must contain at least 1 special character !@#/\$%^&*().
    5. Password cannot contain spaces.`;

  constructor(
    private router  : Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private alertService: AlertService,
    private location: Location
  ) { }

  ngOnInit() {
    //hardcore value 
    this.user.phoneCarrier = "Verizon"
    this.user.secondaryPhoneCarrier = "Verizon"
    this.getPhoneCarrierList();
    // this.getRoleList();
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.newUserId = params['id'];
      }
    });
    if (this.newUserId) {
      this.getUserById();
    }
  }

  getUserById() {
    this.apiService.getAll('/customer/user/getUserById?userId=' + this.newUserId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.user = data.user;
          if (this.user.imageLocation != null) {
            this.imageUrl = this.apiService.imageBasePath + this.user.imageLocation;
            let imageArr = this.user.imageLocation.split('/');
            this.imageTitle = imageArr[1];
          }
          this.roleList = data.user.roles
          this.getTierList();
          this.checkItemCommunication();
          
          this.checkTreeArray = [];
          Object.keys(this.user.tiers).forEach(key => {
            this.checkTreeArray.push(this.user.tiers[key]['tierId'].toString());
          });
          var that = this;
          this.roles = data.user.roles;

          this.roleIds = _.map(this.user.roles, 'roleId');
          var self = this;
          _.map(this.user.roleIds, function (value, key) {
            self.user.roleIds.push({ roleId: value.id });
          });
        } else {
          // this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );

  }

  /*********************
   Image Uploading
  *********************/
  onFileChanged(event) {
    const file = event.target.files;
    const fileType = file[0]['type'];
    const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
    this.imageError = "";
    // invalid file type code goes here.
    if (!validImageTypes.includes(fileType)) {
      this.imageUrl = "assets/img/upload-img.png";
      this.imageName = null;
      this.imageTitle = "";
      this.imageError = "Please select valid image type such as jpg, jpeg, png.";
      return;
    }

    if (this.imageError == "") {
      var reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (_event: any) => {
        var img = new Image();
        img.src = _event.target.result;
        img.onload = () => {
          // this.imageUrl = reader.result as string;
          if (img.width <= 300 && img.height <= 300) {
            this.imageUrl = reader.result as string;
            this.imageName = file;
            this.imageTitle = file[0]['name'];
          } else {
            //   this.alertService.sweetMessage('error','Image should not be greater than 300 X 300');
            this.imageUrl = "assets/img/upload-img.png";
            this.imageName = null;
            this.imageTitle = "";
            this.imageError = "Image Size should be less than 300X300";
            return;
          }
        };
      }
    }
  }

  /* Get tier list */
  getTierList() {
    this.apiService.getAll('/customer/user/getAllTierByUser?clientId=' + this.clientId + '&userId=' + this.userId).subscribe(
      data => {
        setTimeout(() => {
          if (data.statusCode == 100) {
            this.tierListData = data.userList;
            this.data = this.tierListData;
            Object.keys(this.data).forEach(key => {
              this.disableTreeArray.push(this.data[key]['tierId']);
            });
            this.source = {
              datatype: 'json',
              datafields: [
                { name: 'tierId' },
                { name: 'parentTierId' },
                { name: 'tierName' },
              ],
              id: 'tierId',
              localdata: this.data
            };
            this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
            this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [{ name: 'tierName', map: 'label' }, { name: 'tierId', map: 'id' }]);
            var self = this;
            setTimeout(function () {
              self.checkedCheckboxOnLoad();
              self.treeItemDisable();
            }, 1000);

          } else {
            /*this.alertService.sweetMessage('error',data.responseMessage); */
          }
        }, 2000);
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  /* check tree checkbox */
  checkedCheckboxOnLoad() {
    Object.keys(this.checkTreeArray).forEach(key => {
      let checkedItem = document.getElementById(this.checkTreeArray[key]);
      this.myTree.expandItem(checkedItem);
      this.myTree.checkItem(checkedItem, true);
    });
  }

  treeItemDisable() {
    Object.keys(this.disableTreeArray).forEach(key => {
      let checkedItem = document.getElementById(this.disableTreeArray[key]);
      this.myTree.expandItem(checkedItem);
      // If permission is not there for updating tier than disable the tier item       
      this.myTree.disableItem(checkedItem);

    });
  }

  checkItemCommunication() {
    this.commMode = this.user.communicationMode;
    var number = this.user.communicationMode;
    if ((number - 8) >= 0) {
      number = number - 8;
      this.comm.Call = true;
    }
    if ((number - 4) >= 0) {
      number = number - 4;
      this.comm.IVRS = true;
    }
    if ((number - 2) >= 0) {
      number = number - 2;
      this.comm.SMS = true;
    }
    if ((number - 1) >= 0) {
      number = number - 1;
      this.comm.Email = true;
    }

  }

  getPhoneCarrierList() {
    this.apiService.getAll('/customer/user/getPhoneCarrierList').subscribe(
      data => {
        if (data.statusCode == 100) {
          this.phoneCarrierList = data.userList;
        } else {
          this.phoneCarrierList = [];
          /*this.alertService.sweetMessage('error',data.responseMessage); */
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  /* Create communication logic */
  getCommMode(e) {
    if (e.target.name == 'emailId') {
      if (e.target.checked) {
        this.commMode = this.commMode + 1;
      } else {
        this.commMode = this.commMode - 1;
      }
    } else if (e.target.name == 'SMS') {
      if (e.target.checked) {
        this.commMode = this.commMode + 2;
      } else {
        this.commMode = this.commMode - 2;
      }
    } else if (e.target.name == 'IVRS') {
      if (e.target.checked) {
        this.commMode = this.commMode + 4;
      } else {
        this.commMode = this.commMode - 4;
      }
    } else if (e.target.name == 'Call') {
      if (e.target.checked) {
        this.commMode = this.commMode + 8;
      } else {
        this.commMode = this.commMode - 8;
      }
    }
    this.user.communicationMode = this.commMode;
    this.FormObject.append("communicationMode", this.commMode);
  }

  getRoleList() {
    // var URL = 'role/getRoleByUser?clientId=' + this.clientId;
    // if (this.customerId != null && this.customerId != '') {
    //   var customerString = '&customerId=' + this.customerId.toString();
    //   var URL = URL.concat(customerString);
    // }
    // if (this.tiersId != null && this.tiersId != '') {
    //   var tierString = '&tierId=' + this.tiersId.toString();
    //   var URL = URL.concat(tierString);
    // }
    // "customer/user/getRoleByUser?clientId=" + this.clientId  // <<--old url
    let URL = "customer/user/getRoleListByUserProfile"
    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.roleList = data.userList;
        } else {
          this.roleList = [];
          /*this.alertService.sweetMessage('error',data.responseMessage); */
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  /* Create role array */
  updateRoleOptions(rlist, event) {
    if (event.target.checked == true) {
      this.roles.push({ "roleId": rlist.roleId });
    } else {
      for (var i = 0; i < this.roles.length; i++) {
        if (this.roles[i].roleId == rlist.roleId) {
          this.roles.splice(i, 1);
        }
      }
    }
    this.user.roles = this.roles;
    this.FormObject.append("roles", JSON.stringify(this.roles));
  }

  /* Handle tier tree chacked unchecked Event */
  CheckChange(event){
    // if(event.args.checked == true){
    //   if($('input[name="isGlobalUser"]:checked').length == 0)
    //   {
    //       var exist1 = this.holdcheckTreeArray.indexOf(event.args.element.id);
    //       if(exist1== '-1'){
    //         this.holdcheckTreeArray.push(event.args.element.id);
    //       }
    //   }

    //   var exist = this.checkTreeArray.indexOf(event.args.element.id);
    //   if(exist == '-1'){
    //     this.checkTreeArray.push(event.args.element.id);
    //   }
    // }else if(event.args.checked == false){
    //   var exist = this.checkTreeArray.indexOf(event.args.element.id);
    //   if(exist != '-1'){
    //     this.checkTreeArray.splice(exist,1);
    //   }

    //   if(this.holdFlag != 0)
    //   {
    //       var exist1 = this.holdcheckTreeArray.indexOf(event.args.element.id);
    //       if(exist1 != '-1'){
    //         this.holdcheckTreeArray.splice(exist1,1);
    //       }
    //   }
    // }
  }

  resetUpdatePassword(){
    this.passwordError = "";
    $("#Uppasserror").css("display", "none");
    $("#oldpass").val("");
    $("#newpass").val("");
    $("#confirmpass").val("");
  }

  clearError() {
    $("#Uppasserror").css("display", "none");
  }

  updatePassword () {
    // var regx = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    // var regx = new RegExp("^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    var regx = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$");
    var RegExpConsicutive = new RegExp("(?=.*[A-Za-z])(?=.{2,})");
    var regx1 = /\s/;

    if(regx.test(this.user.newPassword) == false) {
      this.passwordError = this.PassInfo
        $("#Uppasserror").css("display", "block");
        $("#newpass").focus();
        return;
    }
    if(regx1.test(this.user.newPassword) == true){
      this.passwordError = "No space allowed";
        $("#Uppasserror").css("display","block");
        $('#confirmpass').focus();
       return;
    }
    //Match New password to Confirm Password
    if(this.user.confirmPassword != this.user.newPassword){
      this.passwordError = "New Password and Confirm Password Should be same";
      $("#Uppasserror").css("display","block");
      $('#confirmpass').focus();
     return;
    }
    
    //Match newPassword and UserId
    if(this.user.newPassword == this.customerData.login){
      this.passwordError = "User ID and Password Should Not be same";
      $("#Uppasserror").css("display","block");
      $('#confirmpass').focus();
      return;
    }
  // Check if password contain 3 identical consecutive characters
        // if( RegExpConsicutive.test(this.user.newPassword) == false ) {
        //   this.passwordError = "Password cannot contain 3 identical consecutive characters";
        //   $("#Uppasserror").css("display", "block");
        //   $("#newpass").focus();
        //   return;
        // }

    /* ====password with encryption==== */
    var obj = {
      "userId": this.newUserId,
      "password": window.btoa(this.user.OldNewpassword),
      "newPassword": window.btoa(this.user.newPassword),
      // "confirmPassword":this.user.confirmPassword
      }

    // var obj = {
    //   "userId": this.newUserId,
    //   "password": this.user.OldNewpassword,
    //   "newPassword": this.user.newPassword,
    //   // "confirmPassword":this.user.confirmPassword
    //   }

    this.apiService.update('customer/user/updatePassword',obj).subscribe(
    data => {
      if(data.statusCode == 100){
       this.alertService.sweetMessage('success','Password Updated Successfully');
       this.user.OldNewpassword = '';
       this.user.newPassword    = '';
       this.user.confirmPassword = ''
       $('#closeModal').click();
      }
      else
      {
        if(data.responseMessage == 'Invalid Password can not contain 3 Indentical Consecutive Character') {
          this.passwordError = "Password cannot contain 3 identical consecutive characters";
          $("#Uppasserror").css("display", "block");
          $("#newpass").focus();
          return;
        }
        else {
          this.alertService.sweetMessage('error',data.responseMessage);
          this.user.OldNewpassword = '';
          this.user.confirmPassword = ''
          this.user.newPassword = '';
        }
      }
    },
    error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    }
    );
  }

    /* ****************
  * Add user
  ******************/
  addUser(data){
      var regx = new RegExp("^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
      var RegExpConsicutive = new RegExp("(?=.*[A-Za-z])(?=.{2,})");
      if(regx.test(this.user.password) == false && this.newUserId == 0) {
        this.passwordError = this.PassInfo
        document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth',  block: 'start', inline: 'start' });
        $("#passerror").css("display", "block");
        $("#pass").focus();
        return;
      }
      if (this.form.valid && (this.user.phoneNumber != this.user.secondaryPhoneNumber)) {
        this.showError = false      
        this.apiService.update('customer/user/updateUserCustomerProfile', this.user).subscribe(
          data => {
            if(data.statusCode == 100){
              this.imageSave(this.newUserId);
              this.alertService.sweetMessage('success','Profile Updated successfully');
              //this.router.navigate(['/user-list']);
            }else{
              this.alertService.sweetMessage('error',data.responseMessage);
            }
          },
          error =>
            {    
              document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth',  block: 'start', inline: 'start' });
                       this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
        );
      } else {
        document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth',  block: 'start', inline: 'start' });
        this.showError = true
      }
  }

  imageSave(newUserId) {
    if(this.imageError==""){
      const uploadData = new FormData();
      if(this.imageName.length){
        uploadData.append('file', this.imageName[0], this.imageName[0].name);
        this.apiService.create('customer/uploadImage/uploadImage?section=1&sectionId=' + newUserId, uploadData).subscribe(
        data => {
          if (data.statusCode == 100) {
            // this.getCustomerById();
          } else {
            this.alertService.sweetMessage('error',data.responseMessage);
          }
          this.getUserInfo();
        },
        error => {
          this.getUserInfo();
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        });
      }else{
        this.getUserInfo();
      }
    }else{
      this.getUserInfo();
    }
  }

  getUserInfo(){
    this.apiService.getUserInfoData('customer/user/getUserInfo').subscribe(
      data => {
        if(data.statusCode == 100){
          ApiService.permission = data.response.permissions;
          if(ApiService.permission){
            ApiService.isPermission = 1;
          }
          localStorage.setItem("permission",JSON.stringify(data.response.permissions));
          localStorage.setItem("customerData", JSON.stringify(data.response));
          localStorage.setItem("userId", data.response.userId);
          localStorage.setItem("clientId", data.response.clientId);
        }else if(data.statusCode == 105){
        var msg = data.responseMessage;
       }else{
       }
      this.router.navigate(['/dashboard']);
    },
    error => {
    });
  }

  CancelProfile(){
    this.location.back();
  }

  removeInputImage() {
    this.imageUrlName.nativeElement.value = '';
    this.imageTitle = '';
  }

  // function for deleting image of branding 
  removeBrandingImage() {
    Swal({
      title: 'Are you sure?',
      text: 'You want to remove image.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        this.apiService.delete("customer/uploadImage/removeImage?section=1&sectionId=" + this.newUserId, '').subscribe(
          data => {
            if (data.statusCode == 100) {
              this.alertService.sweetMessage('success', 'Image removed successfully');
              this.imageUrl = 'assets/img/upload-img.png'
            } else {
              this.alertService.sweetMessage('error', data.responseMessage);
            }
          },
          error => {
            this.alertService.sweetMessage('error', "Something went wrong, please try again.");
          }
        );
      }
    })
  }


}
