import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';
import { jqxTreeComponent } from '../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../jqwidgets-ts/angular_jqxdropdownbutton'
import { environment } from "../../../../../../environments/environment"


@Component({
  selector: 'app-client-setting',
  templateUrl: './client-setting.component.html',
  styleUrls: ['./client-setting.component.css']
})

export class ClientSettingComponent implements OnInit {
  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  customerId:any =(localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);
  tierId:any =(localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
  countryList: any = [];
  stateList: any = [];
  dataTable: any;
  contactData: any = [];
  loginPageContent:any = ""

  filePath:any          = "assets/img/upload-img.png";
  filePathInner:any     = "assets/img/upload-img.png";
  filePathMobile:any    = "assets/img/upload-img.png";
  filePathBranding:any  = "assets/img/upload-img.png";
  filePathBrandingApp:any  = "assets/img/upload-img.png";

  client: any = {
    "description": "", "maxDaysHistory": "", "maxInactivityHours": "", "billingName": "", "deactivationTime": "",
    "requiresPinVerification": false, "isActive": true, "contactEmail": '', "criticalThresHold": "25", "contactPhone": '', "alternatePhone": ''
  };


  criticalThresHold = []

  clientIdForContact:any;
  typeForContact:any = "Client Contact";

  @ViewChild("addClient") addClient:any;
  @ViewChild("clientContact") clientContact:any;

  imageName:any;
  imageNameInner:any;
  imageNameMobile:any;
  clientBrandLogoWeb:any;
  clientBrandLogoApp:any;
  
  contact: any =
    {
      "firstName": "",
      "lastName": "",
      "note": "",
      "title": "",
      "emailId": "",
      "phoneNumber": "",
      // "mobileNumber": "",
      "secondaryPhoneNumber": "",
      "userType": "",
      client: {}
    }

  branding: any = {};
clientCode : any = '';


  constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService, private chRef: ChangeDetectorRef) {
    
   }

   DateFormat(startDate){
  if(startDate){
      return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
  }else{
      return "";
    }
 }
ngOnInit() {

  this.clientCode = this.alertService.getClientCode();

    // jQuery('.rst-date').datetimepicker({
    //   language: 'en',
    //   pickTime: true,
    //   pickDate: true,
    //   minDate: 0,
    //   inline: true,
    //   sideBySide: true,
    //   format: 'yyyy-MM-dd HH:mm:ss'
    // });

    for(let i = 25;i<=100;i++){
      this.criticalThresHold.push(i);
    }
    this.getClientById();
      this.getContactData();

  }




  /* Get Client by Id*/
  getClientById() {
    // this.apiService.getAll('public/client/getClientById?clientId='+this.clientId).subscribe(
    this.apiService.getAll('client/getClientById?clientId='+this.clientId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.client = data.response;
          let obj = this.client;
          this.branding = {
            clientDomain : obj.clientDomain,
            clientCode: obj.clientCode,
          }
          this.loginPageContent = obj.loginPageContent ? obj.loginPageContent : ""

          this.filePath = obj.logoFile ? this.apiService.imageBasePath+obj.logoFile : "assets/img/upload-img.png";

          this.filePathMobile = obj.appLogoPath ? this.apiService.imageBasePath+obj.appLogoPath : "assets/img/upload-img.png";

          // App Loader img
          this.filePathBrandingApp = obj.loaderImgMobile ? this.apiService.imageBasePath+obj.loaderImgMobile : "assets/img/upload-img.png";

          // Web Loader img
          this.filePathBranding = obj.loaderImg ? this.apiService.imageBasePath+obj.loaderImg : "assets/img/upload-img.png";

          // Set Loader image again when user change the loader image
          localStorage.setItem("loaderImg", obj.loaderImg);
          //this.apiService.clinetLoader();

          // display none loader coz above method set and enable the loader
          $("#loaderImgIcon").css("display","none");

          this.filePathInner = obj.clientInnerLogoPath ? this.apiService.imageBasePath+obj.clientInnerLogoPath : "assets/img/upload-img.png";

          // -------------------------------------------------------  branding details from client

        } else {
          this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
     error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  /* Create Client */
  SubmitClient() {
    this.client.deactivationTime = $('#deactivationTime').val();

    if (this.addClient.valid) {

        let obj = this.client
        delete obj.logoFile
        delete obj.appLogoPath
        delete obj.clientInnerLogoPath

        // this.apiService.update('public/client/updateClient', obj).subscribe(
        this.apiService.update('client/updateClient', obj).subscribe(
          data => {
            if (data.statusCode == 100) {
              Swal({
                position: 'top-end',
                type: 'success',
                title: 'Client Details Update Successful',
                showConfirmButton: false,
                timer: 1500
              })


            } else {
              this.alertService.sweetMessage('error',data.responseMessage);
            }
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );

    } else {
      Swal({
        position: 'top-end',
        type: 'error',
        title: 'Please fill in all the required fields.',
        showConfirmButton: false,
        timer: 1500
      })
    }

  }

  /* Get Contact data list */
  getContactData() {
    this.apiService.getAll('contactType/getClientContactListByClientId?clientId=' + this.clientId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.contactData = data.contactTypeList;
          if ($.fn.DataTable.isDataTable('#data_table')){
              $('#data_table').DataTable().destroy();
            };
        this.chRef.detectChanges();
         const table: any = $('#data_table');
        this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": true, "targets": 0 }]});
        } else {

        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  SaveContact() {

    if (this.clientContact.valid) {
      // if (this.contact.userId) {
      if (this.contact.contactTypeId) {
        this.apiService.update('contactType/updateClientContact', this.contact).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.getContactData();
              this.alertService.sweetMessage('success',data.responseMessage);

              this.contact =
                {
                  "firstName": "",
                  "lastName": "",
                  "note": "",
                  "title":"",
                  "emailId": "",
                  "phoneNumber": "",
                  // "mobileNumber": "",
                  "secondaryPhoneNumber": "",
                  "userType": "",
                  client: {}
                }

                this.clientContact.reset();
                this.clientContact.submitted = false;
            } else {
              this.alertService.sweetMessage('error',data.responseMessage);
            }
          },
         error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
      } else {

        this.clientContact.submitted = false;
        this.contact.client.clientId = this.clientId;
        this.contact.userType = this.typeForContact;
        this.apiService.create('contactType/createClientContact', this.contact).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.getContactData();
              this.alertService.sweetMessage('success',data.responseMessage);
              this.contact =
                {
                  "firstName": "",
                  "lastName": "",
                  "note": "",
                  "title" : "",
                  "emailId": "",
                  "phoneNumber": "",
                  // "mobileNumber": "",
                  "secondaryPhoneNumber": "",
                  "userType": "",
                  // client: {}
                  client: {}
                }
                this.clientContact.reset();

                this.clientContact.submitted = false;
            } else {
              this.alertService.sweetMessage('error',data.responseMessage);
            }
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
      }
    }


  }

  SubmitBranding(){
    let obj = this.branding
    obj.clientId = this.clientId;
    let val = this.loginPageContent.replace('',"");
    obj.loginPagecontent =  val//this.loginPageContent//.substring(1, 199);
    // console.log(obj,val,this.loginPageContent);

    // this.imageSave(6,this.imageName);
    // this.imageSave(4,this.imageNameMobile);
    // this.imageSave(5,this.imageNameInner);
    // this.imageSave(7,this.clientBrandLogo);

    // this.apiService.update('public/client/updateBranding', obj).subscribe(
    this.apiService.update('client/updateBranding', obj).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.getContactData();
          this.getClientById();

          this.alertService.sweetMessage('success',data.responseMessage);
          // this.branding = {}
          this.getClientById();
        } else {
          this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
     error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );


  }

  imageSave(section,imageName){

    const uploadData = new FormData();

    if(imageName){
      uploadData.append('file', imageName[0], imageName[0].name);
      this.apiService.create('uploadImage/uploadImage?section='+section+'&sectionId='+this.clientId, uploadData).subscribe(
        data => {
            if(data.statusCode == 100){
              setTimeout(()=>{
                this.imageName = null;
                this.imageNameInner = null;
                this.imageNameMobile = null;
                this.clientBrandLogoWeb = null;
                this.clientBrandLogoApp = null;
              },2000)
            }else{
                this.alertService.sweetMessage('error',data.responseMessage);
                  }
                },
           error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

  }


  /**********************************************
    Image upload For Login Page Logo
  ************************************/
  onFileChanged(event) {

      const file = event.target.files;

      console.log(file[0].type);


      if ((file[0].size/1024)>500)
        { this.alertService.sweetMessage('error',"Size should be below 500 KB");
        $("#logoFile").val('')

      }
      else {

        if(file[0].type != 'image/png' && file[0].type != 'image/jpg' &&  file[0].type != 'image/jpeg'){
          this.alertService.sweetMessage('error',"Only PNG/JPG/JPEG image are allowed");
              $("#logoFile").val('')
              return;
        }


        var reader = new FileReader();
        reader.readAsDataURL(file[0]);

        reader.onload = (_event:any) => {
          var img = new Image();
          img.src = _event.target.result;

          img.onload = () => {
            if (img.width < 500 && img.height < 500) {
              this.filePath = reader.result;
              this.imageName = file;
              this.imageSave(6,this.imageName);
            } else {
              $("#logoFile").val('')
              this.alertService.sweetMessage('error',"Image should not be greater than 500 X 500");
            }
          };

        }
      }

    }


/**********************************************
  Image upload For Cleint Inner page Logo
************************************/
    onFileChangedInner(event) {
    const file = event.target.files;

      console.log(file[0].type);

      if ((file[0].size/1024)>500){
         this.alertService.sweetMessage('error',"Size should be below 500 KB");
          $("#imageNameInner").val('')

      }
      else {

        if(file[0].type != 'image/png' && file[0].type != 'image/jpg' &&  file[0].type != 'image/jpeg'){
          this.alertService.sweetMessage('error',"Only PNG/JPG/JPEG image are allowed");
          $("#imageNameInner").val('')

          return;
        }

        var reader = new FileReader();
        reader.readAsDataURL(file[0]);

        reader.onload = (_event:any) => {
          var img = new Image();
          img.src = _event.target.result;
          img.onload = () => {
            if (img.width <= 300 && img.height <= 300) {
              this.filePathInner = reader.result;
              this.imageNameInner = file;
              this.imageSave(5,this.imageNameInner);
            } else {
              $("#imageNameInner").val('')
              this.alertService.sweetMessage('error',"Image should not be greater than 300 X 300");
            }
          };
        }
      }
    }


  /**********************************************
    Image upload For Mobile Logo
  ************************************/
      onFileChangedMobile(event) {
          const file = event.target.files;
          // console.log(file[0],(file[0].size/1024),file[0].height,file);
          // console.log(file[0].size/1024);

          if ((file[0].size/1024)>500)
            { this.alertService.sweetMessage('error',"Size should be below 500 KB"); 
            $("#mobilelogoFile").val('')
          
          }
          else  {
            var reader = new FileReader();
            reader.readAsDataURL(file[0]);

            reader.onload = (_event:any) => {
              var img = new Image();
              img.src = _event.target.result;

              img.onload = () => {
                if (img.width <= 500 && img.height <= 500) {
                  this.filePathMobile = reader.result;
                  this.imageNameMobile = file;
                  this.imageSave(4,this.imageNameMobile);
                } else {
                  $("#mobilelogoFile").val('')

                    this.alertService.sweetMessage('error',"Image should not be greater than 170 X 170");
                }
              };

            }
          }


        }



/**********************************************
  Image upload For Cleint Branding Logo For WEB
****************************************************/

onFileChangedClientBrandWeb (event) {
  const file = event.target.files;
  // console.log(file[0],(file[0].size/1024),file[0].height,file);
  // console.log(file[0].size/1024);
  console.log(file[0].type);

  if(file[0].type != 'image/gif'){
    this.alertService.sweetMessage('error',"Only Gif image are allowed as loader");
      $("#weblogofile").val('')
      return;
  }

  if ((file[0].size/1024)>500) {
    Swal(
      { position: 'top-end', type: 'error',
        title: 'Size should be below 500 KB',
        showConfirmButton: false, timer: 2500
      })
      $("#weblogofile").val('')

  }else {

    var reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = (_event:any) => {
      var img = new Image();
      img.src = _event.target.result;

      img.onload = () => {
        if (img.width <= 200 && img.height <= 200) {
          this.filePathBranding = reader.result;
          this.clientBrandLogoWeb  = file;
          this.imageSave(7,this.clientBrandLogoWeb);
        }
        else {
          $("#weblogofile").val('')
          
          this.alertService.sweetMessage('error',"Image should not be greater than 200 X 200");
        }
      };
    }
  }
}




/**********************************************
  Image upload For Cleint Branding Logo For App
************************************************************/

onFileChangedClientBrandApp (event) {
  const file = event.target.files;
  // console.log(file[0],(file[0].size/1024),file[0].height,file);
  // console.log(file[0].size/1024);

  if(file[0].type != 'image/gif'){
    this.alertService.sweetMessage('error',"Only Gif image are allowed as loader");
    return;
  }

  if ((file[0].size/1024)>500) {
    Swal({
      position: 'top-end',
      type: 'error',
      title: 'Size should be below 500 KB',
      showConfirmButton: false,
      timer: 2500
    })
  } else {

    var reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = (_event:any) => {
      var img = new Image();
      img.src = _event.target.result;

      img.onload = () => {
        if (img.width <= 170 && img.height <= 170) {
          this.filePathBrandingApp = reader.result;
          this.clientBrandLogoApp  = file;
          this.imageSave(8,this.clientBrandLogoApp);
        }
        else {
          this.alertService.sweetMessage('error',"Image should not be greater than 170 X 170");
        }
      };
    }
  }
}

  getContactById(id){
    this.apiService.getAll('contactType/getContactTypeById?contactTypeId='+id).subscribe(
      data => {
        if (data.statusCode == 100)
          { this.contact = data.contactType; }
        else {
          // this.contactData = [];
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  DeleteClientContact(userId){
    Swal({
        title: 'Are you sure?',
        text: "You want to delete this Contact.",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          // this.apiService.getAll('contactType/deleteClientContact?userId='+userId).subscribe(
        this.apiService.getAll('contactType/deleteClientContact?contactTypeId='+userId).subscribe(
          data => {
          if(data.statusCode == 100){
            this.getContactData();
            this.chRef.detectChanges();
            const table: any = $('.table');
            this.dataTable = table.DataTable();
            this.contact= {
                "firstName": "","lastName": "",
                "note": "", "title": "",
                "emailId": "", "phoneNumber": "",
                "secondaryPhoneNumber": "", "userType": "", "client": {}
              }
            this.alertService.sweetMessage('success','Deleted Successfully');

            }
            else
              { this.alertService.sweetMessage('error',data.responseMessage); }
        },
       error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
          );
        }
      })
  }
}
