import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, FormatDateService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
import * as $ from 'jquery'
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';


@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css']
})
export class CommentsComponent implements OnInit {

  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  assetId :any = 0;
  assetsumaryData : any ={};
  AssetCommentsData: any =[];
   siteLat:number = 39.381266;
  siteLong:number = -97.922211;
  dataTable: any;
  // permissionData:any=[];
  constructor(
    private router:Router,
    private route: ActivatedRoute,
     private apiService: ApiService , 
     private alertService: AlertService, 
     private chRef: ChangeDetectorRef,
     private formatDateService: FormatDateService
     ) {
    // this.permissionData = ApiService.permission;
  }

  // Function to format Date
  DateFormat(startDate, gmtOffset){
      return this.formatDateService.formatDate(startDate, gmtOffset);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
        if(params['id']){
       this.assetId = params['id'];
      }
    });

    if(this.assetId){
      this.getAssetCommentsData();
      this.getAssetSummaryData();
    }

  }

   // Function to set latitude & longitude
    setLatLong(lat, long) {
        this.siteLat = lat;
        this.siteLong = long;
    }

  /* Function for getting Asset Summary Data */
  getAssetSummaryData(){
    this.apiService.getAll('asset/getAssetSummaryById?clientId='+this.clientId+'&userId='+this.userId+'&assetId='+this.assetId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.assetsumaryData= data.response;
          this.chRef.detectChanges();
          const table: any = $('table');
          this.dataTable = table.DataTable();
        }else{
          this.chRef.detectChanges();
          const table: any = $('table');
          this.dataTable = table.DataTable();
          this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
     error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }


  /* Function for getting Asset Comments Data */
  getAssetCommentsData(){
    this.apiService.getAll('comment/getCommentByAssetId?assetId='+this.assetId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.AssetCommentsData= data.commentList;

        }else{
          this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
     error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }
}
