import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd} from '@angular/router';
import { ApiService, AlertService } from '../../../../services/index';
// import * as $ from 'jquery';
import Swal from 'sweetalert2'
import { CookieService } from 'ngx-cookie-service';
declare var moment:any, $:any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  // customerData: any = JSON.parse(localStorage.getItem("customerData"))|| [];
  customerData: any = JSON.parse(localStorage.getItem("customerData"));
  userImage = "assets/img/favicon.ico";
  //userName: any = localStorage.getItem("userName");
  permissionData: any = [];
  isPermission:number=0;
  searchkey : any;
  dashboardCount: any = [];
  // customerInnerlogoPath : any = this.customerData.customerInnerlogoPath;
  customerInnerlogoPath : any = "assets/img/cdm_logo_w.png";

  intervalObj: any;
  intervalObjToken: any;

  constructor(private router:Router, private route: ActivatedRoute, private apiService: ApiService, private alert :AlertService,  private ck:CookieService) { }

  ngOnInit() {

    // Capture the Enter Key press event for Global Search
    var _this = this;
    $(document).on('keypress',function(e) {
      if(e.which == 13) {
        _this.globalSearch()
      }
    })

    let user = JSON.parse(localStorage.getItem("Token"))

    if (!user) {
        setTimeout(()=>{
          this.router.navigate(['/login']);
          //  this.alert.sweetMessage('error',"Please login!");
        },1000)
        this.router.navigate(["/login"])
        return
    }

    this.router.events.subscribe((val)=>{
      if (val instanceof NavigationStart) {
        window.scrollTo(0,0);
        this.customerData = JSON.parse(localStorage.getItem("customerData"));
        console.log('headers data')
        if(this.customerData){
          this.userImage =  this.customerData.imageLocation ? this.apiService.imageBasePath + this.customerData.imageLocation :"assets/img/favicon.ico";
       
          this.customerInnerlogoPath = this.customerData.customerInnerlogoPath ? this.apiService.imageBasePath + this.customerData.customerInnerlogoPath : "assets/img/cdm_logo_w.png";
          
          }else{
          this.userImage =  this.customerData.imageLocation ? this.apiService.imageBasePath + this.customerData.imageLocation :"assets/img/favicon.ico";
          }
      }
     })

  //this.getDashboardCount();
 //$('table').DataTable();

    this.permissionData = ApiService.permission;
    this.isPermission = ApiService.isPermission;


    if (ApiService.isPermission == 0 || ApiService.isPermission == undefined) {
        ApiService.permission = (localStorage.getItem("permission")?JSON.parse(localStorage.getItem("permission")):'');

        if(this.permissionData){
            this.permissionData = ApiService.permission;
        }else{
            localStorage.clear();
        }

    }


    $(document).ready(function () {

      $("td .dropdown-toggle").click(function(e){
        $('tr').removeClass('active');
          $(this).parents('tr').toggleClass('active');
      });
      $(document).click(function(e){
          $('tr').removeClass('active');
      });
    //$('select').selectpicker();

      $('.slide-main-menu li.has-chidren > a').on('click', function(){
          $(this).parent("li.has-chidren").toggleClass("dropdown-active");
          $(this).next("ul.menu-dropdown").slideToggle();
      });
      $('.main > a').on('click', function(){
        $('.sm-toggle').removeClass('smt-active');
        $('body').removeClass('body-smt-active');
        $('.slide-menu-wrap').removeClass('slide-menu-active');
      });
      var header_height = $("header.main-header").height();
      $("body").css({"padding-top": header_height});

      $(window).resize(function(){
        var header_height = $("header.main-header").height();
        $("body").removeClass('login').css({
            "padding-top": header_height
        });
      });

    });

    this.intervalObj = setInterval(()=>{
        this.getUserInfo();
    },300000)///300000

    this.intervalObjToken = setInterval(()=>{
        this.refreshtoken();
    },450000)// 1800000 (30 min)


  }
 
  ngOnDestroy(): void {
      //Called once, before the instance is destroyed.
      //Add 'implements OnDestroy' to the class.
      clearInterval(this.intervalObj);
      clearInterval(this.intervalObjToken);
      // console.log("Interval close");
      
  }

  refreshtoken(){
      console.log("refresh_token");
      
      let token = JSON.parse(localStorage.getItem('Token'))
      // let lasttime = localStorage.getItem('t11')
      let lasttime = this.ck.get('t11')
      console.log(lasttime,moment().diff(moment(new Date(parseInt(lasttime)))),new Date(parseInt(lasttime)));
      let diff = moment().diff(moment(new Date(parseInt(lasttime))))
      if (!token) {
          return
      }
      
      if(diff/1000 > 3600 /* 3600 */){
        let isLogin = this.ck.get('isLogin')
        if (isLogin) {
            
        }else{
            $('.modal.show').modal('hide') 
            localStorage.removeItem('Token')            
            localStorage.clear();
            console.log("redirect-login");
            // this.alert.sweetMessage('error',"Session timed out.");
            Swal({
              position: 'top-end',
              type: 'error',
              title: 'Session timed out.',
              showConfirmButton: false,
              timer: 10000
            })
            this.router.navigate(["/login"]);
            return
        }
    }
      // if(diff/1000 > 1200){
          let obj ={
              refresh_token: token.refresh_token,
              grant_type: "refresh_token"
          }
          // console.log(token.access_token, "token");
 
          // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  uncomment for refresh token
          // -------------------------------------------------------------start -----------------------
                   
          // this.apiService.refreshToken('userauth/oauth/token',obj).subscribe(
          //     data => {
          //         // console.log(data.access_token, "tokenc", data);

          //         // this.apiService.getUserInfoData('user/updateSession?userId='+this.userId+'&token='+token.access_token+'&newToken='+data.access_token).subscribe(
          //         //     dataToken => {        
                          
          //         //     },
          //         //     errorToken => {
          //         //         console.log(errorToken);
                          
          //         //     }
          //         // );
          //         // setTimeout(() => {
          //             localStorage.setItem("Token", JSON.stringify(data));

          //             document.cookie = "refresh_token=;"//+data.refresh_token
          //             document.cookie = "refresh_token="+data.refresh_token+'; path=/; expires=' + new Date(moment().add(30, 'days')).toUTCString();
          //         // }, 500);
                  

          //     },
          //     error => {
          //         console.log(error);
                  
          //     }
          // );
			// -------------------------------------------------------------end -----------------------

      // }
      
  }

  getUserInfo(){

    let token = JSON.parse(localStorage.getItem('Token'));
        
            /* ---------------------------- remove as per 0074192 and 75062----- */
        // if (token && token.access_token) {
        //     fetch(this.apiService.AppUrlService+'/customer/user/getUserInfo', {method:"GET",headers :{ 'Content-Type': 'application/json',  /*  mode: 'no-cors' */  'Authorization': 'bearer '+token.access_token}})
        //         .then((response) => {                    
        //             return response.json();
        //         })
        //         .then((myJson) => {
        //             console.log(myJson);
        //             if (myJson.statusCode == 100) {    

        //               ApiService.permission = myJson.response.permissions;
        //               if(ApiService.permission){
        //                 ApiService.isPermission = 1;
        //               }
        //               localStorage.setItem("clientId",JSON.stringify(myJson.response.clientId));
        //               localStorage.setItem("permission",JSON.stringify(myJson.response.permissions));
        //               localStorage.setItem("customerData", JSON.stringify(myJson.response));
        //               localStorage.setItem("customerId", myJson.response.customerId);
        //               localStorage.setItem("userId", myJson.response.userId);
        //               localStorage.setItem("clientId", myJson.response.clientId);
                      
        //               localStorage.setItem("permission",JSON.stringify(myJson.response.permissions));
        //                 return myJson;
        //                 // localStorage.clear();
        //                 // // localStorage.removeItem("Token");
        //                 // routeNavigate.navigate(['/login']);
        //             }
        //         }).catch((error)=>{
        //             console.log(error.status, error.statusCode, error.errorStatus, error);
        //             return error
        //         });
        // }

    // this.apiService.getUserInfoData('customer/user/getUserInfo').subscribe(
    //       data => {
    //       if(data.statusCode == 100){
            
    //           ApiService.permission = data.response.permissions;
    //           if(ApiService.permission){
    //             ApiService.isPermission = 1;
    //           }
    //           localStorage.setItem("clientId",JSON.stringify(data.response.clientId));
    //           localStorage.setItem("permission",JSON.stringify(data.response.permissions));
    //           localStorage.setItem("customerData", JSON.stringify(data.response));
    //           localStorage.setItem("customerId", data.response.customerId);
    //           localStorage.setItem("userId", data.response.userId);
    //           localStorage.setItem("clientId", data.response.clientId);
              
    //           localStorage.setItem("permission",JSON.stringify(data.response.permissions));
    //         // this.router.navigate(['/dashboard']);
    //         // Swal({
    //         //   position: 'top-end',
    //         //   type: 'success',
    //         //   title: 'Login Successful',
    //         //   showConfirmButton: false,
    //         //   timer: 1500
    //         // })
    //       }else if(data.statusCode == 105){
    //       var msg = data.responseMessage;
    //     }else{

    //     }
    //   },
    //   error => {
    //     this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    //   }
    // );
  }

  /***************************
    Method for Global Search
  ***********************************/

    globalSearch(){
      if(this.searchkey) {
        var keyValue = this.searchkey;
        this.searchkey = ''
        if(keyValue.trim()){
          this.router.navigate(['/global-search',keyValue]);
	      }
      }
    }



  selectClient(clientId){
    localStorage.setItem("clientId", clientId);
  }
  getCompanyId(compid){
    localStorage.setItem("compid", compid);
     this.router.navigate(['/companies']);
  }

  logout() {
      var viaLink = localStorage.getItem("viaLink");

      this.apiService.logout('userauth/oauth/revokeToken').subscribe(
        data => {
           if(data.status == 200) {
                $("body").removeClass('body-smt-active');
                if(viaLink == 'yes') {
                  var clientCode = localStorage.getItem("clientCode");
                  var customerCode = localStorage.getItem("customerCode");
                  localStorage.clear();
                  this.router.navigate(['/login'], { queryParams: { clientCode: clientCode, customerCode : customerCode } });
                } else {
                  localStorage.clear();
                  this.router.navigate(['/login']);
                }
                this.ck.delete('isLogin')
                this.ck.deleteAll()
                document.cookie = "isLogin=;"
                document.cookie = "isLogin=;"
                this.ck.delete('refresh_token');
            }
        },
        error => {
            //this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
        );
  }


  toggleSidebar(){
    $('.sm-toggle').toggleClass('smt-active');
    $('.slide-menu-wrap').toggleClass('slide-menu-active');
    $('body').toggleClass('body-smt-active');
  }

  getDashboardCount(){
      this.apiService.getAll('asset/getSiteCount?companyId=').subscribe(
			data => {
				if(data.statusCode == 100){
					this.dashboardCount = data.response;
				}else{

				}
			},
			error => {
				//this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
			}
		);
   }






}
