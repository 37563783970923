import { Component, OnInit } from '@angular/core';
// import * as version from '../../../../../assets/version.json'

declare var moment:any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  buildDate:any = ''
  version={"version":"2019-10-24T10:40:55.092Z"};
  constructor() { }

  ngOnInit() {
    this.buildDate = moment(this.version.version).format('YYYYMMDD')
  }

}
