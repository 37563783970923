import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, FormatDateService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';
import { jqxTreeComponent } from '../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../jqwidgets-ts/angular_jqxdropdownbutton'
import * as NProgress from 'nprogress';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours} from 'date-fns';
import flatpickr from "flatpickr";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-adhoc-report',
  templateUrl: './adhoc-report.component.html',
  styleUrls: ['./adhoc-report.component.css']
})

export class AdhocReportComponent implements OnInit {
    @ViewChild('myTree') myTree: jqxTreeComponent;
    @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;
    clientId: any = localStorage.getItem("clientId");
    userId: any = localStorage.getItem("userId");
    customerId: any = (localStorage.getItem("customerId") ? JSON.parse(localStorage.getItem("customerId")) : []);
    tierId: any = (localStorage.getItem("tierId") ? JSON.parse(localStorage.getItem("tierId")) : []);
    dropdownSettingsSite:any = {};
    dropdownSettings:any = {};
    dropdownSettingsUser:any ={};
    dropdownSettingsAsset:any ={};
    siteList: any = [];
    customerList: any = [];
    tierListData: any = [];
    data: any = [];
    dataAdapter: any = [];
    source: any = {};
    records: any = [];
    disableTreeArray: any = [];
    checkTreeArray: any = [];
    tiersArray: any = [];
    reportObjects:any=[];
    reportUsers:any=[];
    reportId: any = 0;
    reportperiod: any = "";
    weekdayMapData:any = 0;
    userListDataForTable1:any=[];
    userListDataForTable:any=[];
    userListTableData: any = [];
    NProgress: any;
    userListData:any = {
      "firstName":"",
      "email":"",
      "phoneNumber":"",
      "mobileNumber":"",
      "title":""
    };

    recurringPattern:any = {
      "isMonday":"",
      "isTuesday":"",
      "isWednesday":"",
      "isThursday":"",
      "isFriday":"",
      "isSaturday":"",
      "isSunday":""
    }

    frequencyDataForCheck:any = {
      "frequencyStep":"",
      "weekdayMap":"",
      "dateOfMonth":"",
      "weekOfMonth":"",
      "weekdayOfMonth":"",
      "monthOfYear":""
    }

    addFormData:any = {
      "reportTime":"00:00:00",
      "isActive":true,
      "name":"",
      "description":"",
      "frequencyStep":"",
      "weekdayMap":"",
      "dateOfMonth":"",
      "weekOfMonth":"",
      "weekdayOfMonth":"",
      "monthOfYear":"",
      "emailAddress" : "",
      "startDate" : '',
      "endDate" : ''
    }

    reportData:any = {
      "isActive":true,
      "frequencyType":"",
      "name":"",
      "description":"",
      "frequencyStep":"",
      "reportTime":"",
      "weekdayMap":"",
      "dateOfMonth":"",
      "weekOfMonth":"",
      "weekdayOfMonth":"",
      "monthOfYear":"",
      "users":[],
      "customers":[],
      "tiers":[],
      "sites":[],
      "reportType" : '1',
    //   "fileFormat" : 'xls',
    "fileFormat" : 'xlsx',
      "startDate" : '',
      "endDate" : ''
      }

    frequencyData: any = {};
    checkSiteArray: any = [];
    checkCustomerArray: any = [];
    checkAssetArray: any = [];
    checkUserArray: any = [];
    radioSelected: string = 'Daily';
    checkTreeArrayData: any = [];
    siteIdForApi:any;
    assetIdForApi:any;
    tierIdForApi:any;
    customerIdForApi:any;
    reportForUser:any=[];
    dataForShowTable:any = [];
    userIdForshow :any = 0;

    selectedItems: any = [];
    selectedSite: any = [];
    selectedcustomer: any = [];
    selectedsite: any = [];
    selecteuser: any = [];
    selectedUser: any = [];
    user : any = [];
    afterEditData:any=[];
    showSelectedUserTable:any=[];
    checkDataForchecked:any=0;

    monthlyRadioSelected : string;
    YearlyRadioSelected : string;

    resultForSite:any      =  [];
    resultForUser:any      = [];
    resultForAsset:any      = [];
    resultForCustomer:any  = [];
    clientCode: any = '';
    preivewData: any = [];
    permissionData:any=[];
    assetDatalist: any = [];
    selectedAsset: any = [];
    selectedasset: any = [];
    checkDateDiff:boolean = false;
    detailPreviewData : any  = [];
    selectAllTier = false;
    dataTable: any;
    maxStartDate:any = new Date();
    filterObj:any = {
      "startDate": new Date(),
      "endDate": new Date(),
      "selectedAssetId": 0,
    }
    todayDateInFutureMonth:any = moment(new Date()).add(1, 'M');
    minEndDate:any = this.filterObj.startDate;
    callDetailReport:boolean = false;
    emailData:any = {
        emails:'',
        message:''
    };
    emailArray = [];
    showValidEmailErr:boolean = false;
    showMsgErr:boolean = false;
    showEmailErr:boolean = false;
    emailIds:any = "";
    emailBodyMsg:any = "";
    newCheckDateDiff:boolean = false;
    activeTab:any = 'summaryTab';
    showTable:boolean = false;
    enableTreeArray:any = [];
    disableTreeLength:any=0;
    newDisableTreeArray:any=[];

    emailIdArr:any = [];
    // emailObj : any;
    emailObj:any={};
    showRootCauseColum:boolean = false;
    excelFile: any = '';
    constructor(
        private router: Router, 
        private route: ActivatedRoute, 
        private apiService: ApiService , 
        private alertService: AlertService, 
        private formatDateService: FormatDateService,
        private chRef: ChangeDetectorRef, private sanitizer: DomSanitizer
        ) {
        this.permissionData = ApiService.permission;
    }

    // Function to format Date
    DateFormat(startDate){
        if(startDate){
            return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
        }else{
            return "";
        }
    }

    // Function to format Date
    ShowDateFormat(startDate, gmtOffset){
        return this.formatDateService.formatDate(startDate, gmtOffset);
    }

    ngOnInit() {
        let self = this;
        this.setFlatPicker();//initialize date picker using flatpicker
        this.clientCode = this.alertService.getClientCode();
        this.route.params.subscribe(params => {
            if (params['reportId']) {
                this.reportId = params['reportId'];
            }
        });
        if (this.reportId) {
            this.getReportById();
        }

        this.getSiteManagementList();
        this.getCustomerList();
        // this.getTierList({value:1});
        this.getAllAssetList();

        this.dropdownSettingsSite = {
            singleSelection: false,
            idField: 'siteId',
            textField: 'siteName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 0,
            allowSearchFilter: true,
            placeholder : "Select Site" 
        };
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'customerId',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 0,
            allowSearchFilter: true,
            placeholder : "Select Customer" 
        };

        this.dropdownSettingsAsset = {
            singleSelection: false,
            idField: 'assetId',
            textField: 'assetName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 0,
            allowSearchFilter: true,
            placeholder : "Select Asset",
        };

        this.dropdownSettingsUser = {
            singleSelection: false,
            idField: 'userId',
            textField: 'firstName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 0,
            allowSearchFilter: true
        };
        // this.initializeSummaryDataTable();
        // this.initializeDetailSummaryDataTable();
    }

    initializeSummaryDataTable(){
        if ($.fn.DataTable.isDataTable('#summary_table')){
          $('#summary_table').DataTable().destroy();
        };
        this.dataTable   = $('#summary_table').DataTable();
    }

    initializeDetailSummaryDataTable(){
        if ($.fn.DataTable.isDataTable('#detail_table')){
          $('#detail_table').DataTable().destroy();
        };
        this.dataTable   = $('#detail_table').DataTable();
    }

    initilaiseCal(){

    }

    clickEmail() {
        $('#no-record-found').css('display', 'block');
    }

    /* Get Site Management list */
    getSiteManagementList() {
        this.siteList = []
        this.selectedSite = [];
        this.checkSiteArray = [];
        this.siteIdForApi = "";
        this.checkAssetArray = [];
        this.assetDatalist = [];
        this.selectedAsset = [];
        this.assetIdForApi = "";

        var URL = 'site/getSiteByClient?alphabateSortingOreder=1&clientId=' + this.clientId + '&userId=' + this.userId;
        if (this.customerId != null && this.customerId != '') {
            var customerString = '&customerId=' + this.customerId.toString();
            var URL = URL.concat(customerString);
        }
        // if (this.tierId != null && this.tierId != '') {
        //     var tierString = '&tierId=' + this.tierId.toString();
        //     var URL = URL.concat(tierString);
        // }
        let Tier = this.checkTreeArray.join();
        if (Tier != null && Tier != '') {
            var tierString = '&tierId=' + Tier.toString();
            var URL = URL.concat(tierString);
        }
        if (this.checkTreeArray.length == 0) {    
            this.siteList = []
            this.selectedSite = [];
        }else {
            this.apiService.getAll(URL).subscribe(
                data => {
                    if (data.statusCode == 100) {
                        if(this.checkTreeArray.length != 0) {
                            this.siteList = data.responseList;
                            let tempSelected = [];
                            this.selectedSite.map((obj, index)=>{
                                let findObj = _.find(this.siteList, { siteId: obj.siteId});
                                // console.log(findObj)
                                if (findObj) {
                                    tempSelected.push(obj);
                                }
                            })
                            this.selectedSite = tempSelected;
                        }                        
                    } else {
                        this.siteList = [];
                    }
                },
                error =>{
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                }
            );
        }
    }

    /* Get Customer list */
    getCustomerList() {
        var URL = 'customer/getCustomerByClient?clientId=' + this.clientId;
        if (this.customerId != null && this.customerId != '') {
            var customerString = '&customerId=' + this.customerId.toString();
            var URL = URL.concat(customerString);
        }
        // if (this.tierId != null && this.tierId != '') {
        //     var tierString = '&tierId=' + this.tierId.toString();
        //     var URL = URL.concat(tierString);
        // }
        this.tierIdForApi = this.checkTreeArray.join();
        if (this.tierIdForApi != null && this.tierIdForApi != '') {
            var tierString = '&tierId=' + this.tierIdForApi.toString();
            var URL = URL.concat(tierString);
        }



        if (this.checkTreeArray.length == 0) {    
            this.customerList = []
            this.selectedItems = [];
        }else {
            this.apiService.getAll(URL).subscribe(
                data => {
                    if (data.statusCode == 100) {
                        this.customerList = data.responseList;
                        let tempSelected = []

                        this.selectedItems.map((obj, index)=>{
                            let findObj = _.find(this.customerList, { customerId: obj.customerId});
                            if (findObj) {
                                tempSelected.push(obj);
                            }
                        })
                        this.selectedItems = tempSelected;
                    } else {
                        this.customerList = [];
                        /*this.alertService.sweetMessage('error',data.responseMessage);*/
                    }
                },
                error =>{
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                }
            );
        }
    }


    getReportperiod(value) {
        this.reportperiod = value;
    }

    /* On Item Select Asset */
    onItemSelectAsset(item: any) {
        var exist = this.checkAssetArray.indexOf(item.assetId.toString());
        if (exist == '-1') {
            this.checkAssetArray.push(item.assetId.toString());
            this.assetIdForApi = this.checkAssetArray.join();
        }
    }

    /* On Item DeSelect Asset */
    onItemDeselectAsset(item: any) {
        var exist = this.checkAssetArray.indexOf(item.assetId.toString());
        if (exist != '-1') {
            this.checkAssetArray.splice(exist, 1);
            this.assetIdForApi = this.checkAssetArray.join();
        }
    }

    /* On All Select Asset */
    onSelectAllAsset(items: any) {
        this.checkAssetArray = [];
        Object.keys(items).forEach(key => {
            this.checkAssetArray.push(items[key]['assetId'].toString());
            this.assetIdForApi = this.checkAssetArray.join();
        });
    }

    /* On All DeSelect Site */
    onDeSelectAllAsset() {
        this.checkAssetArray = [];
        this.assetIdForApi = this.checkAssetArray.join();
    }

    onItemSelectSite(item: any) {
        var exist = this.checkSiteArray.indexOf(item.siteId.toString());
        if (exist == '-1') {
            this.checkSiteArray.push(item.siteId.toString());
            this.siteIdForApi = this.checkSiteArray.join();
            this.getUserListData();
            this.getAllAssetList();
        }
    }

    onItemDeselectSite(item: any) {

        var exist = this.checkSiteArray.indexOf(item.siteId.toString());
        if (exist != '-1') {
            this.checkSiteArray.splice(exist, 1);
            this.siteIdForApi = this.checkSiteArray.join();
            this.getUserListData();
            this.getAllAssetList();
        }
    }

    onSelectAllSite(items: any) {
        this.checkSiteArray = [];
        this.siteIdForApi = this.checkSiteArray.join();

        Object.keys(items).forEach(key => {
            this.checkSiteArray.push(items[key]['siteId'].toString());
            this.siteIdForApi = this.checkSiteArray.join();
            this.getUserListData();
            this.getAllAssetList();
        });
    }

    onDeSelectAllSite() {
        this.checkSiteArray = [];
        this.siteIdForApi = this.checkSiteArray.join();
        this.getUserListData();
        this.getAllAssetList();
    }

    onItemSelectCustomer(item: any) {
        var exist = this.checkCustomerArray.indexOf(item.customerId.toString());
        if (exist == '-1') {
            this.checkCustomerArray.push(item.customerId.toString());
            this.customerIdForApi = this.checkCustomerArray.join();
            this.getUserListData();
        }

    }

    onItemDeselectCustomer(item: any) {
        var exist = this.checkCustomerArray.indexOf(item.customerId.toString());
        if (exist != '-1') {
            this.checkCustomerArray.splice(exist, 1);
            this.customerIdForApi = this.checkCustomerArray.join();
            this.getUserListData();
        }

    }

    onSelectAllCustomer(items: any) {
        this.checkCustomerArray = [];
        this.customerIdForApi = this.checkCustomerArray.join();
        this.getUserListData();

        Object.keys(items).forEach(key => {
            this.checkCustomerArray.push(items[key]['customerId'].toString());
            this.customerIdForApi = this.checkCustomerArray.join();
            this.getUserListData();
        });
    }

    onDeSelectAllCustomer() {
        this.checkCustomerArray = [];
        this.customerIdForApi = this.checkCustomerArray.join();
        this.getUserListData();
    }


    //******
    onItemSelectUser(item: any) {

        var exist = this.checkUserArray.indexOf(item.userId.toString());
        if (exist == '-1') {
            this.checkUserArray.push(item.userId.toString());
        }
        this.showTableInfo(item.userId);
    }

    onItemDeselectUser(item: any) {
        var exist = this.checkUserArray.indexOf(item.userId.toString());
        if (exist != '-1') {
            this.checkUserArray.splice(exist, 1);
            this.removeTableInfo(item.userId);
        }
    }

    onSelectAllUser(items: any) {
        this.checkUserArray = [];
        this.userListTableData = [];
        this.checkUserArray = _.map(items, 'userId');
        this.showTableInfo(this.checkUserArray);
    }

    onDeSelectAllUser(item: any) {
        this.checkUserArray = [];
        this.userListTableData = [];
    }

    // function to create tier
    createTierArray() {
      for (var tierId in this.checkTreeArray) {
           this.tiersArray.push({
                tierId: this.checkTreeArray[tierId],
            })
        }
    };

    // Function to add report
    addReportData(data) {
        let startDate = this.newDateFormat(this.filterObj.startDate);
        let endDate = this.newDateFormat(this.filterObj.endDate);
        let reportType = this.reportData.reportType;

        // apply date validation
        if(startDate != '' && endDate != ''){
            // convert dates into miliseconds for check difference
             var startDateMs = moment(startDate).valueOf();
             var endDateMs = moment(endDate).valueOf();
             let dateDiff:any = endDateMs-startDateMs;//calculate difference
             if(parseInt(dateDiff) < 1){
                 this.newCheckDateDiff = true;return;
             }else{
                 this.newCheckDateDiff = false;
             }
         }
        
        if(startDate == '' && endDate == '' && (reportType == 0 || reportType == "" || reportType == null))
        {
            $('#start_date_err').css('display', 'block');
            $('#end_date_err').css('display', 'block');
            $('#report_type_err').css('display', 'block');
            return;
        }
        else 
        {
            $('#start_date_err').css('display', 'none');
            $('#end_date_err').css('display', 'none');
            $('#report_type_err').css('display', 'none');
        }

        if(startDate == '' && endDate == '')
        {
            $('#start_date_err').css('display', 'block');
            $('#end_date_err').css('display', 'block');
            return;
        }
        else
        {
            $('#start_date_err').css('display', 'none');
            $('#end_date_err').css('display', 'none');
        }

        if(startDate == '')
        {
            $('#start_date_err').css('display', 'block');
            return;
        }
        else 
        {
            $('#start_date_err').css('display', 'none');
        }

        if(endDate == '')
        {
            $('#end_date_err').css('display', 'block');
            return;
        }
        else 
        {
            $('#end_date_err').css('display', 'none');
        }

        if(reportType == 0 || reportType == "" || reportType == null)
        {
            $('#report_type_err').css('display', 'block');
            return;
        }
        else
        {
            $('#report_type_err').css('display', 'none');
        }

        // for (var siteId in this.checkSiteArray) {
        //    this.resultForSite.push({
        //       siteId: this.checkSiteArray[siteId],
        //     })
        // }
        // for (var userId in this.checkUserArray) {
        //     this.resultForUser.push({
        //        userId: this.checkUserArray[userId],
        //     })
        // }
        // for (var customerId in this.checkCustomerArray) {
        //     this.resultForCustomer.push({
        //       customerId: this.checkCustomerArray[customerId],
        //     })
        // }

        for (var assetId in this.checkAssetArray) {
            this.resultForAsset.push({
              assetId: this.checkAssetArray[assetId],
            })
        }

        // this.createTierArray();
        
        this.reportData.users       = this.resultForUser;
        this.reportData.assets      = this.resultForAsset;
        this.reportData.customers   = this.resultForCustomer;
        this.reportData.sites       = this.resultForSite;
        this.reportData.tiers       = this.tiersArray;
        this.reportData.name        = this.addFormData.name;
        this.reportData.startDate   = startDate;
        this.reportData.endDate     = endDate;
        this.reportData.description = this.addFormData.description;
        this.reportData.isActive    = this.addFormData.isActive;
        // this.reportData.reportTime  = $('#reportTime').val();
        this.reportData.format = this.reportData.fileFormat;

        if (this.radioSelected == 'OnDemand') {
            this.reportData.frequencyType = "OnDemand";

        } else if (this.radioSelected == 'Daily') {
            this.reportData.frequencyType = "Daily";
            this.reportData.frequencyStep = this.addFormData.frequencyStep;

        } else if (this.radioSelected == 'Weekly') {

            this.reportData.frequencyType = "Weekly";
            this.reportData.frequencyStep = this.addFormData.frequencyStep;

            if (this.recurringPattern.isSunday == true) {
                // this.weekdayMapData = this.weekdayMapData + 64;
                this.weekdayMapData = this.weekdayMapData + 1;
            }
            if (this.recurringPattern.isMonday == true) {
                // this.weekdayMapData = this.weekdayMapData + 1;
                this.weekdayMapData = this.weekdayMapData + 2;
            }
            if (this.recurringPattern.isTuesday == true) {
                // this.weekdayMapData = this.weekdayMapData + 2;
                this.weekdayMapData = this.weekdayMapData + 4;
            }
            if (this.recurringPattern.isWednesday == true) {
                // this.weekdayMapData = this.weekdayMapData + 4;
                this.weekdayMapData = this.weekdayMapData + 8;
            }
            if (this.recurringPattern.isThursday == true) {
                // this.weekdayMapData = this.weekdayMapData + 8;
                this.weekdayMapData = this.weekdayMapData + 16;
            }
            if (this.recurringPattern.isFriday == true) {
                // this.weekdayMapData = this.weekdayMapData + 16;
                this.weekdayMapData = this.weekdayMapData + 32;
            }
            if (this.recurringPattern.isSaturday == true) {
                // this.weekdayMapData = this.weekdayMapData + 32;
                this.weekdayMapData = this.weekdayMapData + 64;
            }

            this.reportData.weekdayMap = this.weekdayMapData;

        } else if (this.radioSelected == 'Monthly') {
            this.reportData.frequencyType = "Monthly";
            if (this.monthlyRadioSelected == 'monthFirst') {
                this.reportData.dateOfMonth = this.addFormData.dateOfMonth;
                this.reportData.frequencyStep = this.addFormData.frequencyStep;
            } else {
                this.reportData.weekOfMonth = this.addFormData.weekOfMonth;
                this.reportData.weekdayOfMonth = this.addFormData.weekDayOfMonth;
                this.reportData.dateOfMonth = this.addFormData.dateOfMonth;
            }
        } else if (this.radioSelected == 'Yearly') {
            this.reportData.frequencyType = "Yearly";
            if (this.YearlyRadioSelected == 'yearFirst') {
                this.reportData.monthOfYear = this.addFormData.monthOfYear;
                this.reportData.frequencyStep = this.addFormData.frequencyStep;
            } else {
                this.reportData.weekdayOfMonth = this.addFormData.weekDayOfMonth;
                this.reportData.weekOfMonth = this.addFormData.weekOfMonth;
                this.reportData.monthOfYear = this.addFormData.monthOfYear;
            }
        }
        this.addReportDataList();
        // if(moment(startDate).isBefore(endDate)){
        //     this.addReportDataList();
        // }
        // else{ 
        //     this.checkDateDiff = true;
        // }
        // this.addReportDataList();
    }

    getAssetSummaryPreviewReport() {
        this.activeTab = 'summaryTab';
        // $('#summary_table').css('display', 'block');
        // $('#detail_table').css('display', 'none');
        //this.previewReport();
        // this.initializeSummaryDataTable();
    }

    getAssetDetailPreviewReport() {
     //  call detail report only if all mandatory fields are filled up
     if(this.callDetailReport){
        this.activeTab = 'detailTab';
        this.detailPreviewData = [];
        let startDate = this.newDateFormat(this.filterObj.startDate);
        let endDate = this.newDateFormat(this.filterObj.endDate);
        let reportType = this.reportData.reportType;
        let fileFormat = this.reportData.fileFormat;
        this.tierIdForApi = this.checkTreeArray.join();

        var URL = 'customer/report/getAdhocPreviewAssetDetailReport';

        let apiData: any = {
            clientId: this.clientId,
            customerid: this.customerId,
            startDate: startDate,
            endDate: endDate,
            reportType: reportType,
            startLimit: 0,
             endLimit: 1500,
        }

        if (this.assetIdForApi != null && this.assetIdForApi != '') {
            apiData.assetIds = this.assetIdForApi.toString();
           // var assetString = '&assetId=' + this.assetIdForApi.toString();
           // var URL = URL.concat(assetString);
        }
        //added as per requirement on 4 november 2019
        
        // if (this.customerIdForApi != null && this.customerIdForApi != '') {
        //     var customerString = '&customerId=' + this.customerIdForApi.toString();
        //     var URL = URL.concat(customerString);
        // }
       
        
        if ($.fn.DataTable.isDataTable('#detail_table')){
          $('#detail_table').DataTable().destroy();
          this.detailPreviewData=[];
        }
        this.apiService.create(URL, apiData).subscribe(
              data => {
                  if (data.statusCode == 100) {
                      this.detailPreviewData=[];
                      this.detailPreviewData = data.responseList;
                      this.chRef.detectChanges();
                      if ($.fn.DataTable.isDataTable('#detail_table')){
                        $('#detail_table').DataTable().destroy();
                      };
                      setTimeout(function() {
                        const table: any = $('#detail_table');
                        this.dataTable   = table.DataTable();
                      }, 500);
                  }
                  else
                    { 
                        this.detailPreviewData = [];
                        if ($.fn.DataTable.isDataTable('#detail_table')){
                          $('#detail_table').DataTable().destroy();
                        };
                        const table: any = $('#detail_table');
                        this.chRef.detectChanges();
                        this.dataTable = table.DataTable();
                    }
              },
             error =>{
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
     }
    }

  // Preview Report
    // previewReport() {
    //     this.reportData = [];
    //     if ($.fn.DataTable.isDataTable('#report_table')){
    //         $('#report_table').DataTable().destroy();
    //         this.reportData=[];
    //     }
    //     let startDate = $('#startDate').val();
    //     let endDate = $('#endDate').val();
    //     let reportType = $('#reportType').val();
    //     this.tierIdForApi = this.checkTreeArray.join();

    //     // validation starts here
    //     if(startDate == '' && endDate == '' && this.checkTreeArray.length == 0)
    //     {
    //         $('#start_date_err').css('display', 'block');
    //         $('#end_date_err').css('display', 'block');
    //         $('#tier_err').css('display', 'block');
    //         return;
    //     }
    //     else 
    //     {
    //         $('#start_date_err').css('display', 'none');
    //         $('#end_date_err').css('display', 'none');
    //         $('#tier_err').css('display', 'none');
    //     }

    //     if(startDate == '' && endDate == '')
    //     {
    //         $('#start_date_err').css('display', 'block');
    //         $('#end_date_err').css('display', 'block');
    //         return;
    //     }
    //     else
    //     {
    //         $('#start_date_err').css('display', 'none');
    //         $('#end_date_err').css('display', 'none');
    //     }

    //      if(startDate == '' && this.checkTreeArray.length == 0)
    //     {
    //         $('#start_date_err').css('display', 'block');
    //         $('#tier_err').css('display', 'block');
    //         return;
    //     }
    //     else 
    //     {
    //         $('#start_date_err').css('display', 'none');
    //         $('#tier_err').css('display', 'none');
    //     }

    //     if(endDate == '' && this.checkTreeArray.length == 0)
    //     {
    //         $('#end_date_err').css('display', 'block');
    //         $('#tier_err').css('display', 'block');
    //         return;
    //     }
    //     else 
    //     {
    //         $('#end_date_err').css('display', 'none');
    //         $('#tier_err').css('display', 'none');
    //     }

    //     if(startDate == '')
    //     {
    //         $('#start_date_err').css('display', 'block');
    //         return;
    //     }
    //     else 
    //     {
    //         $('#start_date_err').css('display', 'none');
    //     }

    //     if(endDate == '')
    //     {
    //         $('#end_date_err').css('display', 'block');
    //         return;
    //     }
    //     else 
    //     {
    //         $('#end_date_err').css('display', 'none');
    //     }

    //     if(this.checkTreeArray.length == 0)
    //     {
    //         $('#tier_err').css('display', 'block');
    //         return;
    //     }
    //     else
    //     {
    //         $('#tier_err').css('display', 'none');
    //     }

    //     if((reportType == 0) || reportType == null)
    //     {
    //         $('#report_type_err').css('display', 'block');
    //         return;
    //     }
    //     else
    //     {
    //         $('#report_type_err').css('display', 'none');
    //     }
    //     // validation ends here


    //     // var URL = 'report/getAdhocPreviewAssetSummaryReport?startDate=2019-06-12&endDate=2019-08-19&reportType=2&clientId=5&tierId=10,11,15,95,16,17,18,12,13,14';
    //     //   var URL = 'report/getAdhocPreviewAssetDetailReport?startDate='+startDate+'&endDate='+endDate+'&reportType='+reportType+'&clientId='+this.clientId+'&tierId='+this.tierIdForApi;
    //     //changed on 4 november 2019
    //     var URL = 'report/getAdhocPreviewAssetSummaryReport?startDate='+startDate+'&endDate='+endDate+'&reportType='+reportType+'&clientId='+this.clientId+'&tierId='+this.tierIdForApi;
    //     //added as per requirement on 4 november 2019
    //     // if (this.tierIdForApi != null && this.tierIdForApi != '') {
    //     //     var tierString = '&tierId=' + this.tierIdForApi.toString();
    //     //     var URL = URL.concat(tierString);
    //     // }
    //     if (this.customerIdForApi != null && this.customerIdForApi != '') {
    //         var customerString = '&customerId=' + this.customerIdForApi.toString();
    //         var URL = URL.concat(customerString);
    //     }
    //     if (this.siteIdForApi != null && this.siteIdForApi != '') {
    //         var siteString = '&siteId=' + this.siteIdForApi.toString();
    //         var URL = URL.concat(siteString);
    //     }
    //     if (this.assetIdForApi != null && this.assetIdForApi != '') {
    //         var assetString = '&assetId=' + this.assetIdForApi.toString();
    //         var URL = URL.concat(assetString);
    //     }

    //     this.apiService.getAll(URL).subscribe(
    //       data => {
    //           if (data.statusCode == 100) {
    //             // this.preivewData = data.responseList;
    //             // $('.previewReportBtn').click();  
    //             this.reportData = [];
    //             this.reportData = data.responseList;
    //             this.chRef.detectChanges();
    //             if ($.fn.DataTable.isDataTable('#report_table')){
    //                 $('#report_table').DataTable().destroy();
    //             };
    //             const table: any = $('#report_table');
    //             this.dataTable   = table.DataTable();   
    //           }
    //           else{ 
    //             this.alertService.sweetMessage('error',"No Data Found"); 
    //             this.reportData = [];
    //             if ($.fn.DataTable.isDataTable('#report_table')){
    //                 $('#report_table').DataTable().destroy();
    //             };
    //             const table: any = $('#report_table');
    //             this.chRef.detectChanges();
    //             this.dataTable = table.DataTable();
    //           }
    //       },
    //       error =>{
    //                        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    //       }
    //     );
    // }

      // Preview Report
    
    previewReport() {
        this.preivewData = [];
        let startDate = this.newDateFormat(this.filterObj.startDate);
        let endDate = this.newDateFormat(this.filterObj.endDate);
        // let reportType = $('#reportType').val();
        let reportType = this.reportData.reportType;
        this.tierIdForApi = this.checkTreeArray.join();

        // apply date validation
        if(startDate != '' && endDate != ''){
            // convert dates into miliseconds for check difference
             var startDateMs = moment(startDate).valueOf();
             var endDateMs = moment(endDate).valueOf();
             let dateDiff:any = endDateMs-startDateMs;//calculate difference
             if(parseInt(dateDiff) < 1){
                 this.newCheckDateDiff = true;return;
             }else{
                 this.newCheckDateDiff = false;
             }
         }
        
        // validation starts here
        if(startDate == '' && endDate == '' && (reportType == 0 || reportType == ""))
        {
            $('#start_date_err').css('display', 'block');
            $('#end_date_err').css('display', 'block');
            $('#report_type_err').css('display', 'block');
            return;
        }
        else 
        {
            $('#start_date_err').css('display', 'none');
            $('#end_date_err').css('display', 'none');
            $('#report_type_err').css('display', 'none');
        }

        if(startDate == '' && endDate == '')
        {
            $('#start_date_err').css('display', 'block');
            $('#end_date_err').css('display', 'block');
            return;
        }
        else
        {
            $('#start_date_err').css('display', 'none');
            $('#end_date_err').css('display', 'none');
        }

        if(startDate == '')
        {
            $('#start_date_err').css('display', 'block');
            return;
        }
        else 
        {
            $('#start_date_err').css('display', 'none');
        }

        if(endDate == '')
        {
            $('#end_date_err').css('display', 'block');
            return;
        }
        else 
        {
            $('#end_date_err').css('display', 'none');
        }

        if((reportType == 0) || reportType == null || reportType == "")
        {
            $('#report_type_err').css('display', 'block');
            return;
        }
        else
        {
            $('#report_type_err').css('display', 'none');
        }

        if( reportType != 0 && reportType != null && startDate != '' && endDate != '' ){
            this.callDetailReport = true;
        }
        // validation ends here

        // show table 
        this.showTable = true;
        if(this.callDetailReport){
         if(this.reportData.reportType=='1'){
            this.showRootCauseColum = true;
         }else{
            this.showRootCauseColum = false;
         }
         this.getAssetDetailPreviewReport();

         //changed on 4 november 2019
         var URL = 'customer/report/getAdhocPreviewAssetSummaryReport';
         //added as per requirement on 4 november 2019
         let apiData: any = {
             clientId: this.clientId,
             customerid: this.customerId,
             startDate: startDate,
             endDate: endDate,
             reportType: reportType,
             startLimit: 0,
             endLimit: 1500,
         }

         if (this.assetIdForApi != null && this.assetIdForApi != '') {
             apiData.assetIds = this.assetIdForApi.toString();
            // var assetString = '&assetId=' + this.assetIdForApi.toString();
            // var URL = URL.concat(assetString);
         }

         if ($.fn.DataTable.isDataTable('#summary_table')){
            $('#summary_table').DataTable().destroy();
            this.preivewData=[];
         }
         this.apiService.create(URL, apiData).subscribe(
            data => {
              if (data.statusCode == 100) {
                $('#no-record-found').css('display', 'none');
                this.preivewData = [];
                this.preivewData = data.responseList;
                this.chRef.detectChanges();
                if ($.fn.DataTable.isDataTable('#summary_table')){
                  $('#summary_table').DataTable().destroy();
                };
                const table: any = $('#summary_table');
                this.dataTable = table.DataTable();
              }
              else
                {
                    $('#no-record-found').css('display', 'block');
                    this.preivewData = [];
                    if ($.fn.DataTable.isDataTable('#summary_table')){
                      $('#summary_table').DataTable().destroy();
                    };
                    const table: any = $('#summary_table');
                    this.chRef.detectChanges();
                    this.dataTable = table.DataTable();
                }
            },
            error =>{
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
         });
        }
    }

    // get tier List
    getTierList(type) {
        this.selectAllTier = false;
        this.checkTreeArray = []
        this.siteList = []
        this.selectedSite = [];
        this.assetDatalist = [];
        this.selectedAsset = [];
        this.data = [];
        var URL = 'tier/getAllTierByUser?clientId=' + this.clientId + '&userId=' + this.userId;
        if (this.customerId != null && this.customerId != '') {
            var customerString = '&customerId=' + this.customerId.toString();
            var URL = URL.concat(customerString);
        }
        if (type.value != 0 || type.value != '0') {
            URL +="&type="+type.value
        }

        this.apiService.getAll(URL).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.data = data.responseList;
                    // Object.keys(this.data).forEach(key => {
                    //     if (this.data[key]['flag'] == false) {
                    //         this.disableTreeArray.push(this.data[key]['tierId']);
                    //     }
                    // });
                    for (let object of this.data) {
                        if(!object.flag){
                          this.disableTreeArray.push(object.tierId.toString());
                        }
                    }
                    // disable tree length
                    this.getEnableDisableTier()

                    this.source = {
                        datatype: 'json',
                        datafields: [{
                            name: 'tierId'
                        }, {
                            name: 'parentTierId'
                        }, {
                            name: 'tierName'
                        }],
                        id: 'tierId',
                        localdata: this.data
                    };
                    this.dataAdapter = new jqx.dataAdapter(this.source, {
                        autoBind: true
                    });
                    this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [{
                        name: 'tierName',
                        map: 'label'
                    }, {
                        name: 'tierId',
                        map: 'id'
                    }]);
                    var self = this;
                    setTimeout(function() {
                        self.treeItemDisable();
                    }, 500);
                }
            },
            error =>{
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    // Function to disable items
    treeItemDisable() {
        Object.keys(this.disableTreeArray).forEach(key => {
            let checkedItem = document.getElementById(this.disableTreeArray[key]);
            this.myTree.expandItem(checkedItem);
            this.myTree.disableItem(checkedItem);
        });
    }

    /* Handle tier tree chacked unchecked Event */
    CheckChange(event) {
        if (event.args.checked == true) {
            var exist = this.checkTreeArray.indexOf(event.args.element.id);
            if (exist == '-1') {
                this.checkTreeArray.push(event.args.element.id);
                setTimeout(() => {
                    this.getUserListData();
                    this.getSiteManagementList();
                    this.getCustomerList();
                    this.getAllAssetList();
                }, 100);
                // localStorage.setItem("tierId", JSON.stringify(this.checkTreeArray));
            }
        } else if (event.args.checked == false || event.args.checked == null) {
            var exist = this.checkTreeArray.indexOf(event.args.element.id);
            if (exist != '-1') {
                this.checkTreeArray.splice(exist, 1);
                setTimeout(() => {
                    this.getUserListData();
                    this.getSiteManagementList();
                    this.getCustomerList();
                    this.getAllAssetList();
                }, 100);
                // localStorage.setItem("tierId", JSON.stringify(this.checkTreeArray));
            }
        }
        if (this.checkTreeArray.length == 0) {
            this.dropdownSettingsSite.placeholder = "Select a tier first" 
            this.dropdownSettings.placeholder = "Select a tier first" 
            this.dropdownSettingsAsset.placeholder = "Select a tier first" 
        }else{
            this.dropdownSettingsSite.placeholder = "Select Site" 
            this.dropdownSettings.placeholder = "Select Customer" 
            this.dropdownSettingsAsset.placeholder = "Select Asset" 
        }
        
        // if (this.data.length == this.checkTreeArray.length) {
        //     this.selectAllTier = true            
        // }else{
        //     this.selectAllTier = false
        // }
        if(this.disableTreeLength==0) {
            if (this.data.length == this.checkTreeArray.length) {
              this.selectAllTier = true            
            }else{
              this.selectAllTier = false
            }
        }else{
            let actualLength = this.data.length-this.disableTreeLength;
            if (actualLength == this.checkTreeArray.length) {
              this.selectAllTier = true            
            }else{
              this.selectAllTier = false
            }
            // console.log("this.selectAllTier--",this.selectAllTier)
        }
    }

    // Getting User List Data
    getUserListData() {
        this.tierIdForApi = this.checkTreeArray.join();
        // var URL = 'public/user/getUserClientByClient?clientId=' + this.clientId + '&userId=' + this.userId
        var URL = 'user/getUserClientByClient?clientId=' + this.clientId + '&userId=' + this.userId;

        if (this.tierIdForApi != null && this.tierIdForApi != '') {
            var tierString = '&tierId=' + this.tierIdForApi.toString();
            var URL = URL.concat(tierString);
        }
        if (this.customerIdForApi != null && this.customerIdForApi != '') {
            var customerString = '&customerId=' + this.customerIdForApi.toString();
            var URL = URL.concat(customerString);
        }
        if (this.siteIdForApi != null && this.siteIdForApi != '') {
            var siteString = '&siteId=' + this.siteIdForApi.toString();
            var URL = URL.concat(siteString);
        }

        this.apiService.getAll(URL).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.afterEditData = JSON.parse(JSON.stringify(data.userList));
                    this.userListDataForTable1 = [];
                    this.userListDataForTable = [];
                    this.userListDataForTable1 = data.userList;
                    this.userListDataForTable = this.userListDataForTable.concat(data.userList);
                } else {
                    this.userListDataForTable1 = [];
                    this.userListDataForTable = [];
                }
            },
           error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    // Function to show table Info
    showTableInfo(data) {
        var opbj = JSON.parse(JSON.stringify(data));
        if(Array.isArray(opbj) && opbj.length == 1){
            var opbj = opbj[0];
        }
        if (opbj && Array.isArray(opbj) && opbj.length > 0) {
            for (var i = 0; i < opbj.length; i++) {
                this.userListTableData.push(this.userListDataForTable[i]);
            }
        }else{
            for(var i = 0; i < this.userListDataForTable.length; i++) {
                if(this.userListDataForTable[i]['userId'] === opbj) {
                    this.userListTableData.push(this.userListDataForTable[i]);
                }
            }
        }
    }

    // Function to remove table Info
    removeTableInfo(data) {
        this.userListTableData.splice(_.indexOf(this.userListTableData, _.find(this.userListTableData, function(item) {
            return item.userId === data;
        })), 1);
    }

    // Function to add Report Data
    addReportDataList() {
        this.tierIdForApi = this.checkTreeArray.join();
        this.assetIdForApi = this.checkAssetArray.join();

        var URL = 'customer/report/getAdhocBasicAssetSummaryReport';
        let apiData: any = {
            clientId: this.clientId,
            customerid: this.customerId,
            startDate: this.reportData.startDate,
            endDate: this.reportData.endDate,
            reportType: this.reportData.reportType,
            format: this.reportData.format
        }

        if (this.assetIdForApi != null && this.assetIdForApi != '') {
            apiData.assetIds = this.assetIdForApi.toString();
           // var assetString = '&assetId=' + this.assetIdForApi.toString();
           // var URL = URL.concat(assetString);
        }

         if (this.reportId == 0) {
            let startDate = this.newDateFormat(this.filterObj.startDate);
            let endDate = this.newDateFormat(this.filterObj.endDate);
            let reportType = this.reportData.reportType;
           
            var URLPreview = 'customer/report/getAdhocPreviewAssetDetailReport';
            let apiPreviewData: any = {
                clientId: this.clientId,
                customerid: this.customerId,
                startDate: startDate,
                endDate: endDate,
                reportType: reportType,
                startLimit: 0,
                endLimit: 1500,
                
            }

            this.apiService.create(URLPreview, apiPreviewData).subscribe(
                  data => {
                      if (data.statusCode == 100) {
                          this.apiService.downloadFile(URL, apiData).subscribe(
                            (data: any) => {
                                let resData = JSON.stringify(data)
                                var blob = new Blob([data._body], {type: 'application/octet-stream'});
                                this.excelFile = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
                                setTimeout(() => {
                                  var te = document.getElementById('downloadReportBtn');
                                  te.click();            
                                }, 2000);
                              },
                            error =>{
                                this.alertService.sweetMessage('error',data.responseMessage);
                                // var mainUrl = this.apiService.baseBath+'/'+URL;
                                // $("#downloadReportBtn").attr("href", mainUrl);
                                // var te = document.getElementById('downloadReportBtn');
                                // te.click();
                                // NProgress.done();
                                // $("#loaderImgIcon").css("display","none");
                            }
                        );   
                      }
                      else
                        { this.alertService.sweetMessage('error',"Something went wrong, please try again "); }
                  },
                 error =>{
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                     }
              );

             
        } else {
            this.reportData.reportId = this.reportId;
            this.apiService.update('report/updateReport', this.reportData).subscribe(
                data => {
                    if (data.statusCode == 100) {
                        Swal({
                            position: 'top-end',
                            type: 'success',
                            title: 'Report Details Updated successfully',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.router.navigate(['/report']);
                    } else {
                        this.alertService.sweetMessage('error',data.responseMessage);
                    }
                },
                error =>{
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
            );
        }
    }

    // getting report by Id
    getReportById() {
        this.apiService.getAll('report/getReportById?reportId=' + this.reportId).subscribe(
            data => {
                    if (data.statusCode == 100) {
                        this.getSiteManagementList();
                        this.getCustomerList();
                        // this.getTierList({value:1});
                        this.getAllAssetList();
                    this.addFormData = data.response;
                    this.user = data.response;
                    this.reportData.reportType = data.response.reportType;
                    this.radioSelected = data.response.frequencyType;
                    //this.checkItemCommunication();
                    if (data.response.frequencyType == 'Monthly') {
                        if (!data.response.weekdayOfMonth) {
                            this.addFormData.dateOfMonth = data.response.dateOfMonth;
                            this.addFormData.frequencyStep = data.response.frequencyStep;
                        } else {
                            this.addFormData.weekOfMonth = data.response.weekOfMonth;
                            this.addFormData.weekdayOfMonth = data.response.weekdayOfMonth;
                            this.addFormData.dateOfMonth = data.response.dateOfMonth;
                        }
                    }
                    if (data.response.frequencyType == 'Yearly') {
                        if (!data.response.weekOfMonth) {
                            this.addFormData.monthOfYear = data.response.monthOfYear;
                            this.addFormData.frequencyStep = data.response.frequencyStep;
                        } else {
                            this.addFormData.weekdayOfMonth = data.response.weekdayOfMonth;
                            this.addFormData.weekOfMonth = data.response.weekOfMonth;
                            this.addFormData.monthOfYear = data.response.monthOfYear;
                        }
                    }

                    this.checkDataForchecked = data.response.weekdayMap;
                    if (this.checkDataForchecked >= 64) {
                        this.checkDataForchecked = this.checkDataForchecked - 64;
                        this.recurringPattern.isSunday = true;
                    }
                    if (this.checkDataForchecked >= 32) {
                        this.checkDataForchecked = this.checkDataForchecked - 32;
                        this.recurringPattern.isSaturday = true;
                    }
                    if (this.checkDataForchecked >= 16) {
                        this.checkDataForchecked = this.checkDataForchecked - 16;
                        this.recurringPattern.isFriday = true;
                    }
                    if (this.checkDataForchecked >= 8) {
                        this.checkDataForchecked - 8;
                        this.recurringPattern.isThursday = true;
                    }
                    if (this.checkDataForchecked >= 4) {
                        this.checkDataForchecked = this.checkDataForchecked - 4;
                        this.recurringPattern.isWednesday = true;
                    }
                    if (this.checkDataForchecked >= 2) {
                        this.checkDataForchecked = this.checkDataForchecked - 2;
                        this.recurringPattern.isTuesday = true;
                    }
                    if (this.checkDataForchecked >= 1) {
                        this.checkDataForchecked = this.checkDataForchecked - 1;
                        this.recurringPattern.isMonday = true;
                    }

                    for (let object of this.addFormData.tiers) {
                        this.checkTreeArray.push(object.tierId.toString());
                    }

                    var self = this;
                      setTimeout(function(){
                    self.checkedCheckboxOnLoad();
                      },4000);

                    for (let object of this.addFormData.sites) {
                        this.selectedsite.push({
                            'siteId': object.siteId
                        });
                        this.checkSiteArray.push(object.siteId.toString());
                    }

                    for (let object of this.addFormData.assets) {
                        this.selectedasset.push({
                            'assetId': object.assetId
                        });
                        this.checkAssetArray.push(object.assetId.toString());
                    }

                    for (let object of this.addFormData.customers) {
                        this.selectedcustomer.push({
                            'customerId': object.customerId
                        });
                        this.checkCustomerArray.push(object.customerId.toString());
                    }
                    if(this.addFormData.users){
                    if(this.addFormData.users.length == 1){
                        this.userListDataForTable.push(this.addFormData.users);
                        this.userListDataForTable1.push({'userId': this.addFormData.users[0]['userId']});
                        this.checkUserArray.push(this.addFormData.users[0]['userId']);
                    }else{
                        for (let object of this.addFormData.users) {
                            this.userListDataForTable1.push({
                                'userId': object['userId']
                            });
                            this.checkUserArray.push(object['userId'].toString());
                            this.userListDataForTable.push(object);
                        }
                        }
                    }
                    this.showTableInfo(this.checkUserArray);
                    this.customerIdForApi = this.checkCustomerArray.join();
                    this.siteIdForApi = this.checkSiteArray.join();
                    this.assetIdForApi = this.checkAssetArray.join();
                    this.selectedItems = this.selectedcustomer;
                    this.selectedSite = this.selectedsite;
                    this.selectedAsset = this.selectedasset;
                    this.selectedUser = this.userListDataForTable1;
                    this.getUserListData();
                    if (this.data.length == this.checkTreeArray.length) {
                        this.selectAllTier = true            
                    }else{
                        this.selectAllTier = false
                    }
                } else {
                    this.alertService.sweetMessage('error',data.responseMessage);
                }
            },
           error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );

    }

    // checked checkbox on load of tree
    checkedCheckboxOnLoad() {
        Object.keys(this.checkTreeArray).forEach(key => {
            let checkedItem = document.getElementById(this.checkTreeArray[key]);
            this.myTree.expandItem(checkedItem);
            this.myTree.checkItem(checkedItem, true);
        });
    }

    // Getting Asset List
    getAllAssetList() {
        this.assetDatalist = [];
        this.selectedAsset = [];
        this.checkAssetArray = [];
        this.assetIdForApi = "";
        var URL = 'customer/asset/getAssetDropDownByClient';
        let apiData: any = {
            clientId: this.clientId,
            customerId: [this.customerId]
          }
        // if (this.customerId != null && this.customerId != '') {
        //     var customerString = '&customerId=' + this.customerId.toString();
        //     var URL = URL.concat(customerString);
        // }
        // if (this.tierId != null && this.tierId != '') {
        //     var tierString = '&tierId=' + this.tierId.toString();
        //     var URL = URL.concat(tierString);
        // }
        // if (this.siteIdForApi != null && this.siteIdForApi != '') {
        //     var siteString = '&siteId=' + this.siteIdForApi.toString();
        //     var URL = URL.concat(siteString);
        // }
        // if (this.tierIdForApi != null && this.tierIdForApi != '') {
        //     var tierString = '&tierId=' + this.tierIdForApi.toString();
        //     var URL = URL.concat(tierString);
        // }
        // if (this.checkSiteArray.length != 0) {
        //     this.site = this.checkSiteArray.toString();
        //     var siteString = '&siteId=' + this.site;
        //     var URL = URL.concat(siteString);
        // }
        // if (this.checkTreeArray.length == 0) {    
        //     this.assetDatalist = [];
        //     this.selectedAsset = [];
        // }else {
            this.apiService.create(URL, apiData).subscribe(
                data => {
                    if (data.statusCode == 100) {
                        // if(this.checkTreeArray.length != 0) {
                            this.assetDatalist = data.responseList;
                            let tempSelected = [];
                            this.selectedAsset.map((obj, index)=>{
                                let findObj = _.find(this.assetDatalist, { assetId: obj.assetId});
                                // console.log(findObj)
                                if (findObj) {
                                    tempSelected.push(obj);
                                }
                            })
                            this.selectedAsset = tempSelected;
                        // }                        
                    } else {
                        this.assetDatalist = [];
                        this.selectedAsset = [];
                    }
                },
                error =>{
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                }
            );
        // }
    }

    selectAllTree(event){
        // console.log(event.target.chacked);
        if (event.target.checked) {
            for (let object of this.data) {
              if(object.flag){
                this.checkTreeArray.push(object.tierId.toString());
              }
            }
            this.checkedCheckboxOnLoad()
        }else{
            this.checkTreeArray = []
            this.siteList = []
            this.selectedSite = [];
            this.assetDatalist = [];
            this.selectedAsset = [];
            this.checkedCheckboxOnLoad()

            for (let object of this.data) {
                let checkedItem = document.getElementById(object.tierId.toString());
                this.myTree.expandItem(checkedItem);
                this.myTree.checkItem(checkedItem, false);
            }
        }
        setTimeout(() => {
            this.getUserListData();
            this.getSiteManagementList();
            this.getCustomerList();
            this.getAllAssetList();
        }, 100);
        
    }

    // new Function to format Date
    newDateFormat(dateStr){
     if(dateStr){
        // return moment(dateStr).format("YYYY-MM-DD HH:mm:ss");
        var dateTime = new Date(dateStr);
        return moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
     }else{
        return "";
     }
    }

    // changeReport(){
    //     this.checkedCheckboxOnLoad()
    // }

    // Email Report
    emailReport() {
      this.emailObj={};
      this.emailObj.type = 'AdhocRuntimeReport';
      this.emailObj.clientId = this.clientId;

      if(this.preivewData.length!=0 && this.detailPreviewData.length!=0){
        let startDate = this.newDateFormat(this.filterObj.startDate);
        let endDate = this.newDateFormat(this.filterObj.endDate);
        let reportType = this.reportData.reportType;
        let fileFormat = this.reportData.fileFormat;

        this.emailObj.format = fileFormat;
        this.emailObj.startDate = startDate;
        this.emailObj.endDate = endDate;
        this.emailObj.reportType = reportType;

        var URL = 'customer/report/sendAdhocSummaryReportEmail';
        
        if (this.assetIdForApi != null && this.assetIdForApi != '') {
            this.emailObj.assetIds = this.assetIdForApi.toString();
        }

        if(this.emailIdArr.length > 0){
            this.emailObj.emailIds = this.emailIdArr;      
        }

        if(this.emailBodyMsg!=""){
            this.emailObj.emailBodyMessage = this.emailBodyMsg;
        }
        // console.log('request', this.emailObj);
        this.apiService.create(URL, this.emailObj).subscribe(
            data => {
                if (data.statusCode == 100) {
                  $("#closeModal").click();
                  this.resetModalData();
                  this.alertService.sweetMessage('success',"Report has been sent");
                }
                else
                { 
                  this.alertService.sweetMessage('error', data.responseMessage);
                }
            },
            error =>{
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        });
      }
    }

   // verify email validations
   emailVerify() {
    this.emailBodyMsg = "";
    let allEmailValid = false;
    if(this.emailData.emails!=""){
      this.emailArray  = this.emailData.emails.split(',');
      if((this.emailArray.length != 0) && (this.emailArray.length > 0)){
        let emailExp = new RegExp("[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\\.[a-zA-Z]{2,3}$")
        this.emailArray.forEach((data,index) => {
          // console.log("data=======",data,'++++++++++',emailExp.test(data))
          if (!emailExp.test(data)) {
            this.showValidEmailErr = true
            allEmailValid = true
          }
        })
      }
    }

    // check for email required
    if(this.emailData.emails) {
      this.showEmailErr = false;
    }else {
      this.showEmailErr = true;
      this.showValidEmailErr = false;
    }

    // check for valid email id
    if (allEmailValid) {
      this.showValidEmailErr = true
    }else {
      this.showValidEmailErr = false
    }  

    // check for msg
    // if (this.emailData.message) {
    //   this.showMsgErr = false
    // }else {
    //   this.showMsgErr = true
    // }

    if ((!this.showEmailErr) && (!this.showValidEmailErr)) {
        this.emailBodyMsg = this.emailData.message;
        if(this.emailArray.length > 0){
          this.emailIdArr=[];
          this.emailArray.forEach((data,index) => {
            var exist = this.emailIdArr.indexOf(data.trim());
            if (exist == '-1') {
              this.emailIdArr.push(data.trim());
            }
          });
          console.log("emailIdArr---", this.emailIdArr)
        } 
        if(this.emailBodyMsg!=""){
          this.emailBodyMsg = this.emailBodyMsg.replace(new RegExp('\n', 'g'), "<br/>");
        }
        this.emailReport();
      }
  }

  resetModalData() {
    this.emailData = {
      emails:'',
      message:''
    };
    this.emailArray = [];
    this.showEmailErr = false;  
    this.showValidEmailErr = false;
    // this.showMsgErr = false;
  }

//   checkActiveTab(){
//     var activeTab = $(".active_nav_date").find(".active");
//     var id = activeTab.attr('id');
//     if(id!="" && id!=null){
//         this.activeTab = id;
//     }
//   }


  getAssetDetailPreviewReportData() {
    this.activeTab = 'detailTab';
    // $('#summary_table').css('display', 'none');
    // $('#detail_table').css('display', 'block');
    // this.initializeDetailSummaryDataTable();
  }

  //setting FlatPicker Property
  setFlatPicker() {
    var self = this;
    //faltpicke config for end Date
    flatpickr('#endDate',{
      enableTime: true,
      enableSeconds : true,
      maxDate: new Date(),
    //   dateFormat: "d-m-Y H:i:S",
      dateFormat: "m-d-Y H:i:S",
      defaultDate:new Date(), 
      time_24hr : true,  
      onChange: function(selectedDates, dateStr, instance) {
        self.filterObj.endDate = selectedDates;
      },      
    });

    //faltpicke config for start Date
    flatpickr('#startDate',{
      enableTime: true,
      enableSeconds : true,
      maxDate: new Date(),
    //   dateFormat: "d-m-Y H:i:S",
      dateFormat: "m-d-Y H:i:S",
      defaultDate:new Date(),  
      time_24hr : true, 
      onChange: function(selectedDates, dateStr, instance) {
        // console.log("selectedDates",selectedDates,"dateStr",dateStr,"instance",instance)
        self.filterObj.startDate = selectedDates;
      }, 
      // onReady: function (selectedDates, dateStr, instance) {
      //   $('#startDate').val(
      //       instance.formatDate(new Date(), 'd-m-Y h:i K')
      //   )
      // },     
    });
  }

  getEnableDisableTier(){
    this.enableTreeArray = [];
    this.newDisableTreeArray = [];
    this.disableTreeLength = 0;
    for (let object of this.data) {
      if(object.flag){
        this.enableTreeArray.push(object.tierId.toString());
      }else{
        this.newDisableTreeArray.push(object.tierId.toString());
      }
    }
    this.disableTreeLength = this.newDisableTreeArray.length;
  }

}
