import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ViewComponent } from './view.component';
import { DashboardComponent } from './section/dashboard/dashboard.component';

/* customers */
import { CustomersComponent } from './section/customers/customers.component';
/* Asset Management */
import { AssetManagementComponent } from './section/asset-management/asset-management.component';
import { AssetSummaryComponent } from './section/asset-summary/asset-summary.component';
import { AssetsummaryMenuComponent } from './section/asset-summary/assetsummary-menu/assetsummary-menu.component';
import { AssetEditComponent } from './section/asset-summary/asset-edit/asset-edit.component';
import { AssetUserComponent } from './section/asset-summary/asset-user/asset-user.component';
import { AssetsLogComponent } from './section/asset-summary/assets-log/assets-log.component';

/* Data Point  */
import { DataPointComponent } from './section/asset-summary/data-point/data-point.component';
/* Asset Graph  */
import { AssetGraphComponent } from './section/asset-summary/asset-graph/asset-graph.component';
/* Modem Traffic  */
import { ModemTrafficComponent } from './section/asset-summary/modem-traffic/modem-traffic.component';
/* Comments  */
import { CommentsComponent } from './section/asset-summary/comments/comments.component';
/* Documents  */
import { DocumentsComponent } from './section/asset-summary/documents/documents.component';
/* mEvents */
import { EventsComponent } from './section/asset-summary/events/events.component';
/* Partlets  */
// import { PartletsComponent } from './section/asset-summary/partlets/partlets.component';
// /* Products  */
// import { ProductsComponent } from './section/asset-summary/products/products.component';

/* Setting */
import { SettingComponent } from './section/setting/setting.component';

import { NotificationComponent } from './section/notification/notification.component';
import { NotificationEventComponent } from './section/notification-event/notification-event.component';
/* Report */
import { ReportComponent } from './section/report/report.component';
import { AddReportComponent } from './section/report/add-report/add-report.component';
import { AdhocReportComponent } from './section/report/adhoc-report/adhoc-report.component';
import { AssetSummaryReportComponent } from './section/report/asset-summary-report/asset-summary-report.component';
import { ClientSettingComponent } from './section/setting/client-setting/client-setting.component';

/* Template  */
import { TemplateComponent } from './section/setting/template/template.component';

import { EventDetailsComponent } from './section/event-details/event-details.component';

import { GlobalSearchComponentComponent } from './section/global-search-component/global-search-component.component';

import { UpComingComponent } from './section/up-coming/up-coming.component';

import { SiteManagementComponent } from './section/site-management/site-management.component';

import { AddSiteComponent } from './section/site-management/add-site/add-site.component';
import { ErrorHandlingComponent } from './section/error-handling/error-handling.component';
import { EventResolve } from 'src/app/services/event.resolve.service';
import { UserProfileComponent } from './section/user-management/user-profile/user-profile.component';

import { HourMeterReportComponent } from './section/report/hour-meter-report/hour-meter-report.component';
import { SiteDataPointSummaryReportComponent } from './section/report/site-data-point-summary-report/site-data-point-summary-report.component';
import { SiteEventNotificationReportComponent } from './section/report/site-event-notification-report/site-event-notification-report.component';
import { SiteMessageActivityReportComponent } from './section/report/site-message-activity-report/site-message-activity-report.component';
import { SiteLocationReportComponent } from './section/report/site-location-report/site-location-report.component';
import { SiteMessageHistoryReportComponent } from './section/report/site-message-history-report/site-message-history-report.component';
import { RuntimeEventReportComponent } from './section/report/runtime-event-report/runtime-event-report.component';
import { TechEntryWizardReportComponent } from './section/report/tech-entry-wizard-report/tech-entry-wizard-report.component';

const routes: Routes = [{
  path: '',
  component: ViewComponent,  resolve :{event:EventResolve/* , userInfo:UserInfoResolve */},
  children: [
    {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
    {
    path: 'dashboard',
    component: DashboardComponent
  },{
    path: 'report',
    component: ReportComponent,
  },{
    path: 'setting',
    component: SettingComponent,
  },{
    path: 'customers',
    component: CustomersComponent,
  },{
    path: 'notification',
    component: NotificationComponent,
  },
  {
    path: 'notification-event',
    component: NotificationEventComponent,
  },
  {
    path: 'asset-management',
    component: AssetManagementComponent,
  },{
    path: 'report',
    component: ReportComponent,
  },{
    path: 'assetsummaryreport',
    component: AssetSummaryReportComponent,
  },{
    path: 'addreport',
    component: AddReportComponent,
  },{
    path: 'adhocreport',
    component: AdhocReportComponent,
  },{
    path: 'addreport/:reportId',
    component: AddReportComponent,
  },{
    path: 'adhocreport/:reportId',
    component: AdhocReportComponent,
  },/* {
    path: 'company-profile',
    component: ClientSettingComponent,
  }, */{
    path: 'assetsummary/:id',
    component: AssetSummaryComponent
  },{
    path: 'template',
    component: TemplateComponent
  },{
    path: 'assetsummarymenu',
    component: AssetsummaryMenuComponent
  },{
    path:'asset-edit',
    component:AssetEditComponent
  },{
    path:'assetGraph/:id',
    component:AssetGraphComponent
  },{
    path: 'assetUser/:id',
    component: AssetUserComponent
  },{
    path: 'assetsLog/:id',
    component: AssetsLogComponent
  },{
    path: 'datapoint/:id',
    component: DataPointComponent
  },{
    path: 'documents/:id',
    component: DocumentsComponent
  },{
    path: 'events/:id',
    component: EventsComponent
  },
  {
    path: 'modemtraffic/:id',
    component: ModemTrafficComponent
  },
  {
    path: 'eventDetails/:id',
    component: EventDetailsComponent
  },
  {
    path: 'global-search/:key',
    component: GlobalSearchComponentComponent
  },
  {
    path: 'up-comming',
    component: UpComingComponent
  },
  {
    path: 'site-management',
    component: SiteManagementComponent
  },
  {
    path: 'addsite/:id',
    component: AddSiteComponent
  },
  {
    path: 'error',
    component: ErrorHandlingComponent,
  },
  {
    path: 'user-profile/:id',
    component: UserProfileComponent,
  },{
    path: 'hour-meter-report',
    component: HourMeterReportComponent
  },{
    path: 'asset-data-point-summary-report',
    component: SiteDataPointSummaryReportComponent,
  },{
    path: 'asset-event-notification-report',
    component: SiteEventNotificationReportComponent,
  },{
    path: 'asset-message-activity-report',
    component: SiteMessageActivityReportComponent,
  },{
    path: 'asset-location-report',
    component: SiteLocationReportComponent,
  },{
    path: 'asset-message-history-report',
    component: SiteMessageHistoryReportComponent,
  },{
    path: 'tech-entry-wizard-report',
    component: TechEntryWizardReportComponent,
  },{
    path: 'runtime-event-report',
    component: RuntimeEventReportComponent,
  }/* ,
  {
    path: '**',
    redirectTo: '/login'
  } */

  ]
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ViewsRoutingModule {
}
