import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService, AlertService } from '../../../../../services/index';
import Swal from 'sweetalert2'
declare var $: any;
declare var jQuery: any;
declare var d3: any, moment: any;
import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';
import { jqxTreeComponent } from '../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../jqwidgets-ts/angular_jqxdropdownbutton'
import * as NProgress from 'nprogress';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import flatpickr from "flatpickr";
import { DomSanitizer } from '@angular/platform-browser';
// import { LoadingBarService } from '@ngx-loading-bar/core';

@Component({
  // host: {
  //   '(document:click)': 'onClick($event)',
  // },
  selector: 'app-runtime-event-report',
  templateUrl: './runtime-event-report.component.html',
  styleUrls: ['./runtime-event-report.component.css']
})
// @Component({
//   host: {
//     '(document:click)': 'onClick($event)',
//   },
//   selector: 'app-runtime-event-report',
//   templateUrl: './runtime-event-report.component.html',
//   styleUrls: ['./runtime-event-report.component.css']
// })
export class RuntimeEventReportComponent implements OnInit {

  // @ViewChild('myTree') myTree: jqxTreeComponent;
  // @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;
  // @ViewChild('tierArea') tierArea: ElementRef;
  // @ViewChild('treeArea') treeArea: ElementRef;
  customerId: any = (localStorage.getItem("customerId") ? JSON.parse(localStorage.getItem("customerId")) : []);
  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  clientCode: any = '';
  data: any = [];
  dataAdapter: any = [];
  source: any = {};
  records: any = [];
  disableTreeArray: any = [];
  checkTreeArray: any = [];
  tiersArray: any = [];
  dropdownSettingsSite: any = {};
  siteList: any = [];
  selectedSite: any = [];
  checkSiteArray: any = [];
  siteIdForApi: any;
  selectAllTier = false;
  reportData: any = [];
  dataTable: any;
  tierIdForApi: any;
  maxStartDate: any = new Date();
  filterObj: any = {
    "startDate": new Date(),
    // "endDate": this.minEndDate,
    "endDate": new Date(),
    "selectedAssetId": '',
    "selectedSite": "",
  }
  todayDateInFutureMonth: any = moment(new Date()).add(1, 'M');
  // minEndDate:any = moment(this.todayDateInFutureMonth).format("YYYY-MM-DD hh:mm:ss");
  minEndDate: any = this.filterObj.startDate;
  showTier: boolean = false;
  hideTempTier: boolean = false;
  downloadUrl: any = "";
  tierToggleClass: any = "";
  tierPlaceholder: any = "Select Tier";
  assetDatalist: any = [];
  selectedAsset: any = [];
  dropdownSettingsAsset: any = {};
  checkAssetArray: any = [];
  assetIdForApi: any;
  assetErr: any = "";
  tierErr: any = "";
  tableColumObj: any = [];
  emailData: any = {
    emails: '',
    message: ''
  };
  emailArray = [];
  showValidEmailErr: boolean = false;
  showMsgErr: boolean = false;
  showEmailErr: boolean = false;
  emailIds: any = "";
  emailBodyMsg: any = "";
  newCheckDateDiff: boolean = false;
  showTable: boolean = false;
  enableTreeArray: any = [];
  disableTreeLength: any = 0;
  newDisableTreeArray: any = [];
  startDateErr: any = "";
  endDateErr: any = "";
  siteErr: any = "";
  emailIdArr: any = [];
  // emailObj:any={'type':'RuntimeEventReport', 'clientId':this.clientId};
  emailObj: any = {};
  excelFile: any = '';
  constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService, private alertService: AlertService, private chRef: ChangeDetectorRef,
    // private loading: LoadingBarService, 
    private eRef: ElementRef, private sanitizer: DomSanitizer) {
  }

  // onClick(event) {
  //   // if (!this.eRef.nativeElement.contains(event.target)){
  //   //   this.showTier = false;
  //   // }
  //   if (!(this.tierArea.nativeElement.contains(event.target))) {
  //     this.showTier = false;
  //   }

  //   if (!this.showTier) { 
  //     this.tierToggleClass = ""; 
  //   } else { 
  //     if (!(this.tierArea.nativeElement.contains(event.target)) && !(this.treeArea.nativeElement.contains(event.target))) {        
  //      this.showTier = false;
  //      this.tierToggleClass = "";
  //     }else{
  //       this.tierToggleClass = "site-m-after";
  //     } 
  //   }
  // }


  ngOnInit() {
    // this.getTierList({value:1});
    // this.dropdownSettingsSite = {
    //   singleSelection: false,
    //   idField: 'siteId',
    //   textField: 'siteName',
    //   selectAllText: 'Select All',
    //   unSelectAllText: 'UnSelect All',
    //   itemsShowLimit: 0,
    //   allowSearchFilter: true,
    //   placeholder : "Select Site" 
    // };
    this.setFlatPicker();//initialize date picker using flatpicker
    this.getAllAssetList();
    this.dropdownSettingsAsset = {
      singleSelection: false,
      idField: 'assetId',
      textField: 'assetName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true,
      placeholder: "Select Asset",
    };

    // this.clientCode = this.alertService.getClientCode();
    // if(this.reportData.length!=0){
    //   this.initializeDataTable()
    // }
  }

  initializeDataTable() {
    if ($.fn.DataTable.isDataTable('#report_table')) {
      $('#report_table').DataTable().destroy();
    };
    this.dataTable = $('#report_table').DataTable();
  }

  // Function to format Date
  DateFormat(dateStr) {
    if (dateStr) {
      // return moment(dateStr).format("YYYY-MM-DD hh:mm:ss");
      var dateTime = new Date(dateStr);
      return moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
    } else {
      return "";
    }
  }

  // showHideTierTree(event){
  //   if(!this.showTier){
  //     this.showTier = true;
  //     this.tierToggleClass="site-m-after";
  //   }else{
  //     this.showTier = false;
  //     this.tierToggleClass="";
  //   }
  // }

  // hideTier(){
  //   this.showTier = false;
  // }

  // Get all tier list
  // getTierList(type) {
  //   var URL = 'tier/getAllTierByUser?clientId=' + this.clientId + '&userId=' + this.userId;
  //   if (type.value != 0 || type.value != '0') {
  //     URL +="&type="+type.value
  //   }
  //   this.apiService.getAll(URL).subscribe(
  //     data => {
  //       if (data.statusCode == 100) {
  //         this.data = data.responseList;

  //         for (let object of this.data) {
  //           if(!object.flag){
  //             this.disableTreeArray.push(object.tierId.toString());
  //           }
  //         }
  //         // disable tree length
  //         this.getEnableDisableTier();

  //         // Object.keys(this.data).forEach(key => {
  //         //   if (this.data[key]['flag'] == false) {
  //         //     this.disableTreeArray.push(this.data[key]['tierId']);
  //         //   }
  //         // });

  //         this.source = {
  //           datatype: 'json',
  //           datafields: [
  //               {name: 'tierId'}, 
  //               {name: 'parentTierId'},
  //               {name: 'tierName'}
  //             ],
  //             id: 'tierId',
  //             localdata: this.data
  //         };
  //         this.dataAdapter = new jqx.dataAdapter(this.source, {
  //           autoBind: true
  //         });
  //         this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [
  //                           {
  //                             name: 'tierName',
  //                             map: 'label'
  //                           }, 
  //                           {
  //                             name: 'tierId',
  //                             map: 'id'
  //                           }]);
  //         var self = this;
  //         setTimeout(function() {
  //           self.treeItemDisable();
  //         }, 500);
  //       }
  //     },
  //     error =>{
  //                   this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
  //     }
  //   );
  // }

  // Function to disable items
  // treeItemDisable() {
  //   Object.keys(this.disableTreeArray).forEach(key => {
  //       let checkedItem = document.getElementById(this.disableTreeArray[key]);
  //       this.myTree.expandItem(checkedItem);
  //       this.myTree.disableItem(checkedItem);
  //   });
  //   if (this.checkTreeArray.length != 0) {
  //     this.tierPlaceholder = "+" + this.checkTreeArray.length + " Selected";
  //   }else{
  //     this.tierPlaceholder = "Select Tier";
  //   }
  // }

  // Select all function for tier
  // selectAllTree(event){
  //   this.checkTreeArray = [];
  //   if (event.target.checked) {
  //       for (let object of this.data) {
  //         if(object.flag){
  //           this.checkTreeArray.push(object.tierId.toString());
  //         }
  //       }
  //       this.checkedCheckboxOnLoad()
  //   }else{
  //       this.checkTreeArray = []
  //       this.checkedCheckboxOnLoad()
  //       for (let object of this.data) {
  //           let checkedItem = document.getElementById(object.tierId.toString());
  //           this.myTree.expandItem(checkedItem);
  //           this.myTree.checkItem(checkedItem, false);
  //       }
  //   }
  //   if (this.checkTreeArray.length != 0) {
  //     this.tierPlaceholder = "+" + this.checkTreeArray.length + " Selected";
  //   }
  //   setTimeout(() => {
  //       this.getSiteManagementList();
  //       // this.getAllAssetList();
  //   }, 100);
  // }

  // checked checkbox on load of tree
  // checkedCheckboxOnLoad() {
  //   Object.keys(this.checkTreeArray).forEach(key => {
  //       let checkedItem = document.getElementById(this.checkTreeArray[key]);
  //       this.myTree.expandItem(checkedItem);
  //       this.myTree.checkItem(checkedItem, true);
  //   });
  // }

  /* Handle tier tree chacked unchecked Event */
  // CheckChange(event) {
  //   if (event.args.checked == true) {
  //     var exist = this.checkTreeArray.indexOf(event.args.element.id);
  //     if (exist == '-1') {
  //       this.checkTreeArray.push(event.args.element.id);
  //       setTimeout(() => {
  //         this.getSiteManagementList();
  //         // this.getAllAssetList();
  //       }, 100);
  //     }
  //   } else if (event.args.checked == false || event.args.checked == null) {
  //     var exist = this.checkTreeArray.indexOf(event.args.element.id);
  //     if (exist != '-1') {
  //       this.checkTreeArray.splice(exist, 1);
  //       setTimeout(() => {
  //         this.getSiteManagementList();
  //         // this.getAllAssetList();
  //       }, 100);
  //     }
  //   }

  //   if (this.checkTreeArray.length == 0) {
  //     this.dropdownSettingsSite.placeholder = "Select a tier first"; 
  //     // this.dropdownSettingsAsset.placeholder = "Select a tier first";
  //     this.tierPlaceholder = "Select Tier";
  //   }else{
  //     this.dropdownSettingsSite.placeholder = "Select Site";
  //     // this.dropdownSettingsAsset.placeholder = "Select Asset";
  //     this.tierPlaceholder = "+" + this.checkTreeArray.length + " Selected";
  //   }

  //   // if (this.data.length == this.checkTreeArray.length) {
  //   //   this.selectAllTier = true            
  //   // }else{
  //   //   this.selectAllTier = false
  //   // }

  //   if(this.disableTreeLength==0) {
  //     if (this.data.length == this.checkTreeArray.length) {
  //       this.selectAllTier = true            
  //     }else{
  //       this.selectAllTier = false
  //     }
  //   }else{
  //     let actualLength = this.data.length-this.disableTreeLength;
  //     if (actualLength == this.checkTreeArray.length) {
  //       this.selectAllTier = true            
  //     }else{
  //       this.selectAllTier = false
  //     }
  //   }


  // }

  /* Get Site Management list */
  // getSiteManagementList() {
  //   this.siteList = []
  //   this.selectedSite = [];
  //   this.checkSiteArray = [];
  //   this.siteIdForApi = "";
  //   this.checkAssetArray = [];
  //   this.assetDatalist = [];
  //   this.selectedAsset = [];
  //   this.assetIdForApi = "";
  //   this.filterObj.selectedAssetId = 0;
  //   this.filterObj.selectedSite = "";

  //       var URL = 'site/getSiteByClient?alphabateSortingOreder=1&clientId=' + this.clientId + '&userId=' + this.userId;
  //       let Tier = this.checkTreeArray.join();
  //       if (Tier != null && Tier != '') {
  //           var tierString = '&tierId=' + Tier.toString();
  //           var URL = URL.concat(tierString);
  //       }
  //       if (this.checkTreeArray.length == 0) {    
  //           this.siteList = []
  //           this.selectedSite = [];
  //       }else {
  //           this.startLoading();
  //           this.apiService.getAll(URL).subscribe(
  //               data => {
  //                   if (data.statusCode == 100) {
  //                     if(this.checkTreeArray.length != 0) {
  //                       this.siteList = data.responseList;
  //                       let tempSelected = []
  //                       this.selectedSite.map((obj, index)=>{
  //                           let findObj = _.find(this.siteList, { siteId: obj.siteId});
  //                           if (findObj) {
  //                               tempSelected.push(obj);
  //                           }
  //                       })
  //                       this.selectedSite = tempSelected;
  //                     }
  //                     this.stopLoading();
  //                   } else {
  //                       this.siteList = [];
  //                       this.stopLoading();
  //                   }
  //               },
  //               error =>{
  //                 this.stopLoading();
  //                             this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
  //               }
  //           );
  //       }
  // }

  /* On item select site */
  // onItemSelectSite(item: any) {
  //   var exist = this.checkSiteArray.indexOf(item.siteId.toString());
  //   if (exist == '-1') {
  //       this.checkSiteArray.push(item.siteId.toString());
  //       this.siteIdForApi = this.checkSiteArray.join();
  //       this.getAllAssetList();
  //   }
  // }

  /* On item de-select site */
  // onItemDeselectSite(item: any) {
  //   var exist = this.checkSiteArray.indexOf(item.siteId.toString());
  //   if (exist != '-1') {
  //       this.checkSiteArray.splice(exist, 1);
  //       this.siteIdForApi = this.checkSiteArray.join();
  //       this.getAllAssetList();
  //   }
  // }

  /* On select all site */
  // onSelectAllSite(items: any) {
  //   this.checkSiteArray = [];
  //   this.siteIdForApi = this.checkSiteArray.join();

  //   Object.keys(items).forEach(key => {
  //       this.checkSiteArray.push(items[key]['siteId'].toString());
  //       this.siteIdForApi = this.checkSiteArray.join();
  //   });

  //   setTimeout(()=> {
  //     this.getAllAssetList();
  //   }, 500);
  // }

  /* On de-select all site */
  // onDeSelectAllSite() {
  //   this.checkSiteArray = [];
  //   this.siteIdForApi = this.checkSiteArray.join();
  //   this.getAllAssetList();
  // }

  /* On Item Select Asset */
  onItemSelectAsset(item: any) {
    var exist = this.checkAssetArray.indexOf(item.assetId.toString());
    if (exist == '-1') {
      this.checkAssetArray.push(item.assetId.toString());
      this.assetIdForApi = this.checkAssetArray.join();
    }
  }

  /* On Item DeSelect Asset */
  onItemDeselectAsset(item: any) {
    var exist = this.checkAssetArray.indexOf(item.assetId.toString());
    if (exist != '-1') {
      this.checkAssetArray.splice(exist, 1);
      this.assetIdForApi = this.checkAssetArray.join();
    }
  }

  /* On All Select Asset */
  onSelectAllAsset(items: any) {
    this.checkAssetArray = [];
    Object.keys(items).forEach(key => {
      this.checkAssetArray.push(items[key]['assetId'].toString());
      this.assetIdForApi = this.checkAssetArray.join();
    });
  }

  /* On All DeSelect Site */
  onDeSelectAllAsset() {
    this.checkAssetArray = [];
    this.assetIdForApi = this.checkAssetArray.join();
  }

  // Getting Asset List
  getAllAssetList() {
    this.assetDatalist = [];
    this.filterObj.selectedAssetId = '';
   
    var URL = 'customer/asset/getAssetDropDownByClient'
    let apiData: any = {
      clientId: this.clientId,
      customerId: [this.customerId]
    }
    // if (this.siteIdForApi != null && this.siteIdForApi != '') {
    //   var siteString = '&siteId=' + this.siteIdForApi.toString();
    //   var URL = URL.concat(siteString);
    // }
    // if (this.tierIdForApi != null && this.tierIdForApi != '') {
    //   var tierString = '&tierId=' + this.tierIdForApi.toString();
    //   var URL = URL.concat(tierString);
    // }
    // if (this.checkSiteArray.length == 0) {
    //   this.assetDatalist = [];
    //   this.selectedAsset = [];
    // } else {
    //   this.startLoading();
      this.apiService.create(URL, apiData).subscribe(
        data => {
          if (data.statusCode == 100) {
              this.assetDatalist = data.responseList;
              let tempSelected = [];
              this.selectedAsset.map((obj, index) => {
                let findObj = _.find(this.assetDatalist, { assetId: obj.assetId });
                // console.log(findObj)
                if (findObj) {
                  tempSelected.push(obj);
                }
              })
              this.selectedAsset = tempSelected;
            // this.stopLoading();
          } else {
            this.assetDatalist = [];
            this.selectedAsset = [];
            // this.stopLoading();
          }
        },
        error => {
          // this.stopLoading();
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    // }
  }

  clickEmail() {
    $('#no-record-found').css('display', 'block');
  }

  // Run Report
  runReport() {
    this.startDateErr = "";
    this.endDateErr = "";
    let isRequestErr = false;
    this.assetErr = '';
    this.reportData = [];
    if (this.reportData.length != 0) {
      if ($.fn.DataTable.isDataTable('#report_table')) {
        $('#report_table').DataTable().destroy();
        this.reportData = [];
      }
    }
    let startDate = this.DateFormat(this.filterObj.startDate);
    let endDate = this.DateFormat(this.filterObj.endDate);
    this.assetIdForApi = this.filterObj.selectedAssetId;
    // this.siteIdForApi = this.filterObj.selectedSite;
    // this.tierIdForApi = this.checkTreeArray.join();
    var URL = 'customer/report/getPreviewWffRuntimeEventReports';
   
    // if(this.siteIdForApi==""){
    //   this.siteErr = "Site is required";return;
    // }

    if (startDate == "" && endDate == "") {
      this.startDateErr = "Start Date is required"
      this.endDateErr = "End Date is required"
      isRequestErr = true
      // return;
    }
    if (startDate == "") {
      this.startDateErr = "Start Date is required"
      isRequestErr = true
      // return;
    }
    if (endDate == "") {
      this.endDateErr = "End Date is required"
      isRequestErr = true
      // return;
    }

    // apply date validation
    if (startDate != '' && endDate != '') {
      // convert dates into miliseconds for check difference
      var startDateMs = moment(startDate).valueOf();
      var endDateMs = moment(endDate).valueOf();
      let dateDiff: any = endDateMs - startDateMs;//calculate difference
      // if ((parseInt(dateDiff) < 1) && (this.tierIdForApi == "") && (this.assetIdForApi == 0)) {
      //   this.newCheckDateDiff = true;
      //   return;
      // } else {
      //   this.newCheckDateDiff = false;
      // }
      if (parseInt(dateDiff) < 1) {
        this.newCheckDateDiff = true; 
        isRequestErr = true
      // return;
      } else {
        this.newCheckDateDiff = false;
      }
    }
    let apiData: any = {
      clientId: this.clientId,
      // userId: this.userId,
      startLimit: 0,
      endLimit: 1500,
      customerid: this.customerId
    }
    if (startDate != null && startDate != "") {
      apiData.startDate = startDate;
      // var startDateString = '&startDate=' + startDate;
      // var URL = URL.concat(startDateString);
    }
    if (endDate != null && endDate != "") {
      apiData.endDate = endDate;
      // var endDateString = '&endDate=' + endDate;
      // var URL = URL.concat(endDateString);
    }
    if (this.assetIdForApi != null && this.assetIdForApi != '') {
      apiData.assetId = this.assetIdForApi.toString();
      // var assetString = '&assetId=' + this.assetIdForApi.toString();
      // var URL = URL.concat(assetString);
    }else{
      this.assetErr = 'Asset is required';
      isRequestErr = true
    }
    if (isRequestErr) {
      return
    }
    this.showTable = true;
    this.apiService.create(URL, apiData).subscribe(
      data => {
        if (data.statusCode == 100) {
          $('#no-record-found').css('display', 'none');
          this.reportData = [];
          this.reportData = data.responseList;
          if (this.reportData.length != 0) {
            for (const key in this.reportData[0].map) {
              if (this.reportData[0].map.hasOwnProperty(key)) {
                const element = this.reportData[0].map[key];
                this.tableColumObj.push(key)
              }
            }
            this.chRef.detectChanges();
            if ($.fn.DataTable.isDataTable('#report_table')) {
              $('#report_table').DataTable().destroy();
            };
            const table: any = $('#report_table');
            this.dataTable = table.DataTable();
            // setTimeout(() => {
            //   const table: any = $('#report_table');
            //   this.dataTable   = table.DataTable();
            // }, 1000);
          }
        }
        else {
          $('#no-record-found').css('display', 'block');
          this.reportData = [];
          if (this.reportData.length == 0) {
            this.tableColumObj = [];
            if ($.fn.DataTable.isDataTable('#report_table')) {
              $('#report_table').DataTable().destroy();
            };
            // this.alertService.sweetMessage('error', data.responseMessage);
            // const table: any = $('#report_table');
            // this.chRef.detectChanges();
            // this.dataTable = table.DataTable();
          }
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  // download excel report
  excelReport() {
    // this.showTier = false;
    if (this.reportData.length != 0) {
      $("#downloadReportBtn").attr("href", '');
      // this.tierIdForApi = this.checkTreeArray.join();
      let startDate = this.DateFormat(this.filterObj.startDate);
      let endDate = this.DateFormat(this.filterObj.endDate);

      var URL = 'customer/report/getDownloadWffRuntimeEventReport';
      let apiData: any = {
        clientId: this.clientId,
        userId: this.userId,
        customerid: this.customerId
      }
      if (startDate != null && startDate != "") {
        apiData.startDate = startDate;
        // var startDateString = '&startDate=' + startDate;
        // var URL = URL.concat(startDateString);
      }
      if (endDate != null && endDate != "") {
        apiData.endDate = endDate;
        // var endDateString = '&endDate=' + endDate;
        // var URL = URL.concat(endDateString);
      }
      if (this.assetIdForApi != null && this.assetIdForApi != '') {
        apiData.assetId = this.assetIdForApi.toString();
        // var assetString = '&assetId=' + this.assetIdForApi.toString();
        // var URL = URL.concat(assetString);
      }
      // if (this.siteIdForApi != null && this.siteIdForApi != '') {
      //   var siteString = '&siteId=' + this.siteIdForApi.toString();
      //   var URL = URL.concat(siteString);
      // }


      this.apiService.downloadFile(URL, apiData).subscribe(
        (data: any) => {
          let resData = JSON.stringify(data)
          var blob = new Blob([data._body], {type: 'application/octet-stream'});
          this.excelFile = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
          setTimeout(() => {
            var te = document.getElementById('downloadReportBtn');
            te.click();            
          }, 2000);
        },
        error => {
          this.alertService.sweetMessage('error',"Something went wrong, please try again ");
          // var mainUrl = this.apiService.baseBath + '/' + URL;
          // this.downloadUrl = mainUrl;
          // $("#downloadReportBtn").attr("href", mainUrl);
          // var te = document.getElementById('downloadReportBtn');
          // te.click();
          // NProgress.done();
          // $("#loaderImgIcon").css("display", "none");
        }
      );
    } else {
      $('#no-record-found').css('display', 'block');
    }
  }

  // Email Report
  emailReport() {
    // this.showTier = false;
    this.emailObj = {};
    this.emailObj.type = 'WffRuntimeEventReport'; //changed as per the backend requirement
    this.emailObj.clientId = this.clientId;
    this.emailObj.customerid = this.customerId;
    
    if (this.reportData.length != 0) {
      // this.tierIdForApi = this.checkTreeArray.join();
      let startDate = this.DateFormat(this.filterObj.startDate);
      let endDate = this.DateFormat(this.filterObj.endDate);
      var URL = 'customer/report/sendWffRuntimeEventReportEmail';

      // if (this.siteIdForApi != null && this.siteIdForApi != '') {
      //     var siteString = '&siteId=' + this.siteIdForApi.toString();
      //     var URL = URL.concat(siteString);
      // }
      if (this.assetIdForApi != null && this.assetIdForApi != '') {
        this.emailObj.assetId = this.assetIdForApi.toString();
      }
      if (startDate != null && startDate != "") {
        this.emailObj.startDate = startDate;
      }
      if (endDate != null && endDate != "") {
        this.emailObj.endDate = endDate;
      }
      if (this.emailBodyMsg != "") {
        this.emailObj.emailBodyMessage = this.emailBodyMsg;
      }
      if (this.emailIdArr.length > 0) {
        this.emailObj.emailIds = this.emailIdArr;
      }
      // console.log("this.emailObj--",this.emailObj);
      this.apiService.create(URL, this.emailObj).subscribe(
        data => {
          if (data.statusCode == 100) {
            $("#closeModal").click();
            this.resetModalData();
            this.alertService.sweetMessage('success', "Report has been sent");
          }
          else {
            this.alertService.sweetMessage('error', data.responseMessage);
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }
  }

  // verify email validations
  emailVerify() {
    this.emailBodyMsg = "";
    let allEmailValid = false;
    if (this.emailData.emails != "") {
      this.emailArray = this.emailData.emails.split(',');
      if ((this.emailArray.length != 0) && (this.emailArray.length > 0)) {
        let emailExp = new RegExp("[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\\.[a-zA-Z]{2,3}$")
        this.emailArray.forEach((data, index) => {
          // console.log("data=======",data,'++++++++++',emailExp.test(data))
          if (!emailExp.test(data)) {
            this.showValidEmailErr = true
            allEmailValid = true
          }
        })
      }
    }

    // check for email required
    if (this.emailData.emails) {
      this.showEmailErr = false;
    } else {
      this.showEmailErr = true;
      this.showValidEmailErr = false;
    }

    // check for valid email id
    if (allEmailValid) {
      this.showValidEmailErr = true
    } else {
      this.showValidEmailErr = false
    }

    // check for msg
    // if (this.emailData.message) {
    //   this.showMsgErr = false
    // }else {
    //   this.showMsgErr = true
    // }

    if ((!this.showEmailErr) && (!this.showValidEmailErr)) {
      this.emailBodyMsg = this.emailData.message;
      if (this.emailArray.length > 0) {
        this.emailIdArr = [];
        this.emailArray.forEach((data, index) => {
          var exist = this.emailIdArr.indexOf(data.trim());
          if (exist == '-1') {
            this.emailIdArr.push(data.trim());
          }
        });
        // console.log("emailIdArr---", this.emailIdArr)
      }
      if (this.emailBodyMsg != "") {
        this.emailBodyMsg = this.emailBodyMsg.replace(new RegExp('\n', 'g'), "<br/>");
      }
      this.emailReport();
    }
  }

  resetModalData() {
    this.emailData = {
      emails: '',
      message: ''
    };
    this.emailArray = [];
    this.showEmailErr = false;
    this.showValidEmailErr = false;
    // this.showMsgErr = false;
  }

  // getEnableDisableTier() {
  //   for (let object of this.data) {
  //     if (object.flag) {
  //       this.enableTreeArray.push(object.tierId.toString());
  //     } else {
  //       this.newDisableTreeArray.push(object.tierId.toString());
  //     }
  //   }
  //   this.disableTreeLength = this.newDisableTreeArray.length;
  // }

  //setting FlatPicker Property
  setFlatPicker() {
    var self = this;
    //faltpicke config for end Date
    flatpickr('#endDate', {
      enableTime: true,
      enableSeconds: true,
      maxDate: new Date(),
      // dateFormat: "d-m-Y H:i:S",
      dateFormat: "m-d-Y H:i:S",
      defaultDate: new Date(),
      time_24hr: true,
      onChange: function (selectedDates, dateStr, instance) {
        // console.log("selectedDates",selectedDates,"dateStr",dateStr,"instance",instance)
        self.filterObj.endDate = selectedDates;
      },
    });

    //faltpicke config for start Date
    flatpickr('#startDate', {
      enableTime: true,
      enableSeconds: true,
      maxDate: new Date(),
      // dateFormat: "d-m-Y H:i:S",
      dateFormat: "m-d-Y H:i:S",
      defaultDate: new Date(),
      time_24hr: true,
      onChange: function (selectedDates, dateStr, instance) {
        // console.log("selectedDates",selectedDates,"dateStr",dateStr,"instance",instance)
        self.filterObj.startDate = selectedDates;
      },
      // onReady: function (selectedDates, dateStr, instance) {
      //   $('#startDate').val(
      //       instance.formatDate(new Date(), 'd-m-Y h:i K')
      //   )
      // },     
    });
  }

  startLoading() {
    // this.loading.start();
  }

  stopLoading() {
    // this.loading.complete();
  }

}
