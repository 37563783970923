import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response, ResponseContentType } from '@angular/http';
import "rxjs/Rx"
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import * as NProgress from 'nprogress';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'
declare var moment: any;
var ip = location.host;

var apiUrl: String = "https://customer.amci1440.com/cust/api/v1";
var loginUrl: String = "https://customer.amci1440.com";
var imgUrl: String = "https://customer.amci1440.com/";

// if (location.host == "customer.amci1440.com") {
//     // var apiUrl: String = "http://customer.amci1440.com/api/v1";
//     var apiUrl: String = "https://customer.amci1440.com/cust/api/v1";
//     var loginUrl: String = "https://customer.amci1440.com";
//     var imgUrl: String = "https://customer.amci1440.com/";
// } else if (location.host == "trialcustomer.satalarm.com") {
//     // var apiUrl: String = "https://trialcustomer.satalarm.com/api/v1";
//     var apiUrl: String = "https://trialcustomer.satalarm.com/cust/api/v1";
//     var loginUrl: String = "https://trialcustomer.satalarm.com";
//     var imgUrl: String = "https://trialcustomer.satalarm.com/";
// } else if (location.host == "trial1-customer.amci1440.com") {
//     // var apiUrl: String = "https://trial1-customer.amci1440.com/api/v1";
//     var apiUrl: String = "https://trial1-customer.amci1440.com/cust/api/v1";
//     var loginUrl: String = "https://trial1-customer.amci1440.com";
//     var imgUrl: String = "https://trial1-customer.amci1440.com/";
// } else if (location.host == "trial2-customer.amci1440.com") {
//     // var apiUrl: String = "https://trial1-customer.amci1440.com/api/v1";
//     var apiUrl: String = "https://trial2-customer.amci1440.com/cust/api/v1";
//     var loginUrl: String = "https://trial2-customer.amci1440.com";
//     var imgUrl: String = "https://trial2-customer.amci1440.com/";
// } else if (location.host == "staging-customer.amci1440.com") {
//     // var apiUrl: String = "https://trialcustomer.satalarm.com/api/v1";
//     var apiUrl: String = "https://staging-customer.amci1440.com/cust/api/v1";
//     var loginUrl: String = "https://staging-customer.amci1440.com";
//     var imgUrl: String = "https://staging-customer.amci1440.com/";
// }
// else if (location.host == "customer.satalarm.com") {
//     // var apiUrl: String = "https://demo.satalarm.com/customer/api/v1";
//     var apiUrl: String = "https://demo.satalarm.com/cust/api/v1";
//     var loginUrl: String = "https://demo.satalarm.com";
//     var imgUrl: String = "https://demo.satalarm.com/";
// }
// else if (location.hostname == "192.168.0.112") {
//     var loginUrl: String = "http://192.168.0.112:8763";
//     var apiUrl: String = "http://192.168.0.112:8767/api/v1";
//     var imgUrl: String = "http://amciimages.cdnsolutionsgroup.com/";
// }
// else if (location.hostname == 's3.cdn131.local') {
//     var loginUrl: String = "http://s3.cdn131.local";
//     var apiUrl: String = "http://s3.cdn131.local/cust/api/v1";
//     var imgUrl: String = "https://s3.cdn131.local/";
// }
// else {

//     var loginUrl: String = "https://s14.cdnsolutionsgroup.com";
//     var apiUrl: String = "https://s14.cdnsolutionsgroup.com/cust/api/v1";
//     var imgUrl: String = "https://s14.cdnsolutionsgroup.com/";


//     // var loginUrl: String = "http://172.20.1.203:8763";
//     // var apiUrl  : String = "http://172.20.1.203:8767/api/v1";
//     // var imgUrl  : String = "http://s09.cdnsolutionsgroup.com/";

//     // 	var loginUrl: String = "http://172.20.1.104:8763";
//     // var apiUrl  : String = "http://172.20.1.104:8767/api/v1/";
//     // var imgUrl  : String = "http://s09.cdnsolutionsgroup.com/";

//     // var loginUrl: String = "http://172.20.1.186:8768";
//     // var apiUrl  : String = "http://172.20.1.186:8768/cust/api/v1";
//     // var imgUrl  : String = "http://s09.cdnsolutionsgroup.com/";

// }

var routeNavigate: any = '';

@Injectable()
export class ApiService {
    public static permission: any = {};
    public static isPermission: number = 0;
    NProgress: any;
    AppUrlService = apiUrl
    LoginUrlService = loginUrl
    constructor(private router: Router, private http: Http) {
        routeNavigate = this.router;


    }



    refreshToken(end_point, obj) {
        let headers = new Headers(/* { 'Authorization': token.access_token} */);
        headers.append("Content-Type", "application/x-www-form-urlencoded");
        // headers.append("Access-Control-Allow-Origin" , "*");
        // headers.append("Access-Control-Allow-Credentials" , "true");
        // headers.append("Access-Control-Allow-Methods" , "*");
        const httpOptions = new RequestOptions({ headers: headers });
        let finalRequestBody = "refresh_token=";
        finalRequestBody = finalRequestBody + obj.refresh_token + "&grant_type=refresh_token"
        // finalRequestBody=finalRequestBody+obj.password+""
        return this.http.post(loginUrl + '/' + end_point, finalRequestBody, httpOptions)
            .map((res: Response) => {
                return res.json();
            })
            .catch((error: any) => {
                return Observable.throw(error.json());
            });
    }

    getUserInfoData(end_point) {

        return this.http.get(apiUrl + '/' + end_point, this.AuthorizationHeader())
            .map((res: Response) => {
                return res.json();
            })
            .catch((error: any) => {
                return Observable.throw(error.json());
            });
    }

    /*Function for getting user token*/
    getUserToken(): Promise<any> {
        return this.http.get('/token').map((response: Response) => {
            return response.json();
        }).toPromise();
    }

    end_point: String;
    data: any;
    id: Number;
    baseBath: String = apiUrl;
    imageBasePath: String = imgUrl == "http://192.168.0.112" || imgUrl == "https://s09.cdnsolutionsgroup.com" ? imgUrl + '/' : imgUrl;
    getAllDashboard(end_point) {
        return this.http.get(apiUrl + '/' + end_point)
            .map(this.handleSuccessD)
            .catch(this.handleErrorD);
    }

    getAll(end_point) {
        if (this.isTokenAvailable()) {
            NProgress.start();

            return this.http.get(apiUrl + '/' + end_point, this.AuthorizationHeader())
                .map(this.handleSuccess)
                .catch(this.handleError);
        }
    }

    getWithouttoken(end_point) {
        // if (this.isTokenAvailable()) {
        NProgress.start();

        return this.http.get(apiUrl + '/' + end_point)
            .map(this.handleSuccess)
            .catch(this.handleError);
        // }
    }

    login(end_point, data) {
        let headers = new Headers();
        headers.append("Content-Type", "application/x-www-form-urlencoded");
        // headers.append("Access-Control-Allow-Origin" , "*");
        // headers.append("Access-Control-Allow-Credentials" , "true");
        // headers.append("Access-Control-Allow-Methods" , "*");
        const httpOptions = new RequestOptions({ headers: headers });

        NProgress.start();
        let finalRequestBody = "username=";
        finalRequestBody = finalRequestBody + data.username + "&password="
        finalRequestBody = finalRequestBody + data.password + "&grant_type="
        finalRequestBody = finalRequestBody + data.grant_type + "&user_type=customer";

        return this.http.post(loginUrl + '/' + end_point, finalRequestBody, httpOptions)
            .map(this.handleSuccessLogin)
            .catch(this.handleErrorLogin);
    }

    logout(end_point) {
        NProgress.start();
        return this.http.delete(loginUrl + '/' + end_point, this.AuthorizationHeader())
            .map(this.handleSuccessLogin)
            .catch(this.handleErrorLogin);
    }

    private handleSuccessLogin(res: Response) {
        NProgress.done();
        localStorage.setItem("t11", new Date().getTime().toString())
        document.cookie = "t11=;"//+data.refresh_token
        // document.cookie = "t11="+new Date().getTime().toString()+'; path=/';
        document.cookie = "t11=" + new Date().getTime().toString() + '; path=/; expires=' + new Date(moment().add(600, 'days')).toUTCString();
        $("#loaderImgIcon").css("display", "none");
        return res;
    }

    private handleErrorLogin(error: any) {
        NProgress.done();
        $("#loaderImgIcon").css("display", "none");
        return Observable.throw(error.json());
    }

    getById(end_point, id: number) {
        NProgress.start();
        return this.http.get(apiUrl + '/' + end_point + '/' + id)
            .map(this.handleSuccess)
            .catch(this.handleError);
    }

    // login(end_point, data) {
    //     NProgress.start();
    //     return this.http.post(apiUrl+'/'+end_point, data)
    //         .map(this.handleSuccess)
    //         .catch(this.handleError);
    // }

    create(end_point, data) {
        if (this.isTokenAvailable()) {
            NProgress.start();
            return this.http.post(apiUrl + '/' + end_point, data, this.AuthorizationHeader())
                .map(this.handleSuccess)
                .catch(this.handleError);
        }
    }

    downloadFile(end_point, data) {
        if (this.isTokenAvailable()) {
            NProgress.start();
            let token = JSON.parse(localStorage.getItem('Token'));
            let headers = new Headers({ 'Authorization': 'bearer ' + token.access_token });
            var options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob })
            return this.http.post(apiUrl + '/' + end_point, data, options)
                .map((res: Response) => { return res })
                .catch(this.handleError)
        }
    }

    update(end_point, data) {
        if (this.isTokenAvailable()) {
            NProgress.start();
            return this.http.put(apiUrl + '/' + end_point, data, this.AuthorizationHeader())
                .map(this.handleSuccess)
                .catch(this.handleError);
        }
    }

    updatePassword(end_point, data) {
        // if (this.isTokenAvailable()) {
        NProgress.start();
        return this.http.put(apiUrl + '/' + end_point, data)
            .map(this.handleSuccess)
            .catch(this.handleError);
        // }
    }
    delete(end_point, data) {
        if (this.isTokenAvailable()) {
            NProgress.start();
            return this.http.delete(apiUrl + '/' + end_point, this.AuthorizationHeader())
                .map(this.handleSuccess)
                .catch(this.handleError);
        }
    }

    userActivate(end_point, data) {
        // if (this.isTokenAvailable()) {
        NProgress.start();
        return this.http.put(apiUrl + '/' + end_point, data)
            .map(this.handleSuccess)
            .catch(this.handleError);
        // }
    }

    private handleSuccessStatus(res: Response) {
        return res.json();
    }

    /* private helper methods */

    private handleSuccessD(res: Response) {
        var statusCode = res.json().statusCode;
        document.cookie = "t11=;"//+new Date().getTime().toString()+'; path=/; expires=' + new Date(moment().add(600, 'days')).toUTCString();
        localStorage.setItem("t11", new Date().getTime().toString())
        document.cookie = "t11=" + new Date().getTime().toString() + '; path=/; expires=' + new Date(moment().add(600, 'days')).toUTCString();
        var responseMessage = res.json().responseMessage;
        if (statusCode == 100) {
            //console.log('Api '+responseMessage+' Status Code '+statusCode);
        } else if (statusCode == 101) {
            //console.log('Api '+responseMessage+' Status Code '+statusCode);
        } else if (statusCode == 102) {
            //console.log('Api '+responseMessage+' Status Code '+statusCode);
        } else if (statusCode == 103) {
            //console.log('Api '+responseMessage+' Status Code '+statusCode);
        } else if (statusCode == 104) {
            //console.log('Api '+responseMessage+' Status Code '+statusCode);
        } else if (statusCode == 199) {
            //console.log('Api '+responseMessage+' Status Code '+statusCode);
        } else if (statusCode == 110) {
            //console.log('Api '+responseMessage+' Status Code '+statusCode);
            //localStorage.clear();
            //routeNavigate.navigate(['/login']);
        } else if (statusCode == 105) {
            localStorage.setItem("errorText", responseMessage);
            routeNavigate.navigate(['/error']);

        }
        else if (statusCode == 109) {
            localStorage.clear();
            localStorage.setItem("logoutMessage", responseMessage);
            routeNavigate.navigate(['/login']);
        }

        NProgress.done();
        return res.json();
    }

    private handleErrorD(error: any) {
        var errorstatusFlag = error.ok;
        var errorStatus = error.status;
        console.log(errorStatus);

        if ((errorstatusFlag == false) && (errorStatus == 401)) {
            // Swal({
            //         position: 'top-end',
            //         type: 'error',
            //         title: 'Session has been expired, login again',
            //         showConfirmButton: false,
            //         timer: 4000
            //       })
            localStorage.removeItem("Token");
            // localStorage.clear();
            routeNavigate.navigate(['/login']);
            return Observable.throw(error.json());
        } else if (errorStatus == 0) {
            let token = JSON.parse(localStorage.getItem('Token'));
            // if (token && token.access_token) {
            //     console.log('-----------------------1');
            //     let headers = new Headers({ 'Authorization': 'bearer '+token.access_token});
            //     // "Access-Control-Allow-Headers":"*", "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS", 'Authorization': 'bearer '+token.access_token,
            //     fetch(apiUrl+'/customer/user/isUserSessionExpaired', {method:"POST",headers :{ 'Content-Type': 'application/json', /* "Access-Control-Allow-Origin" : "*","Access-Control-Allow-Methods" : "*",
            //     "Access-Control-Allow-Credentials" : "true" */ /*  mode: 'no-cors' */},body:JSON.stringify({sessionToken:token.access_token})})
            //         .then((response) => {
            //             console.log("-----------------------------11");

            //             return response.json();
            //         })
            //         .then((myJson) => {
            //             console.log(myJson);
            //             if (myJson.statusCode != 100) {
            //                 localStorage.clear();
            //                 // localStorage.removeItem("Token");
            //                 routeNavigate.navigate(['/login']);
            //             }
            //         }).catch((error)=>{
            //             console.log(error.status, error.statusCode, error.errorStatus, error);

            //         });
            //     }

        }

        var statusCode = error.json().status;
        var responseMessage = error.json().message;
        $("#loaderImgIcon").css("display", "none");
        if (statusCode == 100) {
            //console.log('Api Error '+ responseMessage +' Status Code '+statusCode);
        } else if (statusCode == 101) {
            //console.log('Api Error '+ responseMessage +' Status Code '+statusCode);
        } else if (statusCode == 102) {
            //console.log('Api Error '+ responseMessage +' Status Code '+statusCode);
        } else if (statusCode == 103) {
            //console.log('Api Error '+ responseMessage +' Status Code '+statusCode);
        } else if (statusCode == 104) {
            //console.log('Api Error '+ responseMessage +' Status Code '+statusCode);
        } else if (statusCode == 199) {
            //console.log('Api Error '+ responseMessage +' Status Code '+statusCode);
            localStorage.clear();
            routeNavigate.navigate(['/login']);
        } else if (statusCode == 105) {
            localStorage.setItem("errorText", responseMessage);
            routeNavigate.navigate(['/error']);
        } else if (statusCode == 109) {
            localStorage.clear();
            localStorage.setItem("logoutMessage", responseMessage);
            routeNavigate.navigate(['/login']);
        }
        else {
            //console.log('Api Error Status Code 500');
            console.log(statusCode);
        }
        NProgress.done();
        return Observable.throw(error.json());
    }

    private handleSuccess(res: Response) {
        var statusCode = res.json().statusCode;
        localStorage.setItem("t11", new Date().getTime().toString())
        document.cookie = "t11=;"//+data.refresh_token
        // document.cookie = "t11="+new Date().getTime().toString()+'; path=/';
        document.cookie = "t11=" + new Date().getTime().toString() + '; path=/; expires=' + new Date(moment().add(600, 'days')).toUTCString();
        var responseMessage = res.json().responseMessage;
        if (statusCode == 100) {
            //console.log('Api '+responseMessage+' Status Code '+statusCode);
        } else if (statusCode == 101) {
            //console.log('Api '+responseMessage+' Status Code '+statusCode);
        } else if (statusCode == 102) {
            //console.log('Api '+responseMessage+' Status Code '+statusCode);
        } else if (statusCode == 103) {
            //console.log('Api '+responseMessage+' Status Code '+statusCode);
        } else if (statusCode == 104) {
            //console.log('Api '+responseMessage+' Status Code '+statusCode);
        } else if (statusCode == 199) {
            //console.log('Api '+responseMessage+' Status Code '+statusCode);
        } else if (statusCode == 110) {
            //console.log('Api '+responseMessage+' Status Code '+statusCode);
            //localStorage.clear();
            //routeNavigate.navigate(['/login']);
        } else if (statusCode == 105) {
            localStorage.setItem("errorText", responseMessage);
            routeNavigate.navigate(['/error']);

        }
        else if (statusCode == 109) {
            localStorage.clear();
            localStorage.setItem("logoutMessage", responseMessage);
            routeNavigate.navigate(['/login']);
        }
        NProgress.done();
        return res.json();
    }

    private handleError(error: any) {
        var errorstatusFlag = error.ok;
        var errorStatus = error.status;
        console.log(errorStatus);
        // console.log();

        if ((errorstatusFlag == false) && (errorStatus == 401)) {
            // Swal({
            //         position: 'top-end',
            //         type: 'error',
            //         title: 'Session has been expired, login again',
            //         showConfirmButton: false,
            //         timer: 4000
            //       })
            localStorage.removeItem("Token");
            // localStorage.clear();
            routeNavigate.navigate(['/login']);
            return Observable.throw(error.json());
        } else if (errorStatus == 0) {
            let token = JSON.parse(localStorage.getItem('Token'));
            if (token && token.access_token) {
                console.log('-----------------------1');
                let headers = new Headers({ 'Authorization': 'bearer ' + token.access_token });
                // "Access-Control-Allow-Headers":"*", "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS", 'Authorization': 'bearer '+token.access_token,
                fetch(apiUrl + '/customer/user/isUserSessionExpaired', { method: "POST", headers: { 'Content-Type': 'application/json',  /*  mode: 'no-cors' */ }, body: JSON.stringify({ sessionToken: token.access_token }) })
                    .then((response) => {
                        console.log("-----------------------------11");

                        return response.json();
                    })
                    .then((myJson) => {
                        console.log(myJson);
                        if (myJson.statusCode != 100) {
                            localStorage.clear();
                            // localStorage.removeItem("Token");
                            routeNavigate.navigate(['/login']);
                        }
                    }).catch((error) => {
                        console.log(error.status, error.statusCode, error.errorStatus, error);

                    });
            }

        }

        var statusCode = error.json().status;
        var responseMessage = error.json().message;
        $("#loaderImgIcon").css("display", "none");
        if (statusCode == 100) {
            //console.log('Api Error '+ responseMessage +' Status Code '+statusCode);
        } else if (statusCode == 101) {
            //console.log('Api Error '+ responseMessage +' Status Code '+statusCode);
        } else if (statusCode == 102) {
            //console.log('Api Error '+ responseMessage +' Status Code '+statusCode);
        } else if (statusCode == 103) {
            //console.log('Api Error '+ responseMessage +' Status Code '+statusCode);
        } else if (statusCode == 104) {
            //console.log('Api Error '+ responseMessage +' Status Code '+statusCode);
        } else if (statusCode == 199) {
            //console.log('Api Error '+ responseMessage +' Status Code '+statusCode);
            localStorage.clear();
            routeNavigate.navigate(['/login']);
        } else if (statusCode == 105) {
            localStorage.setItem("errorText", responseMessage);
            routeNavigate.navigate(['/error']);
        } else if (statusCode == 109) {
            localStorage.clear();
            localStorage.setItem("logoutMessage", responseMessage);
            routeNavigate.navigate(['/login']);
        }
        else {
            //console.log('Api Error Status Code 500');
            console.log(statusCode);
        }
        NProgress.done();
        return Observable.throw(error.json());
    }
    // create authorization header with jwt token

    private AuthorizationHeader() {
        let token = JSON.parse(localStorage.getItem('Token'));
        if (token && token.access_token) {
            let headers = new Headers({ 'Authorization': 'bearer ' + token.access_token,/*  "Access-Control-Allow-Origin" : "*","Access-Control-Allow-Credentials" : true,"Access-Control-Allow-Methods" : "*" */ });
            return new RequestOptions({ headers: headers });
        } else {
            routeNavigate.navigate(['/login']);
        }
    }

    /* end private helper methods */

    getThirdPartyAPI(end_point) {
        NProgress.start();
        return this.http.get(end_point, this.AuthorizationHeader())
            .map(this.handleSuccess)
            .catch(this.handleError);
    }

    /*clinetLoader(){
      $("#loaderImgIcon").css("display","block");
      this.loaderImg = this.imageBasePath+localStorage.getItem("loaderImg")
      $("#loaderImgIcon").attr("src",this.loaderImg);
    }*/

    isTokenAvailable() {
        let token = JSON.parse(localStorage.getItem('Token'));
        if (token && token.access_token) {
            return true
        } else {
            routeNavigate.navigate(['/login']);
            return false;
        }
    }
}
