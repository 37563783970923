import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { Router, NavigationStart, NavigationEnd, RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StructureModule }  from './structure/structure.module';
import { LoginModule }  from './structure/login/login.module';
import { ForgotPasswordComponent } from './structure/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './structure/reset-password/reset-password.component';
import { ApiService, DataService } from './services/index';
import { AlertService, CommonService } from './services/index';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { EventResolve } from './services/event.resolve.service';
import { UserActiveComponent } from './structure/user-activation/user-activation.component';
import { LoadingBarHttpModule } from '@ngx-loading-bar/http';


@NgModule({
  declarations: [
    AppComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    UserActiveComponent
  ],
  imports: [
    BrowserModule,
    StructureModule,
    HttpModule,
	  FormsModule,
    AppRoutingModule,
    LoginModule,
    BrowserAnimationsModule,
    LoadingBarHttpModule
  ],
  providers: [
    ApiService,
    CookieService,
    AlertService,
    CommonService,
    EventResolve,
    DataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

