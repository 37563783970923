import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { CanActivate,Router } from '@angular/router';
import { ApiService , AlertService}  from '../../../../services/index';
import Swal from 'sweetalert2'
/*import * as $ from 'jquery'
declare var d3:any,moment:any;;*/
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

    clientId: any = localStorage.getItem("clientId");
    customerId: any = (localStorage.getItem("customerId") ? JSON.parse(localStorage.getItem("customerId")) : []);
    userId: any = localStorage.getItem("userId");
    dataTable: any;
    reportListData: any = [];
    clientCode : any = ''; 
    permissionData:any=[];


    constructor(private router: Router, private apiService: ApiService , private alertService: AlertService, private chRef: ChangeDetectorRef) {
      
    }

     DateFormat(startDate){
  if(startDate){
      return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
  }else{
      return "";
    }
 }
  ngOnInit() {
        this.clientCode = this.alertService.getClientCode();
        this.getAllReportList();
  }


    getAllReportList() {
        // var URL = 'customer/report/getAllReport?customerId='+this.customerId;
        var URL = 'customer/report/getAllReport?clientId='+this.clientId;
        this.apiService.getAll(URL).subscribe(
            data => {
                    if (data.statusCode == 100) {
                    this.reportListData = data.responseList;
                    this.sortDataTableData(this.reportListData,'reportId',"desc");
                    if ($.fn.DataTable.isDataTable('#report_table')){
                        $('#report_table').DataTable().destroy();
                    };
                    this.chRef.detectChanges();
                    const table: any = $('#report_table');
                    this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": 4 }]});
                } else {
                    this.reportListData = [];
                    this.chRef.detectChanges();
                    const table: any = $('#report_table');
                    this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": 4 }]});
                }
            },
            error =>{
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    /** Function to sort data **/
    sortDataTableData(items,sortBy,desc){
         if (sortBy && sortBy != null) {
                items = items.sort((a, b) => {
                 const sortA = a[sortBy];
                 const sortB = b[sortBy];
                 if (desc) {
                     if (sortA < sortB) return 1;
                     if (sortA > sortB) return -1;
                     return 0;
                 } else {
                     if (sortA < sortB) return -1;
                     if (sortA > sortB) return 1;
                     return 0;
                 }
             });
            return items;
         }else{
            return items;
         }
    }

   deleteReport(reportId){
	 Swal({
	  title: 'Are you sure?',
	  text: "You want to delete this Report.",
	  type: 'warning',
	  showCancelButton: true,
	  confirmButtonColor: '#3085d6',
	  cancelButtonColor: '#d33',
	  confirmButtonText: 'Yes, delete it!'
}).then((result) => {
  if (result.value) {
   this.apiService.getAll('report/deleteReport?reportId='+reportId).subscribe(
   data => {
       if(data.statusCode == 100){
         Swal({
              position: 'top-end',
              type: 'success',
              title: 'Report Deleted Successfully',
              showConfirmButton: false,
              timer: 1500
            }) ;

             this.getAllReportList();

        }else{
          this.alertService.sweetMessage('error',data.responseMessage);
         }
      },
     error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
}
})
}

showReportInfo(reportId){
	console.log(reportId);
}

}
