import { Component, OnInit,ChangeDetectorRef,Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, FormatDateService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
/*import * as $ from 'jquery'
declare var d3:any,moment:any;;*/
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'app-data-point',
  templateUrl: './data-point.component.html',
  styleUrls: ['./data-point.component.css']
})
export class DataPointComponent implements OnInit {
  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  assetId :any = 0;
  assetChannelId:any = 0;
  dataTable: any;
  channeldata : any ={};
  assetChannelData : any = [];
  assetsumaryData : any ={};
  assetDatapoint: any = [];
  indexAsset:number=0;
  siteLat:number = 39.381266;
  siteLong:number = -97.922211;
  clientCode : any = '';
  breadcrumbObj: any;
  // permissionData:any=[];

  constructor(
    private router:Router,
    private route: ActivatedRoute, 
    private apiService: ApiService , 
    private alertService: AlertService, 
    private formatDateService: FormatDateService, 
    private chRef: ChangeDetectorRef) {
    // this.permissionData = ApiService.permission;
   }

  // Function to format Date
  DateFormat(startDate, gmtOffset){
    if (startDate) {
      var formattedDate = moment(startDate,"YYYY-MM-DD HH:mm:ss:SSS").format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    } else {
      return '-'
    }
  }

  ngOnInit() {
    this.clientCode = this.alertService.getClientCode();
    this.route.params.subscribe(params => {
        if(params['id']){
        this.assetId = params['id'];
      }
    });
    this.route
    .queryParams
    .subscribe(params => {
        this.breadcrumbObj = params['source'];
    })
    if(this.assetId){
      this.getAssetSummaryData();
      this.getAssetDataPoint();
    }
  }

  // Function to set latitude and longitude
  setLatLong(lat,long){
    this.siteLat = lat;
    this.siteLong = long;
  }


  reciveAsset(){
    this.getAssetSummaryData();
  }

  /* Function for get Asset Summary Data */
  getAssetSummaryData(){
    this.apiService.getAll('customer/asset/getCustomerAssetSummaryById?assetId='+this.assetId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.assetsumaryData= data.response;
        }else{
          this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  /* Function for getting Asset Data Point data */
  getAssetDataPoint(){
    this.apiService.getAll('customer/asset/getCustomerAssetChannelsByAssetId?assetId='+this.assetId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.assetDatapoint = data.responseList;
          this.indexAsset = 0;
          if ($.fn.DataTable.isDataTable('#asset_data_table')){
                $('#asset_data_table').DataTable().destroy();
              };
          this.chRef.detectChanges();
          const table: any = $('#asset_data_table');
          this.dataTable = table.DataTable({"columnDefs": [{ "orderable": false, "targets": 6 }]});
        }else{
          this.assetDatapoint = [];
          this.chRef.detectChanges();
           const table: any = $('#asset_data_table');
          this.dataTable = table.DataTable({"columnDefs": [{ "orderable": false, "targets": 6 }]});

           /*  this.alertService.sweetMessage('error',data.responseMessage);*/
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  // Function to get Asset Data
  asstedata(chanelData){
    this.channeldata = chanelData;
    this.assetChannelId = chanelData.assetChannelId;
    this.getAssetChannelTrigger();
  }

  // Function to trigger a channel associated with Asset
  getAssetChannelTrigger(){
    this.apiService.getAll('customer/asset/getAssetChannelTriggerStateGraph?assetChannelId='+this.assetChannelId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.assetChannelData = data.responseList;
        }else{
          this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
     error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

}
