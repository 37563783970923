import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, FormatDateService}  from '../../../../services/index';
import Swal from 'sweetalert2'
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';
import * as NProgress from 'nprogress';

@Component({
  selector: 'app-asset-management',
  templateUrl: './asset-management.component.html',
  styleUrls: ['./asset-management.component.css']
})
export class AssetManagementComponent implements OnInit {

    customerId: any = localStorage.getItem("customerId");
    userId: any = localStorage.getItem("userId");
    clientId: any = localStorage.getItem("clientId");
    assetDatalist: any = [];
    dataTable: any;
    assetId: any = 0;
    dropdownSettingsSite = {};
    NProgress:any;
    dropdownSettingsState = {};
    dropdownSettingsStatus = {};
    search: any = '';
    site: any = '';
    status: any = '';
    numbers:any=[];
    checkStateArray: any = [];
    checkSiteArray: any = [];
    checkStatusArray: any = [];
    checkSiteArraylist:any = [];
    checkStateArraylist:any = [];
    checkStatusArraylist:any = [];
    countryId: number = 0;
    state: any = '';
    countryList: any = [];
    stateList: any = [];
    siteList: any = [];
    siteListData: any = [];
    userassetList:any=[];
    total:any=1;
    assetDataId : any;
    roleList:any=[];
    UserList:any=[];
    statusList = [{
        "status": "A",
        "statusName": "Alarm"
    }, {
        "status": "W",
        "statusName": "Warning"
    }, {
        "status": "N",
        "statusName": "Normal"
    }, {
        "status": "C",
        "statusName": "No Comm"
    }, {
        "status": "S",
        "statusName": "Suspended"
    }];
    clientCode: any = '';
    assetIdForGraph : any = "";
    permissionData: any = [];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private apiService: ApiService ,
        private alertService: AlertService,
        private formatDateService: FormatDateService,
        private chRef: ChangeDetectorRef
        ) {
        this.permissionData = ApiService.permission;
    }


    ngOnInit() {
        this.clientCode = this.alertService.getClientCode();
        this.route.params.subscribe(params => {
          if (params['status']) {
              this.status = params['status'];
              this.assetIdForGraph = params['assetId'];
          }
        });

        this.getAllAssetList();
        this.getCountryList();
        this.getSiteManagementList();

        this.dropdownSettingsSite = {
            singleSelection: false,
            idField: 'siteId',
            textField: 'siteName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 0,
            allowSearchFilter: true
        };
        this.dropdownSettingsState = {
            singleSelection: false,
            idField: 'stateId',
            textField: 'stateName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 0,
            allowSearchFilter: true
        };
        this.dropdownSettingsStatus = {
            singleSelection: false,
            idField: 'status',
            textField: 'statusName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 0,
            allowSearchFilter: true
        };
    }

    // Function to format Date
    DateFormat(startDate, gmtOffset){
        return this.formatDateService.formatDate(startDate, gmtOffset);
    }

    /* On Item Select Status */
    onItemSelectStatus(item: any) {
        var exist = this.checkStatusArray.indexOf(item.status);
        if (exist == '-1') {
            this.checkStatusArray.push(item.status);
            this.checkStatusArraylist.push(item.status);
        }
    }

    /* On Item DeSelect Status */
    onItemDeselectStatus(item: any) {
        var exist = this.checkStatusArray.indexOf(item.status);
        if (exist != '-1') {
            this.checkStatusArray.splice(exist, 1);
            this.checkStatusArraylist.splice(exist, 1);
        }
    }

    /* On All Select Status */
    onSelectAllStatus(items: any) {
        Object.keys(items).forEach(key => {
            this.checkStatusArray.push(items[key]['status'].toString());
            this.checkStatusArraylist.push(items[key]['status'].toString());
        });
    }

    /* On All DeSelect Status */
    onDeSelectAllStatus() {
        this.checkStatusArray = [];
        this.checkStatusArraylist = [];
    }

    /* On Item Select State */
    onItemSelectState(item: any) {
        var exist = this.checkStateArray.indexOf(item.stateId);
        if (exist == '-1') {
            this.checkStateArray.push(item.stateId);
            this.checkStateArraylist.push(item.stateId);
        }
    }

    /* On Item DeSelect State */
    onItemDeselectState(item: any) {
        var exist = this.checkStateArray.indexOf(item.stateId);
        if (exist != '-1') {
            this.checkStateArray.splice(exist, 1);
            this.checkStateArraylist.splice(exist, 1);
        }
    }

    /* On All Select State */
    onSelectAllState(items: any) {
        Object.keys(items).forEach(key => {
            this.checkStateArray.push(items[key]['stateId'].toString());
            this.checkStateArraylist.push(items[key]['stateId'].toString());
        });
    }

    /* On All DeSelect State */
    onDeSelectAllState() {
        this.checkStateArray = [];
        this.checkStateArraylist = [];
    }

    /* On Item Select Site */
    onItemSelectSite(item: any) {
        var exist = this.checkSiteArray.indexOf(item.siteId);
        if (exist == '-1') {
            this.checkSiteArray.push(item.siteId);
            this.checkSiteArraylist.push(item.siteId);
        }
    }

    /* On Item DeSelect Site */
    onItemDeselectSite(item: any) {
        var exist = this.checkSiteArray.indexOf(item.siteId);
        if (exist != '-1') {
            this.checkSiteArray.splice(exist, 1);
            this.checkSiteArraylist.splice(exist, 1);
        }
    }

    /* On All Select Site */
    onSelectAllSite(items: any) {
        Object.keys(items).forEach(key => {
            this.checkSiteArray.push(items[key]['siteId'].toString());
            this.checkSiteArraylist.push(items[key]['siteId'].toString());
        });
    }

    /* On All DeSelect Site */
    onDeSelectAllSite() {
        this.checkSiteArray = [];
        this.checkSiteArraylist = [];
    }

    /* On Search */
    seachRecord() {
        this.site = this.checkSiteArray.toString();
        this.state = this.checkStateArray.toString();
        this.status = this.checkStatusArray.toString();
        this.getAllAssetList();
    }

    /* Reset Search Record */
    resetSearch() {
        this.search = "";
        this.site = "";
        this.status = "";
        this.countryId = 0;
        this.state = "";
        this.checkSiteArray = [];
        this.checkStateArray = [];
        this.checkStatusArray = [];
        this.checkSiteArraylist = [];
        this.checkStateArraylist = [];
        this.checkStatusArraylist = [];
        this.getAllAssetList();
    }

    /* Get Site Management list */
    getSiteManagementList() {
        var URL = 'customer/site/getSiteListByCustomer?customerId=' + this.customerId;
        this.apiService.getAll(URL).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.siteList = data.responseList;
                } else {
                    this.siteList = [];
                }
            },
            error =>{
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    /* Get state list by country id */
    getStateListByCountryId(countryId) {
        if (countryId != 0) {
            this.apiService.getAll('state/getStateList/'+countryId).subscribe(
                data => {
                    if (data.statusCode == 100) {
                        this.stateList = data.responseList;
                    } else {
                        this.stateList = [];
                    }
                },
                error =>{
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
            );
        }else{
            this.stateList = [];
            this.checkStateArraylist = [];
        }
    }

    /* Get Country list */
    getCountryList() {
        this.apiService.getAll('country/getCountry').subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.countryList = data.responseList;
                } else {
                    this.countryList = [];
                }
            },
           error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    /* Function for getting Root level tier data */
    getAllAssetList() {
        NProgress.start();
        var URL = 'customer/asset/getAssetByCustomer'//?customerId=' + this.customerId+'&clientId='+this.clientId+"&isAssetLive=true";
        // var URL = 'customer/asset/getAssetByCustomer?customerId=' + this.customerId+'&clientId='+this.clientId+"&isAssetLive=true";

        let apiData:any = {
            "customerId" : this.customerId,
            "clientId" : this.clientId,
            "isAssetLive" : true
            }
        if (this.assetIdForGraph != '' && this.assetIdForGraph != undefined) {
            var assetString = '&assetId=' + this.assetIdForGraph;
            // var URL = URL.concat(assetString);
        }

        if (this.site != '') {
            var siteString = '&siteId=' + this.site;
            // var URL = URL.concat(siteString);
        }
        if (this.countryId != 0) {
            var countryString = '&countryId=' + this.countryId;
            apiData.countryId = this.countryId
            // var URL = URL.concat(countryString);
        }
        if (this.state != '') {
            var stateString = '&stateId=' + this.state;
            apiData.stateId = this.state
            // var URL = URL.concat(stateString);
        }
        if (this.status != '') {
            var statusString = '&status=' + this.status;
            apiData.status = this.status.split(',')
            // var URL = URL.concat(statusString);
        }
        if (this.search != '') {
            var searchString = '&search=' + this.search;
            // var URL = URL.concat(searchString);
        }        
        this.assetDatalist = [];
        if ($.fn.DataTable.isDataTable('#asset_data_table')) {
            $('#asset_data_table').DataTable().destroy();
        };
        this.apiService.create(URL, apiData).subscribe(
            data => {
                NProgress.done();
                if (data.statusCode == 100) {
                    let Arr = []
                    this.assetDatalist = [];

                    let objArr =  data.responseList ? data.responseList : [];
                    objArr.map((val,key)=>{
                        let objValue = val
                        if (!val.notificationPlan) {
                            objValue.notificationPlan = {};
                        }
                        if (!val.shiftGroup) {
                            objValue.shiftGroup = {};
                        }
                        if (!val.customer) {
                            objValue.customer = {};
                        }
                        if (!val.site) {
                            objValue.site = {};
                        }
                        Arr.push(objValue);
                    });
                    this.assetDatalist = JSON.parse(JSON.stringify(Arr));
                    this.chRef.detectChanges();
                    if ($.fn.DataTable.isDataTable('#asset_data_table')) {
                        $('#asset_data_table').DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": [9,10]}]}).destroy();
                    };

                    const table: any = $('#asset_data_table');
                    this.dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": [9,10]}]});
                }
                else {
                    this.assetDatalist = [];
                    this.chRef.detectChanges();
                    const table: any = $('#asset_data_table');
                    this.dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": [9,10]}]});
                }
            },
            error =>{
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    // Getting Asset Data
    getAssetData(data){
        this.assetDataId = data;
        // this.apiService.getAll('customer/user/getUsersByAsset?clientId='+this.clientId+'&assetId='+data+'&customerId='+this.customerId).subscribe(
        this.apiService.getAll('customer/user/getUserByAsset?clientId='+this.clientId+'&assetId='+data+'&customerId='+this.customerId).subscribe(
          data => {
            if(data.statusCode == 100){
              this.userassetList = data.userList;
              this.total = this.userassetList.length + 2;
              this.numbers = [];
                for (var i = 1; i < this.total; i++) {
                    this.numbers.push(i);
                    //visit(children[i], visitFn, childrenFn);
                }
            }else{
              this.userassetList = [];
              this.numbers = ['1'];
            }
          },
         error =>{
               this.alertService.sweetMessage('error',"Something went wrong, please try again ");
             }
        );

        // // Getting User and Role List
        // this.getUserList();
        // this.getRoleList();
    }

    // Getting User List
    getUserList(){
        // this.apiService.getAll('public/user/getUserByTier?clientId='+this.clientId+'&assetId='+this.assetDataId).subscribe(
        this.apiService.getAll('user/getUserByTier?clientId='+this.clientId+'&assetId='+this.assetDataId).subscribe(
          data => {
            if(data.statusCode == 100){
              this.UserList = data.userList;
            }else{
              this.UserList =[];
             /* this.alertService.sweetMessage('error',data.responseMessage); */
            }
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    // Getting Role List
    getRoleList(){
        this.apiService.getAll('assetRole/getAllAssetRole').subscribe(
          data => {
            if(data.statusCode == 100){
              this.roleList = data.responseList;
            }else{
              this.roleList =[];
              /*this.alertService.sweetMessage('error',data.responseMessage); */
            }
          },
         error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    /* Get User by Asset list */
    getUserByAsset(){
        this.apiService.getAll('customer/user/getUsersByAsset?clientId='+this.clientId+'&assetId='+this.assetDataId+'&customerId='+this.customerId).subscribe(
          data => {
            if(data.statusCode == 100){
              this.userassetList = data.userList;
            }else{
              this.userassetList = [];
            }
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

 
// When user click on site name then it will add site name to the page filter
  addSiteToFilter (keyValue) {
    $('#asset_data_table').DataTable().search(keyValue).draw();
  }

}
