import { NgModule, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, ActivatedRoute,RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { NguiMapModule} from '@ngui/map';
/* Partial */
import { HeaderComponent } from './partials/header/header.component';
import { FooterComponent } from './partials/footer/footer.component';
/* Dashboard */
import { DashboardComponent } from './section/dashboard/dashboard.component';
/* View */
import { ViewComponent } from './view.component';
import { ViewsRoutingModule } from './view-routing.module';
import { CustomersComponent } from './section/customers/customers.component';

/* Setting */
import { SettingComponent } from './section/setting/setting.component';
import { ClientSettingComponent } from './section/setting/client-setting/client-setting.component';
/* Assets */
import { AssetManagementComponent } from './section/asset-management/asset-management.component';
import { AssetSummaryComponent } from './section/asset-summary/asset-summary.component';
import { AssetsummaryMenuComponent } from './section/asset-summary/assetsummary-menu/assetsummary-menu.component';
import { AssetEditComponent } from './section/asset-summary/asset-edit/asset-edit.component';
import { AssetUserComponent } from './section/asset-summary/asset-user/asset-user.component';
import { AssetsLogComponent } from './section/asset-summary/assets-log/assets-log.component';
import { DataPointComponent } from './section/asset-summary/data-point/data-point.component';

import { AssetGraphComponent } from './section/asset-summary/asset-graph/asset-graph.component';
import { ModemTrafficComponent } from './section/asset-summary/modem-traffic/modem-traffic.component';
import { CommentsComponent } from './section/asset-summary/comments/comments.component';
import { DocumentsComponent } from './section/asset-summary/documents/documents.component';
import { EventsComponent } from './section/asset-summary/events/events.component';

import { NotificationComponent } from './section/notification/notification.component';
import { NotificationEventComponent } from './section/notification-event/notification-event.component';
/* Report */
import { ReportComponent } from './section/report/report.component';
import { AddReportComponent } from './section/report/add-report/add-report.component';
import { AdhocReportComponent } from './section/report/adhoc-report/adhoc-report.component';
import { AssetSummaryReportComponent } from './section/report/asset-summary-report/asset-summary-report.component';

// import { PartletsComponent } from './section/asset-summary/partlets/partlets.component';
// import { ProductsComponent } from './section/asset-summary/products/products.component';
// import { ActivitiesComponent } from './section/asset-summary/activities/activities.component';
// import { AssetCalenderComponent } from './section/asset-summary/assets-calender/assets-calender.component';
// import { AssetsDataPocketComponent } from './section/asset-summary/assets-data-pocket/assets-data-pocket.component';

import { CopyScheduleComponent } from './section/asset-summary/copy-schedule/copy-schedule.component';


/* Template  */
import { TemplateComponent } from './section/setting/template/template.component';
import { jqxTreeComponent } from './jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from './jqwidgets-ts/angular_jqxdropdownbutton';
import { jqxCheckBoxComponent } from './jqwidgets-ts/angular_jqxcheckbox';
import { Ng2DragDropModule } from 'ng2-drag-drop';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FusionChartsModule } from 'angular-fusioncharts';
import { NgDygraphsModule } from 'ng-dygraphs';

import * as FusionCharts from './js/fusioncharts';
import * as Charts from './js/fusioncharts.charts';
import * as FintTheme from './js/themes/fusioncharts.theme.fint';
import { EditorModule } from '@tinymce/tinymce-angular';

import { EventDetailsComponent } from './section/event-details/event-details.component';

import { GlobalSearchComponentComponent } from './section/global-search-component/global-search-component.component';
import { UpComingComponent } from './section/up-coming/up-coming.component';

import { SiteManagementComponent } from './section/site-management/site-management.component';

import { AddSiteComponent } from './section/site-management/add-site/add-site.component';
import { ErrorHandlingComponent } from './section/error-handling/error-handling.component';
import { UserProfileComponent } from './section/user-management/user-profile/user-profile.component';

import { HourMeterReportComponent } from './section/report/hour-meter-report/hour-meter-report.component';
import { SiteDataPointSummaryReportComponent } from './section/report/site-data-point-summary-report/site-data-point-summary-report.component';
import { SiteEventNotificationReportComponent } from './section/report/site-event-notification-report/site-event-notification-report.component';
import { SiteMessageActivityReportComponent } from './section/report/site-message-activity-report/site-message-activity-report.component';
import { SiteLocationReportComponent } from './section/report/site-location-report/site-location-report.component';
import { SiteMessageHistoryReportComponent } from './section/report/site-message-history-report/site-message-history-report.component';
import { RuntimeEventReportComponent } from './section/report/runtime-event-report/runtime-event-report.component';
// import { FlatpickrModule } from 'angularx-flatpickr';

FusionCharts.options.SVGDefinitionURL='absolute'
FusionChartsModule.fcRoot(FusionCharts, Charts, FintTheme)

import { AgmCoreModule } from '@agm/core';
import { TechEntryWizardReportComponent } from './section/report/tech-entry-wizard-report/tech-entry-wizard-report.component'

const VIEWS_COMPONENTS = [
  ViewComponent,
  HeaderComponent,
  FooterComponent,
  DashboardComponent,
  CustomersComponent,
  AssetManagementComponent,
  NotificationComponent,
  NotificationEventComponent,
  ReportComponent,
  AddReportComponent,
  AdhocReportComponent,
  AssetSummaryReportComponent,
  SettingComponent,
  ClientSettingComponent,
  TemplateComponent,
  AssetSummaryComponent,
  AssetsummaryMenuComponent,
  AssetEditComponent,
  AssetUserComponent,
  AssetsLogComponent,
  AssetGraphComponent,
  ModemTrafficComponent,
  CommentsComponent,
  DocumentsComponent,
  EventsComponent,
  DataPointComponent,
  jqxTreeComponent,
  jqxDropDownButtonComponent,
  jqxCheckBoxComponent,
  // PartletsComponent,
  // ProductsComponent,
  // ActivitiesComponent,
  // AssetCalenderComponent,
  // AssetsDataPocketComponent,
  CopyScheduleComponent,
  EventDetailsComponent,
  GlobalSearchComponentComponent,
  UpComingComponent,
  SiteManagementComponent,
  AddSiteComponent,
  ErrorHandlingComponent,
  UserProfileComponent,
  HourMeterReportComponent,
  SiteDataPointSummaryReportComponent,
  SiteEventNotificationReportComponent,
  SiteMessageActivityReportComponent,
  SiteLocationReportComponent,
  SiteMessageHistoryReportComponent,
  RuntimeEventReportComponent,
];

@NgModule({
  imports: [
    ViewsRoutingModule,
  	FormsModule,
    CommonModule,
    FilterPipeModule,
    FusionChartsModule,
    EditorModule,
    NgDygraphsModule,
    NgMultiSelectDropDownModule,
    NguiMapModule.forRoot({apiUrl: 'https://maps.google.com/maps/api/js?key=AIzaSyCL0Qt9KnWSrhOBpETUKORWvPzC_rbvh9g'}),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCL0Qt9KnWSrhOBpETUKORWvPzC_rbvh9g'
    }),
    Ng2DragDropModule.forRoot(),
    // FlatpickrModule.forRoot(),
  ],
  declarations: [
    ...VIEWS_COMPONENTS,
    TechEntryWizardReportComponent,
     ],
})


export class ViewModule {
}
