﻿import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    ActivatedRouteSnapshot
} from '@angular/router';
import { ApiService } from './api.service';
import { DataService } from './dataService';
import { Observable } from 'rxjs';
import { RequestOptions, Http, Headers, Response } from '@angular/http';
import { CookieService } from 'ngx-cookie-service';

declare var $: any;
declare var jQuery: any, moment:any;

@Injectable()
export class EventResolve implements Resolve<any> {
    constructor(private apiService: ApiService, private router: Router, private http:Http, private cookieService:CookieService) { }
    
    resolve(route: ActivatedRouteSnapshot):Promise<any>/*  | Promise<never>  */ {
		console.log('resolver called');

		// setTimeout(() => {
		// 	return
		// }, 3000);
		// console.log(path,segment,previous);
		// let userData = JSON.parse(localStorage.getItem('userData'))
		
		// if (userData) {
		// 	return
		// } else {
			
		// 	// return
		// }
		
		var ip = location.host;
		if(location.host == "customer.satalarm.com"){
			// var apiUrl: String = "https://demo.satalarm.com/customer/api/v1";
			var apiUrl: String = "https://demo.satalarm.com/cust/api/v1";
			var loginUrl: String = "https://demo.satalarm.com";
			var imgUrl: String = "https://demo.satalarm.com/";
		}
		else if(location.hostname == "192.168.0.112"){
			var loginUrl: String = "http://192.168.0.112:8763";
			var apiUrl  : String = "http://192.168.0.112:8767/api/v1";
			var imgUrl  : String = "http://amciimages.cdnsolutionsgroup.com/";
		}
		else if(location.hostname == 's3.cdn131.local'){
			var loginUrl: String = "http://s3.cdn131.local";
			var apiUrl	: String = "http://s3.cdn131.local/cust/api/v1";
			var imgUrl  : String = "https://s3.cdn131.local/";
		}
		else{
			var loginUrl: String = "https://s14.cdnsolutionsgroup.com";
			var apiUrl: String  = "https://s14.cdnsolutionsgroup.com/cust/api/v1";
			var imgUrl: String  = "https://s09.cdnsolutionsgroup.com/";
		}
			
		// var header = null
		// let token = JSON.parse(localStorage.getItem('Token'));
        // if (token && token.access_token) {
        //     let headers1 = new Headers({ 'Authorization': 'bearer '+token.access_token});
        //     header = new RequestOptions({ headers: headers1 });
		// }



		let refreshToken = this.cookieService.get('refresh_token')
		// console.log(refreshToken);
		console.log(this.router.url,"-------url");
		
		if (!refreshToken) {
			console.log("------------------------------------");
			
			return;
		}
		
		let isLogin = this.cookieService.get('isLogin')

		let obj = {
			refresh_token: refreshToken,
			grant_type: "refresh_token"
		}

		let lasttime = this.cookieService.get('t11')
        // console.log(lasttime,moment().diff(moment(new Date(parseInt(lasttime)))),new Date(parseInt(lasttime)));
        let diff = moment().diff(moment(new Date(parseInt(lasttime))))
        
		console.log("wait", diff, isLogin);
        if(diff/1000 > 900/* 0 */ /* 3600 */){
			console.log("----- time");
			
            if (isLogin) {
				console.log("----- is login reset");
            
				let headers = new Headers(/* { 'Authorization': token.access_token} */);
				headers.append("Content-Type" , "application/x-www-form-urlencoded");
				const httpOptions = new RequestOptions({ headers: headers });
				let finalRequestBody = "refresh_token=";
				finalRequestBody=finalRequestBody+obj.refresh_token+"&grant_type=refresh_token"
				// finalRequestBody=finalRequestBody+obj.password+""
				return this.http.post(this.apiService.LoginUrlService+'/userauth/oauth/token',finalRequestBody,httpOptions).toPromise()
				.then((res: Response)=>{
					this.cookieService.delete('refresh_token')
					let data =  res.json();

					localStorage.setItem("Token", JSON.stringify(data));
					
					// document.cookie = "refresh_token=;"//+data.refresh_token
					// document.cookie = "refresh_token="+data.refresh_token

					let h1 =  new Headers({ 'Authorization': "bearer  "+data.access_token});
					h1.append("Content-Type" , "application/x-www-form-urlencoded");
					const httpOptions1 = new RequestOptions({ headers: h1 });
					// this.cookieService.set('refresh_token', data.refresh_token)
					document.cookie = "refresh_token=;"//+data.refresh_token
					document.cookie = "refresh_token="+data.refresh_token+'; path=/; expires=' + new Date(moment().add(30, 'days')).toUTCString();

					console.log("now return");
					// this.getUserInfo(apiUrl, httpOptions1);
					// setTimeout(() => {
					// 	return data;
					// }, 10000);

					/* return */ this.http.get(this.apiService.AppUrlService+'/customer/user/getUserInfo',httpOptions1)//.toPromise()
						.map((response: Response)=>{
							console.log(/* response, */"========================================");

							if (data.response) {
								localStorage.setItem("clientId",JSON.stringify(data.response.clientId));
								localStorage.setItem("permission",JSON.stringify(data.response.permissions));
								localStorage.setItem("customerData", JSON.stringify(data.response));
								localStorage.setItem("customerId", data.response.customerId);
								localStorage.setItem("userId", data.response.userId);
								localStorage.setItem("clientId", data.response.clientId);
								
							}

							// let responseInfo = response.json();
							// localStorage.setItem("permission",JSON.stringify(responseInfo.response.permissions));

							// localStorage.setItem("userData", JSON.stringify(responseInfo.response));
							// localStorage.setItem("userId", responseInfo.response.userId);
							// localStorage.setItem("clientId", responseInfo.response.defaultClientId);
							// localStorage.setItem("clientCode", responseInfo.response.clients[0].clientCode);
							// this.router.navigate(['/dashboard']);
							return response.json();
						})
						.catch((error1:any)=>{
							// return Observable.throw(error1.json());
							return Promise.reject(error1 || 'Server error');
						}).toPromise();
				})
				.catch((error:any)=>{
					console.log(error,"----- refresh token");
					let err = JSON.parse(error._body)
					if (err.error == "invalid_token") {
						console.log("invalid-token");
						localStorage.clear()
						this.router.navigate(['/login']);
                    }
					return Observable.throw(error.json());
				})
			}else{
				localStorage.removeItem('Token')            
				localStorage.clear()
				this.router.navigate(['/login']);
				console.log("----- else logout");

				return;
			}
        }else{
			// this.router.navigate(['/login']);
			console.log("----- else not time");

			return;
		}

		if (isLogin) {

			
			// setTimeout(() => {
			// 	console.log("now return");
				
			// 	return
			// }, 10000);
            

			/* return this.apiService.getResetToken('userauth/oauth/token',obj).subscribe(
				data => {
					// console.log(data.access_token, "tokenc", data);
	
					// setTimeout(() => {
						localStorage.setItem("Token", JSON.stringify(data));
						
						document.cookie = "refresh_token=;"//+data.refresh_token
						document.cookie = "refresh_token="+data.refresh_token
						// }, 500);

						return this.apiService.getUserInfoData('user/getUserInfo').subscribe(
							datainfo => {
							if(datainfo.statusCode == 100){
								
								ApiService.permission = datainfo.response.permissions;
								if(ApiService.permission){
									ApiService.isPermission = 1;
								}
								localStorage.setItem("permission",JSON.stringify(datainfo.response.permissions));

								localStorage.setItem("userData", JSON.stringify(datainfo.response));
								localStorage.setItem("userId", datainfo.response.userId);
								localStorage.setItem("clientId", datainfo.response.defaultClientId);
								localStorage.setItem("clientCode", datainfo.response.clients[0].clientCode);
								return datainfo;
							}else if(data.statusCode == 105){
							 var msg = data.responseMessage;
							 return datainfo;
						}else{
							return datainfo;
						}
					},
					error => {
						return error;
					}
				);
	
				},
				error => {
					console.log(error);
					return;
					
				}
			);	 */		
		} else {
			
		}


		// return this.http.post(loginUrl+'/userauth/oauth/login', obj, header).map((data: any) => {
		// return this.http.get(apiUrl+'/user/getUserInfo', header).map((data: any) => {
		// 	console.log(data);//1570176255895 1570176483713
			
		// 	return data
		// },(err:any)=>{
		// 	return
		// }); 

		// if(segment){
		// 	if(segment!='refresh'){
		// 		return this.apiService.getAll(segment).map(data => {
		// 			return data;
		// 		});
		// 	}else{
		// 		return [];
		// 	}
		// }else{
		// 	switch(path){
		// 		case 'webhooks/create':
		// 			return this.apiService.getById('connections', route.params.connectionId).map(data => {
		// 				return data;
		// 			});
		// 		case 'events/events':
		// 			return this.apiService.getUserToken().then(token => {
		// 				console.log(JSON.stringify(token));
		// 				if (token.user && token.user.access_token) {
		// 					// store user details and jwt token in local storage to keep user logged in between page refreshes
		// 					localStorage.setItem('currentUser', JSON.stringify(token.user));
		// 					// setTimeout(()=>{
		// 						this.router.navigate(['/campaign/list']);
		// 					// },1000)
		// 					window.location.reload();
		// 				}
		// 				return;
		// 			});
		// 	}
		// }
	}
	
	getUserInfo(apiUrl, httpOptions1)/* :Promise<any> */{
		return this.http.get(apiUrl+'/user/getUserInfo',httpOptions1)//.toPromise()
				.map((response: Response)=>{
					console.log(response,"========================================");
					
					let responseInfo = response.json();
					localStorage.setItem("permission",JSON.stringify(responseInfo.response.permissions));

					localStorage.setItem("userData", JSON.stringify(responseInfo.response));
					localStorage.setItem("userId", responseInfo.response.userId);
					localStorage.setItem("clientId", responseInfo.response.defaultClientId);
					localStorage.setItem("clientCode", responseInfo.response.clients[0].clientCode);
					this.router.navigate(['/dashboard']);
					return responseInfo.json();
				})
				.catch((error1:any)=>{
					// return Observable.throw(error1.json());
					return Promise.reject(error1 || 'Server error');
				  })//.toPromise();
	}
}







/* 

import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    ActivatedRouteSnapshot
} from '@angular/router';
import { ApiService } from './api.service';
import { DataService } from './dataService';

declare var $: any;
declare var jQuery: any;

@Injectable()
export class EventResolve implements Resolve<any> {
    constructor(
      private apiService: ApiService,
      private dataservice:DataService,
      private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
		console.log('resolver called');
		var path = route.data['path'];
		var segment = route.params['segment'];
		var previous = route.queryParams['ref'];
		if(segment){
			if(segment!='refresh'){
				return this.apiService.getAll(segment).map(data => {
					return data;
				});
			}else{
				return [];
			}
		}else{
			switch(path){
				case 'webhooks/create':
					return this.apiService.getById('connections', route.params.connectionId).map(data => {
						return data;
					});
				case 'events/events':
					return this.apiService.getUserToken().then(token => {
						console.log(JSON.stringify(token));
						if (token.user && token.user.access_token) {
							// store user details and jwt token in local storage to keep user logged in between page refreshes
							localStorage.setItem('currentUser', JSON.stringify(token.user));
							// setTimeout(()=>{
								this.router.navigate(['/campaign/list']);
							// },1000)
							window.location.reload();
						}
						return;
					});
			}
		}
    }
}
 */