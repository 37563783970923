import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
import * as $ from 'jquery'
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'app-assetsummary-menu',
  templateUrl: './assetsummary-menu.component.html',
  styleUrls: ['./assetsummary-menu.component.css']
})
export class AssetsummaryMenuComponent implements OnInit {
  // Initializing Data
  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  assetId :any = 0;  
  breadcrumbObj: any;
  // permissionData:any=[];
  constructor(private router:Router,private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService) { 
    // this.permissionData = ApiService.permission;
  }

  // Format Date
  DateFormat(startDate){
    if(startDate){
        return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
    }else{
        return "";    
    }
  }

  ngOnInit() {
   this.route.params.subscribe(params => {
      if(params['id']){
        this.assetId = params['id'];
      }
    });
    this.route
      .queryParams
      .subscribe(params => {
          this.breadcrumbObj = params['source'];
      })
  }
}
