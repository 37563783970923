import { Component, OnInit,ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService , AlertService } from '../../../../services/index';
import Swal from 'sweetalert2'
import { Subscription } from 'rxjs';
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';
declare var $:any;


@Component({
  selector: 'app-site-management',
  templateUrl: './site-management.component.html',
  styleUrls: ['./site-management.component.css']
})
export class SiteManagementComponent implements OnInit {

  customerId: any = localStorage.getItem("customerId");
  userId    : any = localStorage.getItem("userId");
  clientId  : any = localStorage.getItem("clientId");
  dataTable : any;

  sitemanagementDatalist : any;
  clientFilterId : any = '';

  siteLat:number = 39.381266;
  siteLong:number = -97.922211;
  mapMarkerPositions:any = []
  siteName:string="";
  bounds: any;
  zoomLevel : any;
  assignAsset: any = [];

  constructor(
    private router      : Router,
    private apiService  : ApiService,
    private alertService: AlertService ,
    private chRef       : ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.getSiteManagementList();
  }


/* Get Site Management list */
getSiteManagementList(){

    // var URL = 'customer/site/getSiteListByCustomer?customerId=19';
    this.sitemanagementDatalist = []

    if ($.fn.DataTable.isDataTable('#site_table')){
      $('#site_table').DataTable().destroy();
    };

    // this.apiService.getAll('customer/site/getSiteListByCustomer?customerId='+this.customerId+'&clientId=5').subscribe(
    this.apiService.getAll('customer/site/getSiteByCustomer?customerId='+this.customerId+'&clientId='+this.clientId).subscribe(
      data => {
      if(data.statusCode == 100){
        this.sitemanagementDatalist = data.responseList;

        this.chRef.detectChanges();
        if ($.fn.DataTable.isDataTable('#site_table')){
          $('#site_table').DataTable().destroy();
        };
        const table: any = $('#site_table');
        this.dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": [5] }]});

      }else{
        this.sitemanagementDatalist = [];
        this.chRef.detectChanges();
        const table: any = $('#site_table');
        this.dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": [5] }]});
      }
    },
    error =>
      {             this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    );
  } 

  setLatLong(lat,long,name){
    this.siteLat  = lat;
    this.siteLong = long;
    let arr = []
    // this.mapMarkerPositions = [lat, long];
    arr.push([lat, long]);
    this.mapMarkerPositions = arr
    this.siteName = name;
  
  
    this.bounds = new google.maps.LatLngBounds();
    let loc = new google.maps.LatLng(lat, long);
  
    this.bounds.extend(loc);
    var data = this.bounds.getCenter();
    this.zoomLevel = 21;
  
  }

  getAssignAssets(sitedatalist){
    console.log('getAssignAssets', sitedatalist);
    var URL = 'customer/asset/getAssetByCustomer?clientId='+this.clientId+'&customerId='+this.customerId+'&siteId='+sitedatalist.siteId;
    if ($.fn.DataTable.isDataTable('#assignAssetTable')) {
      $('#assignAssetTable').DataTable().destroy();
    };
    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.assignAsset = data.responseList;
          this.chRef.detectChanges();
          setTimeout(() => {
            const table: any = $('#assignAssetTable');
            this.dataTable = table.DataTable({ "bInfo": false });
          }, 100);
        } else {
          this.assignAsset = [];
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

}
