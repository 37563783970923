import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, AlertService } from '../../services/index';
import * as $ from 'jquery';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

    // Initialized Variables
    email: any = "";
    loginPageContentData:any;
    imageFile:any;
    isLoading = false;

    // Constructor
    constructor(private router: Router, private apiService: ApiService, private alertService: AlertService) {}

    ngOnInit() {
        // get Page content 
        $(document).ready(function() {
            // CSS
            $("body").css({
                "padding-top": "0px"
            });
        });

    }

   

    /* Function to reset Password */
    resetPassword() {
        this.isLoading = true;
        this.apiService.getWithouttoken('customer/user/forgetPassword?login=' + this.email.trim()).subscribe(
            data => {
                this.isLoading = false;
                if (data.statusCode == 100) {
                    // Link is sent to Email for changing password
                    Swal({
                        position: 'top-end',
                        type: 'success',
                        title: 'Reset Password link has been sent to your email, Please check.',
                        showConfirmButton: false,
                        timer: 3500
                    })
                    this.router.navigate(['/login']);
                } else {
                    Swal({
                        position: 'top-end',
                        type: 'error',
                        // title: "Please enter valid Email-Id.",
                    	title: data.responseMessage/* 'Please enter valid Email-Id.' */,
                        showConfirmButton: false,
                        timer: 3500
                    })
                    this.email = "";
                }
            },
            error => {
                this.isLoading = false;
                console.log(error);
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );
    }

}