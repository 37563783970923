import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-up-coming',
  templateUrl: './up-coming.component.html',
  styleUrls: ['./up-coming.component.css']
})
export class UpComingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
