import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, FormatDateService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
/*import * as $ from 'jquery'
declare var d3:any,moment:any;;*/
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';


@Component({
  selector: 'app-assets-log',
  templateUrl: './assets-log.component.html',
  styleUrls: ['./assets-log.component.css']
})
export class AssetsLogComponent implements OnInit {

  // Initializing Data
  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  assetId :any = 0;
  commentId :number = 0;
  commentType : any = '';
  dataTable: any;
  assetsumaryData : any ={};
  AssetCommentsData : any = [];
  CommentsData : any = [];
  showcondition : any = 'less-text';
  showbtn: any ='Show';
  siteLat:number = 39.381266;
  siteLong:number = -97.922211;
  addContent:any={"user":{"userId":this.userId},"comment":"","asset":{"assetId":""},"commentType":{"commentTypeId":"1"}};
  clientCode: any = '';
  breadcrumbObj: any;
  // permissionData:any=[];
  constructor(
    private router:Router,
    private route: ActivatedRoute,
    private apiService: ApiService , 
    private formatDateService: FormatDateService,
    private alertService: AlertService, 
    private chRef: ChangeDetectorRef
    ){
    // this.permissionData = ApiService.permission;
  }

  // Function to format Date
  DateFormat(startDate, gmtOffset){
    if (startDate) {
      var formattedDate = moment(startDate,"YYYY-MM-DD HH:mm:ss:SSS").format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    } else {
      return '-'
    }
  }

  ngOnInit() {
    this.clientCode = this.alertService.getClientCode();
      this.getCommentsListData();
      this.route.params.subscribe(params => {
        if(params['id']){
          this.assetId = params['id'];
        }
      });
      this.route
      .queryParams
      .subscribe(params => {
          this.breadcrumbObj = params['source'];
      })
      if(this.assetId){
        this.getAssetSummaryData();
        this.getAssetCommentsData();
      }
  }

  // Setting Latitude and Longitude
  setLatLong(lat,long){
    this.siteLat = lat;
    this.siteLong = long;
  }

  // Listen For event Recive from child
    reciveAsset(){
      this.getAssetSummaryData();
    }

  /* Function for getting Asset Summary Data */
  getAssetSummaryData(){
    this.apiService.getAll('customer/asset/getCustomerAssetSummaryById?assetId='+this.assetId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.assetsumaryData= data.response;
        }else{
          this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  // Get Comment by id
  getCommentId(id){
    this.commentId = id;
    this.AssetCommentsData = [];
    this.getAssetCommentsData();
  }

  /* Function for getting Asset Comments Data */
  getAssetCommentsData(){
    var URL = 'customer/comment/getCommentByAssetId?assetId='+this.assetId;
    if(this.commentId != 0){
      var commentType     = '&commentTypeId='+this.commentId;
      var URL = URL.concat(commentType);
    }
    this.apiService.getAll(URL).subscribe(
      data => {
        if(data.statusCode == 100){
          this.AssetCommentsData= data.commentList;
          if ($.fn.DataTable.isDataTable('#assetLog_table')){
            $('#assetLog_table').DataTable().destroy();
          };
          this.chRef.detectChanges();
          const table: any = $('#assetLog_table');
          this.dataTable = table.DataTable();
        }else{
         this.chRef.detectChanges();
         const table: any = $('#assetLog_table');

         this.dataTable = table.DataTable();

          /*  this.alertService.sweetMessage('error',data.responseMessage);*/
        }
      },
     error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  /* Function for getting Comments List Data */
  getCommentsListData(){
    this.apiService.getAll('customer/comment/getAllCommentType').subscribe(
      data => {
        if(data.statusCode == 100){
          this.CommentsData = data.commentList;
        }else{

          /*  this.alertService.sweetMessage('error',data.responseMessage);*/
        }
      },
     error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  /* Function for Add Comment Data */
  addCommentData(){
    this.addContent.asset.assetId = this.assetId;
    this.apiService.create('comment/addComment',this.addContent).subscribe(
      data => {
        if(data.statusCode == 100){
          this.getAssetCommentsData();
          this.addContent={"user":{"userId":this.userId},"comment":"","asset":{"assetId":""},"commentType":{"commentTypeId":""}};
          this.alertService.sweetMessage('success','Comment Added Successfully');
        }else{
          this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
     error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  // Hide and show text (Toggle Functioanlity)
  showHideText(value){
    $('#less-text'+value).toggleClass('more-text');
    $('#Show'+value).toggleClass('hide-btn');
  }

}
